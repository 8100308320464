import React from "react";
import { Stack, TextField, PrimaryButton } from "@fluentui/react";
import Api from "../api";
import { UserInfo } from "../models";
import { Helpers } from "../helpers";
import { Header } from "./Header";
import { StackVert, PageTitle, ParagraphText } from "./Controls";
import { Content } from "./Content";
import { Language } from "./Strings";

const strings = Language.strings;

interface RegisterAddressProps {
  onStartPurchase: () => void;
}

type RegisterAddressState = UserInfo;

export class BuyPhysicalTags extends React.Component<RegisterAddressProps, RegisterAddressState> {
  constructor(props: RegisterAddressProps) {
    super(props);

    this.state = {
      id: "",
      username: "",
      phone: "",
      firstName: "",
      lastName: "",
      address: "",
      zipCode: "",
      city: "",
      country: "",
      email: "",
      isAdmin: false,
      properties: { freezerFoodChoiceListJson: "[]", freezerFoodTypeListJson: "[]" },
    };
  }

  async componentDidMount() {
    const userInfo = await Api.getUserInfo(Helpers.getCurrentUserId());
    this.setState(userInfo);
  }

  render() {
    const { firstName, lastName, address, zipCode, city, country } = this.state;

    return (
      <StackVert>
        <Header />
        <Content>
          <Stack.Item>
            <PageTitle>{strings.buyPhysicalTags_buyPhysicalTags}</PageTitle>
            <ParagraphText>{strings.buyPhysicalTags_fillInAddressInfo}</ParagraphText>
          </Stack.Item>
          <Stack.Item>
            <TextField
              label={strings.buyPhysicalTags_firstName}
              value={firstName}
              styles={{ fieldGroup: { width: 250 } }}
              onChange={(_e, newValue) => {
                if (!newValue || newValue.length <= 50) {
                  this.setState({ firstName: newValue || "" });
                } else {
                  this.setState({ firstName: firstName });
                }
              }}
              required={firstName.length < 1}
              autoComplete="on"
            />
          </Stack.Item>
          <Stack.Item>
            <TextField
              label={strings.buyPhysicalTags_lastName}
              value={lastName}
              styles={{ fieldGroup: { width: 250 } }}
              onChange={(_e, newValue) => {
                if (!newValue || newValue.length <= 50) {
                  this.setState({ lastName: newValue || "" });
                } else {
                  this.setState({ lastName: lastName });
                }
              }}
              required={lastName.length < 1}
              autoComplete="on"
            />
          </Stack.Item>
          <Stack.Item>
            <TextField
              label={strings.buyPhysicalTags_address}
              value={address}
              styles={{ fieldGroup: { width: 250 } }}
              onChange={(_e, newValue) => {
                if (!newValue || newValue.length <= 50) {
                  this.setState({ address: newValue || "" });
                } else {
                  this.setState({ address: address });
                }
              }}
              required={address.length < 1}
              autoComplete="on"
            />
          </Stack.Item>
          <Stack.Item>
            <TextField
              label={strings.buyPhysicalTags_zipCode}
              value={zipCode}
              styles={{ fieldGroup: { width: 120 } }}
              onChange={(_e, newValue) => {
                if (!newValue || newValue.length < 7 || newValue.length > 9) {
                  this.setState({ zipCode: newValue || "" });
                } else {
                  this.setState({ zipCode: zipCode });
                }
              }}
              required={zipCode.trim().length < 4}
              autoComplete="on"
            />
          </Stack.Item>
          <Stack.Item>
            <TextField
              label={strings.buyPhysicalTags_city}
              value={city}
              styles={{ fieldGroup: { width: 250 } }}
              onChange={(_e, newValue) => {
                if (!newValue || newValue.length <= 50) {
                  this.setState({ city: newValue || "" });
                } else {
                  this.setState({ city });
                }
              }}
              required={city.length < 1}
              autoComplete="on"
            />
          </Stack.Item>
          <Stack.Item>
            <TextField
              label={strings.buyPhysicalTags_country}
              value={country}
              styles={{ fieldGroup: { width: 250 } }}
              onChange={(_e, newValue) => {
                if (!newValue || newValue.length <= 50) {
                  this.setState({ country: newValue || "" });
                } else {
                  this.setState({ country });
                }
              }}
              // required={country.length < 1}
              autoComplete="on"
            />
          </Stack.Item>
          <StackVert>
            <Stack.Item>
              <ParagraphText>
                <a href="/privacy" target="_blank">
                  <i>{strings.buyDigialTags_howHandlePersonaInfoHere}</i>
                </a>
                {" " + strings.buyDigialTags_howHandlePersonaInfo}
              </ParagraphText>
            </Stack.Item>
            <Stack.Item>
              <PrimaryButton onClick={this._registerAddress}>{strings.registerUser_goToPayment}</PrimaryButton>
            </Stack.Item>
          </StackVert>
        </Content>
      </StackVert>
    );
  }

  _registerAddress = async () => {
    await Api.registerBuyerInfo(
      this.state.firstName.trim(),
      this.state.lastName.trim(),
      this.state.address.trim(),
      this.state.zipCode.trim(),
      this.state.city.trim(),
      (this.state.country || "").trim(),
      this.state.email.trim()
    );
    this.props.onStartPurchase();
  };
}
