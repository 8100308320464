import { PrimaryButton, Stack, mergeStyleSets } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Api from "../api";
import { Store } from "../models";
import { Content } from "./Content";
import { IconTableRow, ParagraphLabel, StackHorizontal, StackVert } from "./Controls";
import { CreateStoreDialog } from "./CreateStoreDialog";
import { Header } from "./Header";
import { ListStores } from "./ListStores";
import { Language } from "./Strings";
import { TitleWithInfo } from "./TitleWithInfo";

const strings = Language.strings;

export const Stores = withRouter(({ history }) => {
  const [stores, setStores] = useState<Store[]>([]);
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    if (hidden) {
      Api.getStores()
        .then((stores) => {
          setStores(stores);
        })
        .catch((error) => console.warn("Faied to get stores", error));
    }
  }, [hidden]);

  const onStoreSelected = (store: Store) => {
    // TODO: check if user has the list in the pivot
    // TODO: add to pivot items if not
    // TODO: navigate to list pivot item
    // history.push(`store/${store.id}`);
    history.push(`/?list=${store.id}`);
  };

  const showDialog = () => setHidden(false);
  const hideDialog = () => setHidden(true);
  const createStore = (store: Store) => {
    Api.createStore(store)
      .then(() => hideDialog())
      .catch((error) => console.warn(error));
  };

  const classNamesIconTableRow = mergeStyleSets({
    table: {
      fontFamily: "arial, sans-serif",
      borderCollapse: "collapse",
      width: "100%",
    },
    td_th: {
      border: "1px solid #dddddd",
      textAlign: "left",
      padding: "8px",
    },
  });



  return (
    <StackVert>
      <Header />
      <Content>
        <StackVert>
          <TitleWithInfo title={strings.stores_ManageLists} iconFileName={""} infoTitle={strings.stores_infoHeader}>
            <ul>
              {/* TODO: Add the information and add in strings */}
              <li>{strings.stores_info1}</li>
              <li>{strings.stores_info2}</li>
              <li>{strings.stores_info3}</li>
              <li>{strings.stores_info4}</li>
            </ul>
            <Stack.Item>
              <ParagraphLabel>{strings.home_iconTableWhatDoTheIconsMean}</ParagraphLabel>
            </Stack.Item>
            <Stack.Item>
              <table className={classNamesIconTableRow.table}>
                <thead>
                  <tr>
                    <th className={classNamesIconTableRow.td_th}>{strings.home_iconTableHeaderIcon}</th>
                    <th className={classNamesIconTableRow.td_th}>{strings.home_iconTableHeaderDescription}</th>
                  </tr>
                </thead>
                <tbody>
                  <IconTableRow iconName="ICON_Home_32.png" description={"STR: This list belongs to you"} />
                  <IconTableRow
                    iconName="ICON_HomeAtOther_64.png"
                    description={"STR: This is someone else's list"}
                  />
                </tbody>
              </table>
            </Stack.Item>
          </TitleWithInfo>
          {/* TODO: add a MultiItemSelect to choose which list to filter on
          TODO: Add properties on Lists: type of tag */}
          <Stack.Item>
            <ListStores items={stores} busy={false} onSelected={onStoreSelected} />
          </Stack.Item>
          <StackHorizontal>
            <Stack.Item>
              <PrimaryButton onClick={showDialog}>{strings.stores_CreateStore}</PrimaryButton>
            </Stack.Item>
            <Stack.Item align="end">
              <PrimaryButton onClick={() => history.goBack()}>{strings.generic_back}</PrimaryButton>
            </Stack.Item>
          </StackHorizontal>
        </StackVert>
      </Content>
      <CreateStoreDialog hidden={hidden} onDismiss={hideDialog} onCreateStore={createStore} />
    </StackVert>
  );
});
