import React from "react";
import { Stack, TextField, PrimaryButton, Checkbox, FontIcon } from "@fluentui/react";
import { Header } from "./Header";
import { Content } from "./Content";
import { StackVert, PageTitle, ParagraphText } from "./Controls";
import { Helpers } from "../helpers";
import Api from "../api";
import { Language } from "./Strings";

const strings = Language.strings;

interface ConfigLostProps {
  onChanged: () => void;
  qrCode: string;
}

interface ConfigLostState {
  busy: boolean;
  lostPayValue: string;
  showHowMuchPay: boolean;
  enablePosition: boolean;
  offerPay: boolean;
  possibleSendMess: boolean;
  showPhoneNumber: boolean;

  showInfoSendMess: boolean;
  showInfoOfferPay: boolean;
  showInfoShowHowMuchPay: boolean;
}

export class ConfigLost extends React.Component<ConfigLostProps, ConfigLostState> {
  constructor(props: ConfigLostProps) {
    super(props);

    this.state = {
      busy: false,
      lostPayValue: "",
      showHowMuchPay: false,
      enablePosition: false,
      offerPay: false,
      possibleSendMess: false,
      showPhoneNumber: false,
      showInfoSendMess: false,
      showInfoOfferPay: false,
      showInfoShowHowMuchPay: false,
    };
  }

  private _infoSendMess = React.createRef<HTMLDivElement>();
  private _infoOfferPay = React.createRef<HTMLDivElement>();
  private _infoShowHowMuchPay = React.createRef<HTMLDivElement>();

  render() {
    const { showHowMuchPay, offerPay, possibleSendMess, showPhoneNumber } = this.state;
    return (
      <StackVert>
        <Header />
        <Content>
          <Stack.Item>
            <PageTitle>{strings.editLostFound_settingsLost}</PageTitle>
            <ParagraphText>{strings.editLostFound_settingsLostText}</ParagraphText>
          </Stack.Item>
          <Stack.Item>
            <Checkbox
              label={strings.editLostFound_IwantFinderToSeePhoneNumber}
              checked={showPhoneNumber}
              onChange={(_ev, checked) => this.setState({ showPhoneNumber: !!checked })}
            />
          </Stack.Item>
          <Stack.Item>
            <Stack horizontal>
              <Checkbox
                label={strings.editLostFound_IwantFinderAbleSendMess}
                checked={possibleSendMess}
                onChange={(_ev, checked) => this.setState({ possibleSendMess: !!checked })}
              />
              <div ref={this._infoSendMess}>
                <FontIcon iconName="Info" onClick={() => this.setState({ showInfoSendMess: true })} />
                {/* {this.state.showInfoSendMess && <Callout className={styles.callout} target={this._infoSendMess.current}>
                           <Stack style={{ margin: 10 }}>{strings.configLost_withThisSettingFinderShowPos}</Stack>
                        </Callout>} */}
              </div>
              {/* <ParagraphText>Med denna inställning kan den som hittat prylen skicka ett meddelande till
                        dig om var hen lagt den eller lämnat in den, utan att röja sitt telefonnummer.
                        Det kan göra att fler återlämnar prylen.
                        </ParagraphText> */}
            </Stack>
          </Stack.Item>
          <Stack.Item>
            <Stack horizontal>
              <Checkbox
                label={strings.editLostFound_IwantOfferLostPay}
                checked={offerPay}
                onChange={(_ev, checked) => this.setState({ offerPay: !!checked })}
              />
              <div ref={this._infoOfferPay}>
                <FontIcon iconName="Info" onClick={() => this.setState({ showInfoOfferPay: true })} />
                {/* {this.state.showInfoOfferPay && <Callout className={styles.callout} target={this._infoOfferPay.current}>
                           <Stack style={{ margin: 10 }}>{strings.configLost_IwantOfferLostPayInfo}</Stack>
                        </Callout>} */}
              </div>
            </Stack>
          </Stack.Item>
          <Stack.Item>
            <Stack horizontal>
              <Checkbox
                label={strings.editLostFound_IwantFinderSeeHowMuchPay}
                checked={showHowMuchPay}
                onChange={(_ev, checked) => this.setState({ showHowMuchPay: !!checked })}
              />
              <div ref={this._infoShowHowMuchPay}>
                <FontIcon iconName="Info" onClick={() => this.setState({ showInfoShowHowMuchPay: true })} />
                {/* {this.state.showInfoShowHowMuchPay && <Callout className={styles.callout} target={this._infoShowHowMuchPay.current}>
                           <Stack style={{ margin: 10 }}>{strings.configLost_IwantFinderSeeHowMuchPayInfo}</Stack>
                        </Callout>} */}
              </div>
            </Stack>
          </Stack.Item>
          <Stack.Item>
            <TextField
              id="lostPayValue"
              label={strings.editLostFound_HowMuchLostPay}
              value={this.state.lostPayValue}
              onChange={(_e, value) => this.setState({ lostPayValue: value || "" })}
            />
            <ParagraphText>{strings.editLostFound_HowMuchLostPayInfo}</ParagraphText>
          </Stack.Item>
          {/* <Stack.Item>   
                        <Checkbox
                            label={strings.configLost_IwantEnablePosition} 
                            checked={enablePosition}
                            onChange={(_ev, checked) => this.setState({ enablePosition: !!checked })} />
                        <ParagraphText>{strings.configLost_HowMuchLostPayInfo}
                        </ParagraphText>
                    </Stack.Item> */}
          {
            <Stack.Item>
              <PrimaryButton onClick={this._registerLostCfg}>{strings.generic_save}</PrimaryButton>
            </Stack.Item>
          }
        </Content>
      </StackVert>
    );
  }

  private _registerLostCfg = async () => {
    this.setState({ busy: true });
    const userId = Helpers.getCurrentUserId();

    await Api.registerLostCfg(
      this.props.qrCode!,
      userId,
      this.state.lostPayValue.trim(),
      this.state.showHowMuchPay,
      this.state.offerPay,
      this.state.possibleSendMess,
      this.state.showPhoneNumber
    );
    this.setState({ busy: false });
    this.props.onChanged();
  };
}
