import React from 'react';
import { StackVert } from './Controls';
import { Header } from './Header';
import { Content } from './Content';
// import Api from '../api';

export const BuyTagsPage = () => {
    // const [] = useState(0);
    // const [] = useState(false);
    // const [] = useState(1);

    // const registerPurchase = (skuId: string) => {
    //     // TODO: call Api.registerPurchase(skuId)
    // }

    return <StackVert>
        <Header />
        <Content>
            {/* TODO: split this up and use App.js routes instead => done!
            <PageTitle>{'STR: Buy tags page title'}</PageTitle>
            {step === 1 && <BuyTags
                onBuyTags={(numTags: number, buyDigital: boolean) => {
                    setNumTags(numTags);
                    setbuyDigital(buyDigital);
                    setStep(2);
                }}
            />}
            {step === 2 && !buyDigital && <BuyPhysicalTags
                onStartPurchase={() => {
                    setStep(3);
                }}
            />}
            {step === 2 && buyDigital && <BuyDigitalTags
                onStartPurchase={() => {
                    setStep(3);
                }}
            />}
            {step === 3 && <Purchasing />} */}

            {/* TODO: Get and set purchase when done. This is just sample code */}
            {/* {purchases = Api.getPurchases(userID)}   
            {Api.registerPurchase(userId, buyDigital, tagSent, stateOrAction, paymentCompleted)} 
            TODO: send the createcodebatch request to the phyton code. 
            # Parameters:
            #   purchaseID is the purchase ID for the just registered purchase. 
            #   userBatchCnt = is the number of batches that this user has purchased in this transaction. May not be needed. to discuss
            #   nrQRs = the number of tags purchased this time (how many should be created as pdfs. If more than 48, there will be multiple pdfs, with the first named
                [batchID]_1.pdf, the second [batchID]_2.pdf, etc)
            #   CreaceCodeBatch retrieves the userID and the user email from the database, based on the purchaseID.
            http://localhost:7071/api/CreateCodeBatch?purchaseID=8648dc6a-2f29-469c-bcd8-08d8b0b1a0fc&userBatchCnt=1&nrQRs=12 
            */}
        </Content>
    </StackVert>;
}