import React, { useEffect, useState } from "react";
import { Panel, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from "@fluentui/react";
import { CodeShowAndLinkToQRImage, ParagraphLabel, StackHorizontal, StackVert } from "./Controls";
import { Language } from "./Strings";
import Api from "../api";
import { useObserveCode } from "../hooks/useObserveCode";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ItemFooterSave } from "./ItemFooterSave";
import { TitleWithInfo } from "./TitleWithInfo";
import { ListsDropdown } from "./ListsDropdown";
import { useObserveUser } from "../hooks";
import { CodePropsType } from "../models";
import { ImagesPanel } from "./ImagesPanel";
import { UploadDialog } from "./UploadDialog";

const strings = Language.strings;

interface HtmlProps extends RouteComponentProps {
  qrCode: string;
  header: string;
  onSaved: () => void;
  onChangeType: (type: CodePropsType) => void;
}

export const EditHtml = withRouter(({ qrCode, header, history, onSaved, onChangeType }: HtmlProps) => {
  const [isValid, setIsValid] = useState(false);
  const [, setInfo] = useState(""); // TODO: display error and info messages!
  const [, setError] = useState("");

  const {
    code,
    busy,
    save,
    name,
    setName,
    description,
    setDescription,
    unregister,
    images,
    defaultImageId,
    setDefaultImageId,
    addImage,
    refreshImages,
    codeStores,
    setCodeStores,
    ownerUserId,
    changed,
    copyCodeFromLocalSettings,
  } = useObserveCode({
    code: qrCode,
    onSaved,
    onUnregistered: () => history.replace("/"),
  });

  const { stores } = useObserveUser();

  useEffect(() => {
    setIsValid(name !== "" || description !== "" || images.length > 0);
  }, [name, description, images]);

  // useEffect(() => {
  //   Api.getProperties("user").then((properties) => {
  //     console.warn(properties);
  //   });
  // }, []);

  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [showEditImagesPanel, setShowEditImagesPanel] = useState(false);
  const [quickAdd, setQuickAdd] = useState(false);

  return (
    <>
      <StackVert>
        <Stack.Item>
          <TitleWithInfo title={header} iconFileName="FileHTML" infoTitle={strings.editHtml_infoHeader} code={qrCode}>
            <ParagraphLabel>{(strings.editHtml_Instructions = "Instructions")}</ParagraphLabel>
            <ul>
              <li>{strings.editHtml_Info1}</li>
              <li>{strings.editHtml_Info2}</li>
              <li>{strings.editHtml_Info3}</li>
              <li>{strings.editHtml_Info4}</li>
              <li>{strings.editHtml_Info5}</li>
            </ul>
          </TitleWithInfo>
          {/* <ParagraphText>{strings.configLost_settingsLostText}</ParagraphText> */}
        </Stack.Item>
        <Stack.Item>
          <TextField
            id="prylnamn"
            label={strings.registerItem_messageName}
            value={name}
            onChange={(_e, value) => setName(value || "")}
          />
        </Stack.Item>
        <Stack.Item>
          <TextField
            multiline
            autoAdjustHeight
            label={strings.registerItem_messageHtml}
            value={description}
            onChange={(_e, value) => setDescription(value || "")}
          />
        </Stack.Item>
        <Stack.Item>
          <ParagraphLabel>{strings.editStatic_photo}</ParagraphLabel>
        </Stack.Item>
        <Stack.Item>
          {!showUploadDialog && images.length > 0 && (
            <ImagesPanel
              viewMode
              defaultImageId={defaultImageId}
              setDefaultImageId={setDefaultImageId}
              code={qrCode}
              images={images}
            />
          )}
        </Stack.Item>
        <Stack.Item>
          <StackHorizontal>
            <PrimaryButton
              disabled={!code}
              onClick={() => {
                setQuickAdd(false);
                setShowEditImagesPanel(true);
              }}
            >
              {strings.generic_manageImages}
            </PrimaryButton>
            <PrimaryButton
              disabled={!code}
              onClick={() => {
                setQuickAdd(true);
                setShowEditImagesPanel(true);
              }}
            >
              {strings.generic_quickAddImage}
            </PrimaryButton>
          </StackHorizontal>
        </Stack.Item>
        <Stack.Item>
          {code && (
            <Panel
              headerText={strings.generic_manageImages}
              isOpen={showEditImagesPanel}
              isLightDismiss
              onDismiss={() => setShowEditImagesPanel(false)}
              closeButtonAriaLabel={strings.generic_close}
            >
              <ImagesPanel
                code={code.id}
                images={images}
                defaultImageId={defaultImageId}
                setDefaultImageId={setDefaultImageId}
                onImageAdded={() => addImage()}
                onImageDeleted={refreshImages}
                quickAdd={quickAdd}
              />
              <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign="end">
                <PrimaryButton onClick={() => setShowEditImagesPanel(false)}>{strings.generic_close}</PrimaryButton>
              </Stack>
            </Panel>
          )}
        </Stack.Item>
        <Stack.Item>
          {code && (
            <UploadDialog
              hidden={!showUploadDialog}
              filename={code!.id} // TODO: Fix...
              onDismiss={() => {
                setShowUploadDialog(false);
              }}
              code={qrCode}
            />
          )}
        </Stack.Item>
        <CodeShowAndLinkToQRImage code={code?.id || ""}></CodeShowAndLinkToQRImage>
        <Stack.Item>{busy && <Spinner size={SpinnerSize.large} />}</Stack.Item>
        <Stack.Item>
          <ListsDropdown
            setCodeStores={setCodeStores}
            label={strings.editStatic_ListsHeader}
            selectedLists={codeStores.map((s) => s.id)}
            lists={stores}
            ownerUserId={ownerUserId}
            setError={setError}
            setInfo={setInfo}
          />
        </Stack.Item>
        <ItemFooterSave
          code={qrCode}
          onSave={save}
          isValid={isValid}
          onUnregister={unregister}
          onChangeType={onChangeType}
          canSave={changed && !busy}
          onPasteCode={copyCodeFromLocalSettings}
        />
      </StackVert>
    </>
  );
});
