import React from "react";
import { FocusZone, IRectangle, List, Stack } from "@fluentui/react";
import { ParagraphText, ParagraphTitle } from ".";
import { CodeInfo } from "../models";
import { ItemImage } from "./ItemImage";

interface ItemGridViewProps {
  items: CodeInfo[];
  onItemInvoked: (item?: CodeInfo) => void;
}

export const ItemGridView = ({ items, onItemInvoked }: ItemGridViewProps) => {
  const ROWS_PER_PAGE = 3;
  const MAX_ROW_HEIGHT = 250;
  const columnCount = React.useRef(0);
  const rowHeight = React.useRef(0);

  const getPageHeight = (): number => {
    return rowHeight.current * ROWS_PER_PAGE;
  };

  const getItemCountForPage = (itemIndex?: number, visibleRect?: IRectangle) => {
    if (itemIndex === 0 && visibleRect) {
      columnCount.current = Math.ceil(visibleRect.width / MAX_ROW_HEIGHT);
      rowHeight.current = Math.floor(visibleRect.width / columnCount.current);
    }
    return columnCount.current * ROWS_PER_PAGE;
  };

  return (
    <FocusZone>
      <List
        items={items}
        onRenderCell={React.useCallback((item?: CodeInfo) => {
          if (!item) return null;
          const id = item.properties ? item.properties["defaultImageId"] || null : null;
          return (
            <div
              className="grid-box"
              data-is-focusable
              onClick={() => onItemInvoked(item)}
              // style={{ borderWidth: 1, width: "30vw", position: "relative", float: "left", height: "30vw" }}
            >
              <div style={{ overflow: "hidden" }}>
                {!id && item.type === "static" && (
                  <Stack.Item styles={{ root: { height: "100%" } }}>
                    {item.name && <ParagraphTitle nowrap>{item.name}</ParagraphTitle>}
                    {item.description && <ParagraphText>{item.description}</ParagraphText>}
                  </Stack.Item>
                )}
                {!id && item.type === "boxes" && (
                  <Stack.Item styles={{ root: { height: "100%" } }}>
                    {item.shortID && item.name && (
                      <ParagraphTitle nowrap>{item.shortID + " - " + item.name}</ParagraphTitle>
                    )}
                    {item.shortID && !item.name && <ParagraphTitle nowrap>{item.shortID}</ParagraphTitle>}
                    {!item.shortID && item.name && <ParagraphTitle nowrap>{item.name}</ParagraphTitle>}
                    {item.description && <ParagraphText>{item.description}</ParagraphText>}
                  </Stack.Item>
                )}
                {!id && item.type === "lostFound" && (
                  <Stack.Item styles={{ root: { height: "100%" } }}>
                    {item.name && <ParagraphTitle nowrap>{item.name}</ParagraphTitle>}
                    {item.description && <ParagraphText>{item.description}</ParagraphText>}
                  </Stack.Item>
                )}
                {item.type === "freezer" && (
                  <Stack.Item styles={{ root: { height: "100%" } }}>
                    <ParagraphTitle nowrap align={"center"}>
                      {item.shortID}
                    </ParagraphTitle>
                    {item.properties.foodType && item.properties.contentType && (
                      <ParagraphText>{item.properties.contentType + " - " + item.properties.foodType}</ParagraphText>
                    )}
                    {item.properties.foodType && !item.properties.contentType && (
                      <ParagraphText>{item.properties.foodType}</ParagraphText>
                    )}
                    {!item.properties.foodType && item.properties.contentType && (
                      <ParagraphText>{item.properties.contentType}</ParagraphText>
                    )}
                    {item.description && <ParagraphText>{item.description}</ParagraphText>}
                  </Stack.Item>
                )}
                {item.type === "html" && (
                  <Stack.Item styles={{ root: { height: "100%" } }}>
                    {item.description && <ParagraphText>{item.description}</ParagraphText>}
                  </Stack.Item>
                )}
                {id && item.type === "static" && (
                  <Stack.Item styles={{ root: { height: "100%" } }}>
                    <ItemImage id={id} isSmall />
                  </Stack.Item>
                )}
                {id && item.type === "boxes" && (
                  <Stack.Item styles={{ root: { height: "100%" } }}>
                    <ParagraphTitle align={"center"} nowrap>
                      {item.shortID}
                    </ParagraphTitle>
                    <ItemImage id={id} isSmall />
                  </Stack.Item>
                )}
                {id && item.type === "lostFound" && (
                  <Stack.Item styles={{ root: { height: "100%" } }}>
                    <ItemImage id={id} isSmall />
                  </Stack.Item>
                )}
              </div>
            </div>
          );
        }, [])}
        getItemCountForPage={getItemCountForPage}
        getPageHeight={getPageHeight}
        renderedWindowsAhead={4}
      />
    </FocusZone>
  );
};
