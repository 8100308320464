import React from "react";
import { Stack, Image, TextField, MessageBar } from "@fluentui/react";
import { Helpers } from "../helpers";
import { Content } from "./Content";
import { StackVert, ParagraphTitle, ParagraphText } from "./Controls";
import Api from "../api";
import { Language } from "./Strings";

const strings = Language.strings;

interface PurchasingProps {}

interface PurchasingState {
  fullname: string;
  address: string;
  zipCode: string;
  city: string;
  country: string;
  numTags?: number;
  buyDigital?: boolean;
  email: string;
}

export class Purchasing extends React.Component<PurchasingProps, PurchasingState> {
  constructor(props: PurchasingProps) {
    super(props);

    this.state = {
      fullname: "",
      address: "",
      zipCode: "",
      city: "",
      country: "",
      numTags: undefined,
      buyDigital: undefined,
      email: "",
    };

    // Should we read the address and tag info from the DB already in the constructor? If not, where? Should we get it in as an argument?
  }

  async componentDidMount() {
    if (Helpers.isLoggedIn()) {
      const userInfo = await Api.getUserInfo(Helpers.getCurrentUserId());
      const { firstName, lastName, address, zipCode, city, country, email } = userInfo;
      this.setState({ fullname: firstName + " " + lastName, address, zipCode, city, country, email });
    }
  }

  render() {
    const isLoggedIn = Helpers.isLoggedIn();
    const { fullname, address, zipCode, city, country, email } = this.state;
    return (
      <StackVert>
        {/* <Header /> */}
        <Content>
          <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: "80%" } }}>
            {!isLoggedIn && (
              <MessageBar>
                {strings.puchasing_youLoggedInLoginNow}
                <br />
              </MessageBar>
            )}
            <Stack.Item>
              <h1>{strings.purchasing_payment}</h1>
            </Stack.Item>
            <Stack.Item>
              <ParagraphText>{strings.purchasing_ifPurchaseDigital}</ParagraphText>
              <ParagraphText>{strings.purchasing_ifPurchasePhysical}</ParagraphText>
            </Stack.Item>
            <Stack.Item>
              <ParagraphTitle>{strings.purchasing_address}</ParagraphTitle>
            </Stack.Item>
            <Stack.Item>
              <TextField
                id="fullname"
                label={strings.purchasing_fullName}
                value={fullname}
                onChange={(_event, newValue?: string) => this.setState({ fullname: newValue || "" })}
              />
              <TextField
                id="address"
                label={strings.purchasing_address}
                value={address}
                onChange={(_event, newValue?: string) => this.setState({ address: newValue || "" })}
              />
              <TextField
                id="zipCode"
                label={strings.purchasing_zipCode}
                value={zipCode}
                onChange={(_event, newValue?: string) => this.setState({ zipCode: newValue || "" })}
              />
              <TextField
                id="city"
                label={strings.purchasing_city}
                value={city}
                onChange={(_event, newValue?: string) => this.setState({ city: newValue || "" })}
              />
              <TextField
                id="country"
                label={strings.purchasing_country}
                value={country}
                onChange={(_event, newValue?: string) => this.setState({ country: newValue || "" })}
              />
            </Stack.Item>
            <Stack.Item></Stack.Item>
            <Stack.Item>
              <ParagraphTitle>{strings.purchasing_email}</ParagraphTitle>
              <ParagraphText>{email}</ParagraphText>
              <TextField placeholder={strings.purchasing_emailPlaceholder} />
            </Stack.Item>
            <Stack.Item></Stack.Item>
            <Stack.Item>
              <ParagraphTitle>{strings.purchasing_order}</ParagraphTitle>
            </Stack.Item>
            <Stack.Item></Stack.Item>
            <Stack.Item>
              <ParagraphText>{strings.purchasing_paySwishInstructions}</ParagraphText>
              <Image src="/swish_qr_code.png" alt="Swish" width={100} height={100} />
            </Stack.Item>
            {/* <Stack.Item>
                            <PrimaryButton onClick={this._payWithPaypal}>{strings.purchasing_payPaypal}</PrimaryButton>
                        </Stack.Item>
                        <Stack.Item>
                            <PrimaryButton onClick={this._payWithSwich}>{strings.purchasing_paySwish}
                            </PrimaryButton>
                        </Stack.Item>
                        <Stack.Item>
                            <PrimaryButton onClick={this._payWithKlarna}>{strings.purchasing_payKlarna}
                            </PrimaryButton>
                        </Stack.Item> */}
          </Stack>
        </Content>
      </StackVert>
    );
  }

  // _getUserContactInfo = async () => {
  //     // TODO: Write the requestAddress Api and return it
  //     Api.getUserInfo(this.props.userID.trim());
  // }

  _getUserOrderInfo = async () => {
    // TODO: Write the getOrderDetails Api
    //Api.getOrderDetails(this.props.userID.trim());
  };

  _payWithPaypal = () => {
    // TODO: pay with paypal
  };

  _payWithSwich = () => {
    // TODO: pay with Swich
  };

  _payWithKlarna = () => {
    // TODO: pay with Klarna
  };

  // _register = async () => {
  //     const userId = await Api.registerUser(phone.trim(), name.trim(), code.trim());
  //     if (userId !== '') {
  //         this.props.onRegister(userId);
  //     }
  //     else {
  //         alert('Kontrollera registreringskoden och försök igen!');
  //         this.setState({ codeSent: false });
  //     }
  // }
}
