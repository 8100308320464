import React from "react";
import { Stack, TextField, PrimaryButton, MessageBar, MessageBarType, Dropdown } from "@fluentui/react";
import Api from "../api";
import { PageTitle, ParagraphText, StackVert } from "./Controls";
import { Language } from "./Strings";
import { CompanyInfo } from "../models";

const strings = Language.strings;

interface RegisterUserProps {
  onRegister: (userId: string, token: string, companyPhone?: string) => void;
}

interface RegisterUserState {
  name: string;
  phone: string;
  code: string;
  codeSent: boolean;
  usernameNeeded: boolean;
  privacyChecked: boolean;
  message?: string;
  error?: string;
  companies: CompanyInfo[];
  company: string;
  email: string;
}

export class RegisterUser extends React.Component<RegisterUserProps, RegisterUserState> {
  constructor(props: RegisterUserProps) {
    super(props);

    this.state = {
      name: "",
      phone: "",
      code: "",
      codeSent: false,
      privacyChecked: false,
      usernameNeeded: false,
      companies: [],
      company: "",
      email: "",
    };
  }

  render() {
    const { codeSent, companies, company, email } = this.state;
    const isUsPhone = this.state.phone.trim().startsWith("+1");
    const emailOk = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    return (
      <StackVert>
        {/* <Header /> */}
        <Stack.Item>
          <PageTitle>{strings.registerUser_registerUser}</PageTitle>
          <ParagraphText>{strings.registerUser_InfoAboutFree}</ParagraphText>
        </Stack.Item>
        <Stack.Item>
          {/* <Stack horizontal tokens={{ childrenGap: 15 }}> */}
          {/* <Stack.Item> */}
          <TextField
            label={strings.registerUser_registerPhone}
            value={this.state.phone}
            styles={{ fieldGroup: { width: 150 } }}
            type="tel"
            id="phone"
            autoFocus
            disabled={codeSent}
            onChange={(_e, newValue) => {
              // Johan, hur gör jag detta? Rensar white spaces och -
              // newValue = newValue || '';
              // newValue.replace(/\s/g, '');
              // newValue.replace(/-/g, '');
              if (!newValue || newValue.length <= 16) {
                this.setState({ phone: newValue || "" });
              } else {
                this.setState({ phone: this.state.phone, codeSent: false });
              }
            }}
            required={!this.state.phone.startsWith("+")}
            description={strings.registerUser_registerphoneNumMustStartWith}
            autoComplete="on"
          />
        </Stack.Item>
        {isUsPhone && (
          <Stack.Item>
            <TextField
              label={strings.registerUser_registerEmail}
              value={email}
              styles={{ fieldGroup: { width: 250 } }}
              type="email"
              id="email"
              required={!emailOk}
              disabled={codeSent}
              onChange={(_e, newValue) => {
                this.setState({ ...this.state, email: newValue || "" });
              }}
              autoComplete="on"
            />
          </Stack.Item>
        )}
        <Stack.Item>
          {/* <Stack.Item align="end"> */}
          <PrimaryButton
            disabled={!this.state.phone.startsWith("+") || this.state.codeSent || (isUsPhone && !emailOk)}
            onClick={this._requestCode}
          >
            {strings.registerUser_sendCode}
          </PrimaryButton>
          {/* </Stack.Item> */}
          {/* </Stack> */}
        </Stack.Item>
        {companies.length > 0 && (
          <Dropdown
            label={strings.registerUser_organization}
            options={[
              { key: this.state.phone, text: `${strings.registerUser_meAsUser} (${this.state.phone})` },
              ...companies.map((company) => ({ key: company.phone, text: company.name })),
            ]}
            selectedKey={company}
            onChange={(_ev, option) => {
              if (option && option.key) this.setState({ company: option.key as string });
            }}
          />
        )}
        {this.state.usernameNeeded && this.state.codeSent && (
          <Stack.Item>
            <TextField
              label={strings.registerUser_registerUserName}
              value={this.state.name}
              id="name"
              styles={{ fieldGroup: { width: 250 } }}
              onChange={(_e, newValue) => {
                if (!newValue || newValue.length <= 32) {
                  this.setState({ name: newValue || "" });
                } else {
                  this.setState({ name: this.state.name });
                }
              }}
              required={this.state.name.length < 5}
              autoComplete="on"
              description={strings.registerUser_registerUsernameNeeded}
            />
          </Stack.Item>
        )}
        <Stack.Item>
          <TextField
            label={strings.registerUser_code}
            styles={{ fieldGroup: { width: 100 } }}
            type="number"
            disabled={/*this.state.phone.trim().length < 8*/ !this.state.codeSent}
            value={this.state.code}
            onChange={(_e, newValue) => {
              if (!newValue || newValue.length <= 4) {
                this.setState({ code: newValue || "" });
              } else {
                this.setState({ code: this.state.code });
              }
            }}
            required={this.state.code.trim().length !== 4}
          />
        </Stack.Item>
        <Stack.Item>
          {strings.registerUser_iAgreeTerms}
          <a href="/privacy" target="_blank">
            <i>{strings.registerUser_privacyAgreement}</i>
          </a>
          {strings.registerUser_iAgreeTermsEnd}
          {/* <Checkbox
                        label={strings.registerUser_iAgreeTerms}
                        checked={this.state.privacyChecked}
                        onChange={(_ev, checked) => this.setState({ privacyChecked: !!checked })} /> */}
        </Stack.Item>
        <Stack.Item>
          {this.state.error && <MessageBar messageBarType={MessageBarType.error}>{this.state.error}</MessageBar>}
          {this.state.message && <MessageBar messageBarType={MessageBarType.info}>{this.state.message}</MessageBar>}
        </Stack.Item>
        <Stack.Item>
          <PrimaryButton
            disabled={
              this.state.code.trim().length !== 4 ||
              this.state.name.trim().length === 0 ||
              this.state.phone.trim().length === 0 ||
              (isUsPhone && !emailOk)
            }
            onClick={this._register}
          >
            {strings.registerUser_registerUserButton}
          </PrimaryButton>
        </Stack.Item>
      </StackVert>
    );
  }

  _requestCode = async () => {
    try {
      const phone = this.state.phone.trim();
      const email = this.state.email.trim();
      const companies = await Api.requestRegistrationCode(phone, strings.registerUser_smsTextCode, email);
      this.setState({ codeSent: true, companies, company: phone });

      const username = await Api.getUsernameByPhone(phone);
      this.setState({ name: username, usernameNeeded: username === "" });
    } catch {
      this.setState({ error: strings.registerUser_networkErrorTryAgain });
    }
  };

  _register = async () => {
    try {
      const { userId, token } = await Api.registerUser(
        this.state.phone.replace(/[^+0-9]/g, "").trim(),
        this.state.email.trim(),
        this.state.name.trim(),
        this.state.code.trim(),
        this.state.companies.length > 0 ? this.state.company : ""
      );
      if (userId !== "") {
        // TODO: save userId here instead?
        this.props.onRegister(userId, token);
      } else {
        this.setState({ message: strings.registerUser_checkCodeTryAgain, codeSent: false });
      }
    } catch {
      this.setState({ error: strings.registerUser_networkErrorTryAgain2 });
    }
  };

  _getFilteredPhoneNumber = () => {
    // TODO: implement and use!
    let phone = this.state.phone.trim();
    // TODO: remove spaces and dashes
    return phone;
  };
}
