import * as React from "react";

export class Content extends React.Component {
  render() {
    return (
      <div
        id="content"
        style={{
          position: "absolute",
          top: "42px",
          bottom: "0px",
          left: "0px",
          right: "0px",
          overflow: "auto",
          margin: "10px auto",
          padding: "0px 10px",
          maxWidth: "480px",
        }}
      >
        {this.props.children}
      </div>
    );
  }
}
