import React, { useEffect, useState } from "react";
import { Stack, ComboBox, PrimaryButton, mergeStyleSets, ChoiceGroup, IChoiceGroupOption } from "@fluentui/react";
import { Header } from "./Header";
import { StackVert, PageTitle, ParagraphText } from "./Controls";
import { Content } from "./Content";
import { withRouter } from "react-router-dom";
import { Sku } from "../models";
import Api from "../api";
import { Helpers } from "../helpers";
import { Language } from "./Strings";

const strings = Language.strings;

const styles = mergeStyleSets({
  buyButton: {
    whiteSpace: "nowrap",
  },
});

export const BuyTags = withRouter(({ history }) => {
  const [item, setItem] = useState("");
  const [skuList, setSkuList] = useState<Sku[]>([]);
  const selectedSku = skuList.findIndex((sku) => sku.id === item);
  // const price = selectedSku >= 0 ? skuList[selectedSku].price : 0;
  const sku = selectedSku >= 0 ? skuList[selectedSku] : null;

  const isLoggedIn = Helpers.isLoggedIn();
  if (!isLoggedIn) {
    history.replace("/register?redirect=/Buy/1");
  }

  const tagTypeOptions: IChoiceGroupOption[] = [
    {
      key: "empty",
      imageSrc: strings.buyTags_unSelectedTagEmpty,
      imageAlt: strings.buyTags_emptyTag,
      selectedImageSrc: strings.buyTags_selectedTagEmpty,
      imageSize: { width: 100, height: 49 },
      text: strings.buyTags, // This text is long to show text wrapping.
    },
    {
      key: "lostFound",
      imageSrc: strings.buyTags_unSelectedTagLostFound,
      imageAlt: strings.buyTags_lostAndFound,
      selectedImageSrc: strings.buyTags_selectedTagLostFound,
      imageSize: { width: 100, height: 49 },
      text: strings.buyTags_lostAndFound,
    },
    {
      key: "number",
      imageAlt: strings.buyTags_numbered,
      imageSrc: strings.buyTags_unSelectedTagNumber,
      selectedImageSrc: strings.buyTags_selectedTagNumber,
      imageSize: { width: 100, height: 49 },
      text: strings.buyTags_numberedForeezer,
    },
  ];

  const physDigitoptions: IChoiceGroupOption[] = [
    { key: "digital", text: "Digial" },
    { key: "physical", text: "Physical" },
  ];

  useEffect(() => {
    Api.getSkus().then((s) => setSkuList(s));
  }, []);

  return (
    <StackVert>
      <Header />
      <Content>
        <PageTitle>{strings.buyTags_headerBuyQRcodes}</PageTitle>
        <StackVert>
          <Stack.Item>
            <Stack tokens={{ childrenGap: 10 }}>
              <Stack.Item>
                <ParagraphText>{strings.buyTags_toUseMustHaveInfo}</ParagraphText>
              </Stack.Item>
              <Stack.Item>
                <ul>
                  <li>
                    <div>
                      {strings.buyTags_ifYouBuyDigial1}
                      {
                        <a href={strings.buyTags_ifYouBuyDigialPdfExampleURL}>
                          {strings.buyTags_ifYouBuyDigialPdfExampleHere}
                        </a>
                      }
                      {strings.buyTags_ifYouBuyDigial2}
                      {
                        <a href={strings.buyTags_ifYouBuyDigialAveryExampleURL}>
                          {strings.buyTags_ifYouBuyDigialAveryExampleURLHere}
                        </a>
                      }
                      {strings.buyTags_ifYouBuyDigial3}
                    </div>
                  </li>
                  <li>
                    {strings.buyTags_ifYouBuyPhysical} {strings.currency_prefix} {strings.pricePhysicalDelviery}{" "}
                    {strings.currency_suffix} {strings.buyTags_ifYouBuyPhysical2}
                  </li>
                </ul>
              </Stack.Item>
              <Stack.Item>
                <ComboBox
                  // defaultSelectedKey="12"
                  selectedKey={item.toString()}
                  options={skuList
                    .filter((sku) => strings.skuList.includes(sku.id))
                    .map((sku) => {
                      return {
                        key: sku.id,
                        text: `${sku.name}`,
                      };
                    })}
                  label={strings.buyTags_chooseNumCodes}
                  onChange={(_e, option) => {
                    if (option) setItem(option.key as string);
                  }}
                  // TODO: Set the chosen number of tags in this.state.numTags
                />
              </Stack.Item>
              <Stack.Item>
                <ChoiceGroup
                  defaultSelectedKey="digital"
                  options={physDigitoptions}
                  label={strings.buyTags_ChoosePhysDigit}
                  required={true}
                />
              </Stack.Item>
              <Stack.Item>
                <ChoiceGroup
                  label={strings.buyTags_ChooseTagLayout}
                  defaultSelectedKey="empty"
                  options={tagTypeOptions}
                />
              </Stack.Item>
              {/* <Stack.Item>
                            <ParagraphText>{strings.buyTags_chooseHereToBuyDigitalPhysical}</ParagraphText>
                        </Stack.Item> */}
              <Stack.Item>
                {sku && (
                  <ParagraphText>
                    {strings.buyTags_Price} {strings.currency_prefix} {sku.price} {strings.currency_suffix}
                  </ParagraphText>
                )}
                {!sku && <ParagraphText>{strings.buyTags_Price}</ParagraphText>}
              </Stack.Item>
              <Stack.Item>
                <PrimaryButton
                  className={styles.buyButton}
                  onClick={() => {
                    // TODO: Add if sku.isDigital
                    history.push("/buy/3?numTags=" + item); // + '&isDigital=' + sku?.isDigital  // + '&tagLayout=TODO:how to get the option?');
                    // else  history.push('/buy/2?numTags=' + item);
                    // TODO: Also update the price somewhere
                    //
                  }}
                >
                  {strings.buyTags_downloadPdf}
                </PrimaryButton>
              </Stack.Item>
            </Stack>
          </Stack.Item>
        </StackVert>
      </Content>
    </StackVert>
  );
});

// function _onChangePhysDigit(ev: React.FormEvent<HTMLInputElement>, option: IChoiceGroupOption): void {
//     console.dir(option);
// }
