import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  Dropdown,
  IComboBoxOption,
} from "@fluentui/react";
import { strings } from "./Strings";

export const AccessChangeOwnerDialog = ({
  hidden,
  onDismiss,
  onChangeOwner,
  users,
  ownerUserId,
}: {
  hidden: boolean;
  onDismiss: () => void;
  onChangeOwner: (ownerUserId: string) => void;
  users: { userId: string; name: string }[];
  ownerUserId: string;
}) => {
  const [value, setValue] = useState(ownerUserId);

  const optionsFoodTypes: IComboBoxOption[] = users.map(({ userId, name }) => {
    return { key: userId, text: name };
  });

  return (
    <Dialog
      title={strings.accessChangeOwnerDialog_ChangeOwner}
      dialogContentProps={{ subText: strings.accessChangeOwnerDialog_SubText }}
      hidden={hidden}
      onDismiss={onDismiss}
      modalProps={{ isBlocking: true }}
    >
      <DialogContent>
        <Dropdown
          options={optionsFoodTypes}
          selectedKey={value}
          onChange={(_ev, option) => {
            if (option && option.key) setValue(option.key as string);
          }}
        />
      </DialogContent>
      <DialogFooter>
        <PrimaryButton
          onClick={() => {
            onDismiss();
            onChangeOwner(value);
          }}
          text={strings.accessChangeOwnerDialog_ChangeOwner}
        />
        <DefaultButton onClick={onDismiss} text={strings.generic_cancel} />
      </DialogFooter>
    </Dialog>
  );
};
