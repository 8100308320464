import React, { useState, useEffect } from "react";
import { Stack, TextField, PrimaryButton } from "@fluentui/react";
import Api from "../api";
import { Helpers } from "../helpers";
import { Header } from "./Header";
import { StackVert, PageTitle, ParagraphText } from "./Controls";
import { Content } from "./Content";
import { Language } from "./Strings";

const strings = Language.strings;

export const BuyDigitalTags = (props: any) => {
  // const [userInfo, setUserInfo] = useState({
  //     id: '',
  //     username: '',
  //     phone: '',
  //     firstName: '',
  //     lastName: '',
  //     address: '',
  //     zipCode: '',
  //     city: '',
  //     country: 'Sweden',
  //     email: '',
  //     isAdmin: false
  // });

  const [email, setEmail] = useState("");

  useEffect(() => {
    Api.getUserInfo(Helpers.getCurrentUserId())
      .then((userInfo) => setEmail(userInfo.email))
      .catch((error) => {}); // TODO: set error and show in MessageBar
  }, []);

  // const { firstName, lastName, address, zipCode, city, country, email } = userInfo;

  const registerEmail = async () => {
    await Api.registerBuyerEmail(email.trim());
    props.onStartPurchase();
  };

  return (
    <StackVert>
      <Header />
      <Content>
        <StackVert>
          <Stack.Item>
            <PageTitle>{strings.buyDigialTags_buyTags}</PageTitle>
            <ParagraphText>{strings.buyDigialTags_fillInEmail}</ParagraphText>
          </Stack.Item>
          <Stack.Item>
            <TextField
              label={strings.buyDigialTags_email}
              value={email}
              styles={{ fieldGroup: { width: 250 } }}
              onChange={(_e, newValue) => {
                if (!newValue || newValue.length <= 50) {
                  setEmail(newValue || "");
                } else {
                  setEmail(email);
                }
              }}
              id="email"
              type="email"
              required={email.length < 5}
              autoComplete="on"
            />
          </Stack.Item>
          <Stack.Item>
            <ParagraphText>
              <a href="/privacy" target="_blank">
                <i>{strings.buyDigialTags_howHandlePersonaInfoHere}</i>
              </a>
              {" " + strings.buyDigialTags_howHandlePersonaInfo}
            </ParagraphText>
          </Stack.Item>
          <Stack.Item>
            <PrimaryButton onClick={registerEmail}>{strings.registerUser_goToPayment} </PrimaryButton>
          </Stack.Item>
        </StackVert>
      </Content>
    </StackVert>
  );
};
