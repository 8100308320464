import React, { useState } from "react";
import { Stack, PrimaryButton, TextField, Spinner, MessageBar, MessageBarType } from "@fluentui/react";
import { ParagraphLabel } from "./Controls";
import { Helpers } from "../helpers";
import { strings } from "./Strings";

export const FeedbackForm = ({ extra }: { extra: string }) => {
  const [feedback, setFeedback] = useState("");
  const [busy, setBusy] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);

  const sendFeedback = async () => {
    try {
      if (feedback.trim().length !== 0) {
        setSent(false);
        setBusy(true);
        await Helpers.postFeedbackMessage(feedback + "\n\n" + extra);
        setFeedback("");
        setSent(true);
      }
    } catch {
      setError(true);
    } finally {
      setBusy(false);
    }
  };

  return (
    <Stack>
      <Stack.Item>
        <ParagraphLabel>{strings.titleWithInfo_reportMisuse}</ParagraphLabel>
        <TextField
          multiline
          rows={4}
          value={feedback}
          resizable={false}
          onChange={(_e, newValue) => setFeedback(newValue || "")}
        />
      </Stack.Item>
      <Stack.Item>
        <Stack horizontalAlign="end" horizontal tokens={{ childrenGap: 10 }} styles={{ root: { marginTop: 10 } }}>
          {busy && <Spinner />}
          {sent && (
            <MessageBar messageBarType={MessageBarType.success} onDismiss={() => setSent(false)}>
              {strings.titleWithInfo_reportMisuseSent}
            </MessageBar>
          )}
          {error && (
            <MessageBar messageBarType={MessageBarType.error} onDismiss={() => setError(false)}>
              {strings.titleWithInfo_reportMisuseError}
            </MessageBar>
          )}
          <PrimaryButton
            disabled={busy}
            title={strings.itemFound_sendMessage}
            iconProps={{ iconName: "Send" }}
            onClick={sendFeedback}
          />
        </Stack>
      </Stack.Item>
    </Stack>
  );
};
