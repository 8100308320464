import * as React from "react";
import { Stack } from "@fluentui/react";
import { Header } from "./Header";
import { Content } from "./Content";
import { StackVert, PageTitle, ParagraphTitle, ParagraphText } from "./Controls";
import { Language } from "./Strings";

const strings = Language.strings;

export class Privacy extends React.Component {
  render() {
    return (
      <StackVert>
        <Header />
        {/* <div
                    id="header"
                    style={{
                        backgroundColor: "rgb(30, 89, 92)",
                        color: "white",
                        position: "absolute",
                        top: "0px",
                        left: "0px",
                        height: "40px",
                        right: "0px",
                        overflow: "hidden"
                    }}
                > */}
        {/* <Stack horizontal style={{ backgroundColor: "rgb(30, 89, 92)" }}>
                        <Stack.Item align="center" grow disableShrink>
                            <p style={{ color: "#f5f5f5", fontSize: 20, margin: 8 }}>
                                qrit.app</ParagraphText>
                        </Stack.Item>
                    </Stack> */}
        {/* </Header> */}
        <Content>
          <Stack tokens={{ childrenGap: 15 }}>
            <StackVert>
              <Stack.Item>
                <PageTitle>{strings.privacy_deliveryTermsHeader}</PageTitle>
                <ParagraphText>{strings.privacy_deliveryTerms}</ParagraphText>
                <ParagraphText>{strings.privacy_deliveryTermsMissDelivery}</ParagraphText>
                <ParagraphTitle>{strings.privacy_personalInfoHeader}</ParagraphTitle>
                <ParagraphText>{strings.privacy_personalInfoUseInfo}</ParagraphText>
                <ParagraphTitle>{strings.privacy_cookies}</ParagraphTitle>
                <ParagraphText>{strings.privacy_cookiesInfo}</ParagraphText>
                <ParagraphTitle>{strings.privacy_infoStorageHeader}</ParagraphTitle>
                <ParagraphText>{strings.privacy_infoStorageInfoSSL}</ParagraphText>
                <ParagraphText>{strings.privacy_infoStorageInfoEncrypt}</ParagraphText>
                <ParagraphTitle>{strings.privacy_personalInfoPurchaseHeading}</ParagraphTitle>
                <ParagraphText>{strings.privacy_personalInfoPurchaseInfo}</ParagraphText>
                <ParagraphTitle>{strings.privacy_pricesHeader}</ParagraphTitle>
                <ParagraphText>{strings.privacy_pricesInfo}</ParagraphText>
                <ParagraphTitle>{strings.privacy_giveBackHeader}</ParagraphTitle>
                <ParagraphText>{strings.privacy_giveInfo}</ParagraphText>
                <ParagraphTitle>{strings.privacy_returningHeader}</ParagraphTitle>
                <ParagraphText>{strings.privacy_returningInfo}</ParagraphText>
                <ParagraphTitle>{strings.privacy_exchangeHeader}</ParagraphTitle>
                <ParagraphText>{strings.privacy_exchangeInfo}</ParagraphText>
                <ParagraphTitle>{strings.privacy_payBackHeader}</ParagraphTitle>
                <ParagraphText>{strings.privacy_payBackInfo}</ParagraphText>
                <ParagraphText>{strings.privacy_payBackInfoDelivery}</ParagraphText>
                <ParagraphTitle>{strings.privacy_goodToHaveLinksHeader}</ParagraphTitle>
                <ul>
                  <li>
                    {strings.privacy_goodToHaveLinksConsumerService}{" "}
                    <a
                      href={
                        "http://www.konsumentverket.se/contentassets/c78f43844da74c7786821d8d12a98b5a/angerblankett_konsumentverket_150817.pdf"
                      }
                    >
                      (Konsumentverket)
                    </a>
                  </li>
                  <li>
                    {strings.privacy_goodToHaveLinksConsumerARN} <a href={"http://www.arn.se/"}>(ARN)</a>
                  </li>
                  <li>
                    {strings.privacy_goodToHaveLinksArbitration}
                    <a href={"https://webgate.ec.europa.eu/odr/main/?event=main.home.show&lng=SV"}>(Konsumentverket)</a>
                  </li>
                </ul>
                <ParagraphTitle>{strings.privacy_complaintsHeader}</ParagraphTitle>
                <ParagraphText>{strings.privacy_complaintsInfo}</ParagraphText>
              </Stack.Item>
              <Stack.Item />
            </StackVert>
          </Stack>
        </Content>
        {}
      </StackVert>
    );
  }
}
