import React from "react";
import { Stack, TextField, Checkbox, PrimaryButton } from "@fluentui/react";
import { Header } from "./Header";
import { Content } from "./Content";
import { StackVert, PageTitle } from "./Controls";
import { Helpers } from "../helpers";
import Api from "../api";
import { Language } from "./Strings";

const strings = Language.strings;

interface ConfigRentProps {
  onChanged: () => void;
  qrCode: string;
}

interface ConfigRentState {
  busy: boolean;
  enableRenterChatRent: boolean;
  enableRenterExtensionRent: boolean;
  enableQueForItemRent: boolean;
  rentCostDescr: string;
}

export class ConfigRent extends React.Component<ConfigRentProps, ConfigRentState> {
  constructor(props: ConfigRentProps) {
    super(props);

    this.state = {
      busy: false,
      enableRenterChatRent: false,
      enableRenterExtensionRent: false,
      enableQueForItemRent: false,
      rentCostDescr: "",
    };
  }

  render() {
    const { enableRenterChatRent, enableRenterExtensionRent, enableQueForItemRent } = this.state;
    return (
      <StackVert>
        <Header />
        <Content>
          <Stack.Item>
            <PageTitle>{strings.configRent_choiceRentHeading}</PageTitle>
          </Stack.Item>
          <Stack.Item>
            <TextField
              multiline
              autoAdjustHeight
              label={strings.configRent_howMuchCostLbl}
              value={this.state.rentCostDescr}
              onChange={(_e, value) => this.setState({ rentCostDescr: value || "" })}
            />
          </Stack.Item>
          <Stack.Item>
            <Checkbox
              label={strings.configRent_enableChatLbl}
              checked={enableRenterChatRent}
              onChange={(_ev, checked) => this.setState({ enableRenterChatRent: !!checked })}
            />
          </Stack.Item>
          <Stack.Item>
            <Checkbox
              label={strings.configRent_allowTimeExtent}
              checked={enableRenterExtensionRent}
              onChange={(_ev, checked) => this.setState({ enableRenterExtensionRent: !!checked })}
            />
          </Stack.Item>
          <Stack.Item>
            <Checkbox
              label={strings.configRent_enableRentQueue}
              checked={enableQueForItemRent}
              onChange={(_ev, checked) => this.setState({ enableQueForItemRent: !!checked })}
            />
          </Stack.Item>
          <Stack.Item>
            <PrimaryButton onClick={this._registerRentCfg}>Spara</PrimaryButton>
          </Stack.Item>
        </Content>
      </StackVert>
    );
  }

  private _registerRentCfg = async () => {
    this.setState({ busy: true });
    const userId = Helpers.getCurrentUserId();

    await Api.registerRentCfg(
      this.props.qrCode!,
      userId,
      this.state.rentCostDescr.trim(),
      this.state.enableRenterChatRent,
      this.state.enableRenterExtensionRent,
      this.state.enableQueForItemRent
    );
    this.setState({ busy: false });
    this.props.onChanged();
  };
}
