import { useCallback, useEffect, useState } from "react";
import Api from "../api";
import { ChatMessage } from "../models";

const getMessages = (code: string) => Api.getMessages(undefined, code);

export const useObserveMessages = (code: string) => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);

  const readMessages = useCallback(() => {
    getMessages(code).then(setMessages);
  }, [code]);

  useEffect(() => {
    readMessages();
  }, [code, readMessages]);

  return { messages, getMessages: () => readMessages() };
};
