import React, { useState } from "react";
import { Stack, MessageBar, MessageBarType, Spinner, SpinnerSize } from "@fluentui/react";
import { Helpers } from "../helpers";
import {
  StackVert,
  ParagraphText,
  ParagraphLabel,
  ItemInfoStacked,
  ShowList,
  CodeShowAndLinkToQRImage,
} from "./Controls";
import { Language } from "./Strings";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useObserveCode } from "../hooks/useObserveCode";
import { ItemFooterEdit } from "./ItemFooterEdit";
import { TitleWithInfo } from "./TitleWithInfo";
import { ListMessages } from "./ListMessages";
import { ImagesPanel } from "./ImagesPanel";

const strings = Language.strings;

interface HandleOwnItemProps extends RouteComponentProps {
  code: string;
}

export const HandleOwnItem = withRouter(({ history, code }: HandleOwnItemProps) => {
  const {
    description,
    name,
    busy,
    itemType,
    codeStores,
    statusIconFilename,
    requireUserRemovalApproval,
    state,
    defaultImageId,
    setDefaultImageId,
    rentCostDescr,
    images,
    location,
    room,
    category,
  } = useObserveCode({ code, onSaved: () => history.goBack() });

  const [error, setError] = useState(undefined);

  return (
    <StackVert>
      {!busy && (
        <Stack.Item>
          {error && (
            <MessageBar messageBarType={MessageBarType.error} onDismiss={() => setError(undefined)}>
              {error}
            </MessageBar>
          )}
        </Stack.Item>
      )}
      <TitleWithInfo
        title={name ? name : ""}
        iconFileName={statusIconFilename}
        infoTitle={strings.handleOwnItem_itemHeader}
        code={code}
      >
        {/* iconFileName={Helpers.getStatusIconFilename(codeInfo)} */}
        <ul>
          {/* TODO: Add the information and add in strings - this is for Edit */}
          <li>{strings.editStatic_1}</li>
          <li>{strings.editStatic_2}</li>
          <li>{strings.editStatic_3}</li>
          <li>{strings.editStatic_4}</li>
          <li>{strings.editStatic_5}</li>
          {Helpers.hasFeature("rent") && <li>{strings.editStatic_6}</li>}
        </ul>
      </TitleWithInfo>
      <ItemInfoStacked
        header={strings.handleOwnItem_TypeOfItem}
        content={itemType ? itemType : strings.generic_noTypeSet}
      ></ItemInfoStacked>
      <ItemInfoStacked header={strings.item_status} content={Helpers.stateToString(state, true)}></ItemInfoStacked>
      <ItemInfoStacked header={strings.generic_name + ":"} content={name}></ItemInfoStacked>
      {description && description !== "-" && (
        <Stack.Item>
          <ParagraphLabel>{strings.generic_description}</ParagraphLabel>
          <ParagraphText>{description}</ParagraphText>
        </Stack.Item>
      )}
      {rentCostDescr && rentCostDescr !== "-" && (
        <Stack.Item>
          <ParagraphLabel>{strings.registerItem_rentInfo}</ParagraphLabel>
          <ParagraphText>{rentCostDescr}</ParagraphText>
        </Stack.Item>
      )}
      {images.length > 0 && (
        <Stack.Item>
          <ParagraphLabel>{strings.item_photo}</ParagraphLabel>
          <ImagesPanel
            viewMode
            defaultImageId={defaultImageId}
            setDefaultImageId={setDefaultImageId}
            code={code}
            images={images}
          />
        </Stack.Item>
      )}
      {/* TODO: Read from the codelog and add a list here of all transactions that you were involved
                        in. That is, for an owner it is everything. For a borrower (that borrowed it at any time)
                        it is the list of the times he/she borrowed it. */}
      <ItemInfoStacked
        header={strings.registerItem_alwaysConfirmReturn}
        content={requireUserRemovalApproval ? strings.generic_yes : strings.generic_no}
      ></ItemInfoStacked>
      <ItemInfoStacked header={strings.handleBoxes_Location} content={location}></ItemInfoStacked>
      <ItemInfoStacked header={strings.handleBoxes_Room} content={room}></ItemInfoStacked>
      <ItemInfoStacked header={strings.handleBoxes_Category} content={category}></ItemInfoStacked>
      <ShowList header={strings.generic_ListsShow} codesLists={codeStores}></ShowList>
      {/* <ItemInfoStacked header={strings.handleOwnItem_QRCode} content={code}></ItemInfoStacked> */}
      <CodeShowAndLinkToQRImage code={code}></CodeShowAndLinkToQRImage>
      <ListMessages code={code} showSendMessage={false} />
      <Stack.Item>{busy && <Spinner size={SpinnerSize.large} />}</Stack.Item>
      <ItemFooterEdit code={code} />
    </StackVert>
  );
});
