import * as React from "react";
import { Header } from "./Header";
import { Content } from "./Content";
import { StackVert } from "./Controls";
import { TagTypeOverview } from "./TagTypeOverview";

export const About = () => (
  <StackVert>
    <Header />
    <Content>
      <TagTypeOverview />
    </Content>
  </StackVert>
);
