import React, { useEffect, useState } from "react";
import { Stack, Image, PrimaryButton, Toggle } from "@fluentui/react";
import { PageTitle, ParagraphLabel, ParagraphText, ParagraphTitle, StackHorizontal, StackVert } from "./Controls";
import { Language } from "./Strings";
import { useTheme } from "../hooks/useTheme";
import { useHistory } from "react-router-dom";

const strings = Language.strings;

export const TagTypeOverview = () => {
  const {
    showStaticCodes,
    showStorageCodes,
    showLostAndFoundCodes,
    showFreezerCodes,
    showHtmlCodes,
    imageDir,
  } = useTheme();

  const history = useHistory();
  const [viewPrivateMode, setviewPrivateMode] = useState(localStorage.getItem("viewPrivateBusiness") === "private");

  useEffect(
    () => localStorage.setItem("viewPrivateBusiness", viewPrivateMode ? "private" : "business"),
    [viewPrivateMode]
  );
  // TODO: Store the viewPrivateBusiness in userObserveUsers instead/too

  return (
    <StackVert>
      {!viewPrivateMode && (
        <StackVert>
          <PageTitle>{strings.tagTypeOverview_pageHeader}</PageTitle>
          <Stack.Item>
            <Toggle
              checked={viewPrivateMode}
              onChange={(_event, checked?) => setviewPrivateMode(checked || false)}
              onText={strings.tagTypeOverview_business}
              offText={strings.tagTypeOverview_private}
            />
          </Stack.Item>
          <ParagraphText>{strings.tagTypeOverview_intro}</ParagraphText>
          <ul>
            <li>
              <ParagraphLabel>
                <a href="#keyCodes">{strings.tagTypeOverview_lblHeaderQRitStorage}</a>
              </ParagraphLabel>
            </li>
            {/* <li>
              <ParagraphLabel>
                <a href="#keys">{strings.tagTypeOverview_lblHeaderQRitKeys}</a>
              </ParagraphLabel>
            </li> */}
            <li>
              <ParagraphLabel>
                <a href="#statistic">{strings.tagTypeOverview_lblHeaderQRitStatic}</a>
              </ParagraphLabel>
            </li>
            <li>
              <ParagraphLabel>
                <a href="#lostFound">{strings.tagTypeOverview_lblHeaderQRitLostFound}</a>
              </ParagraphLabel>
            </li>
            <li>
              <ParagraphLabel>
                <a href="#freezer">{strings.tagTypeOverview_lblHeaderQRitFreezer}</a>
              </ParagraphLabel>
            </li>
            <li>
              <ParagraphLabel>
                <a href="#html">{strings.tagTypeOverview_lblHeaderQRitHtml}</a>
              </ParagraphLabel>
            </li>
          </ul>
          <Stack.Item>
            <ParagraphLabel>{strings.tagTypeOverview_RegisterLoginInfo}</ParagraphLabel>
            {/* TODO: How to push to register user */}
            {/* <PrimaryButton onClick={props.history.push("/register?s=" + qrCode)}>
                {strings.tagTypeOverview_RegisterLogin}
            </PrimaryButton> */}
          </Stack.Item>
          <Stack.Item shrink>
            <PrimaryButton onClick={() => history.push("/register")}>{strings.home_loginRegister}</PrimaryButton>
          </Stack.Item>
          {showStorageCodes && (
            <Stack.Item>
              <hr />
              <ParagraphTitle>{strings.tagTypeOverview_lblHeaderQRitStorage}</ParagraphTitle>
              <ParagraphText>{strings.tagTypeOverview_QRitStorage1}</ParagraphText>
              {/* <Image className="overview-image" width={'80vw'} src="/ExampleRyobiSetTagged_1200_v1.jpg" /> */}
              <Stack horizontal tokens={{ childrenGap: 16 }}>
                <Image className="overview-image-half" src={imageDir + "/boxes_Attic.jpg"} />
                <Image className="overview-image-half" src={imageDir + "/boxes_garage.jpg"} />
              </Stack>
              <ParagraphText>{strings.tagTypeOverview_QRitStorage2}</ParagraphText>
            </Stack.Item>
          )}
          {/* {showKeyCodes && (
            <Stack.Item>
              <hr id="keyCodes" />
              <ParagraphTitle>{strings.tagTypeOverview_lblHeaderQRitKeys}</ParagraphTitle>
              <ParagraphText>{strings.tagTypeOverview_QRitKeys1}</ParagraphText>
              <Image className="overview-image" src={imageDir + "/key_simple.jpg"} />
              <ParagraphText>{strings.tagTypeOverview_QRitKeys2}</ParagraphText>
            </Stack.Item>
          )} */}
          {showStaticCodes && (
            <Stack.Item>
              <hr id="statistic" />
              <ParagraphTitle>{strings.tagTypeOverview_lblHeaderQRitStatic}</ParagraphTitle>
              <ParagraphText>{strings.tagTypeOverview_QRitStatic1}</ParagraphText>
              <Image className="overview-image" src={imageDir + "/ExampleRyobiSetTagged_1200_v1.jpg"} />
              {/* <Stack horizontal tokens={{ childrenGap: 16 }}>
                <Image style={{ marginLeft: 0 }} width={'25vw'} src="/boxes_Attic.jpg" />
                <Image width={'25vw'} src="/boxes_garage.jpg" />
            </Stack> */}
              <ParagraphText>{strings.tagTypeOverview_QRitStatic2}</ParagraphText>
            </Stack.Item>
          )}
          {showLostAndFoundCodes && (
            <Stack.Item>
              <hr id="lostFound" />
              <ParagraphTitle>{strings.tagTypeOverview_lblHeaderQRitLostFound}</ParagraphTitle>
              <ParagraphText>{strings.tagTypeOverview_QRitLostFound1}</ParagraphText>
              {/* <Image className="overview-image" width={'80vw'} src="/ExampleRyobiSetTagged_1200_v1.jpg" /> */}
              <Stack horizontal tokens={{ childrenGap: 16 }}>
                <StackHorizontal>
                  <Image className="overview-image-half" src={imageDir + "/lostFoundGloves.jpg"} />
                  <Image className="overview-image-half" src="/lostFoundItem.jpg" />
                </StackHorizontal>
              </Stack>
              <ParagraphText>{strings.tagTypeOverview_QRitLostFound2}</ParagraphText>
            </Stack.Item>
          )}
          {showFreezerCodes && (
            <Stack.Item>
              <hr id="freezer" />
              <ParagraphTitle>{strings.tagTypeOverview_lblHeaderQRitFreezer}</ParagraphTitle>
              <ParagraphText>{strings.tagTypeOverview_QRitFreezer1}</ParagraphText>
              <Image className="overview-image" src={imageDir + "/freezer_top_multi.jpg"} />
              <ParagraphText>{strings.tagTypeOverview_QRitFreezer2}</ParagraphText>
            </Stack.Item>
          )}
          {showHtmlCodes && (
            <Stack.Item>
              <div id="html" />
              <hr />
              <ParagraphTitle>{strings.tagTypeOverview_lblHeaderQRitHtml}</ParagraphTitle>
              <ParagraphText>{strings.tagTypeOverview_QRitHtml1}</ParagraphText>
              {/* <Stack horizontal tokens={{ childrenGap: 16 }}>
                <Image style={{ marginLeft: 0 }} width={'40vw'} src={imageDir + "/htmlExample1.jpg"} />
                <Image width={'40vw'} src="/htmlExample2.jpg" />
            </Stack> */}
              <ParagraphText>{strings.tagTypeOverview_QRitHtml2}</ParagraphText>
            </Stack.Item>
          )}
        </StackVert>
      )}

      {viewPrivateMode && (
        <StackVert>
          <PageTitle>{strings.tagTypeOverview_pageHeader}</PageTitle>
          <Stack.Item>
            <Toggle
              checked={viewPrivateMode}
              onChange={(_event, checked?) => setviewPrivateMode(checked || false)}
              onText={strings.tagTypeOverview_business}
              offText={strings.tagTypeOverview_private}
            />
          </Stack.Item>
          <ParagraphText>{strings.tagTypeOverview_intro_BB}</ParagraphText>
          <ul>
            <li>
              <ParagraphLabel>
                <a href="#keyCodes">{strings.tagTypeOverview_lblHeaderQRitStorage_BB}</a>
              </ParagraphLabel>
            </li>
            {/* <li>
              <ParagraphLabel>
                <a href="#keys">{strings.tagTypeOverview_lblHeaderQRitKeys_BB}</a>
              </ParagraphLabel>
            </li> */}
            <li>
              <ParagraphLabel>
                <a href="#statistic">{strings.tagTypeOverview_lblHeaderQRitStatic_BB}</a>
              </ParagraphLabel>
            </li>
            <li>
              <ParagraphLabel>
                <a href="#lostFound">{strings.tagTypeOverview_lblHeaderQRitLostFound_BB}</a>
              </ParagraphLabel>
            </li>
            <li>
              <ParagraphLabel>
                <a href="#freezer">{strings.tagTypeOverview_lblHeaderQRitRestaurant_BB}</a>
              </ParagraphLabel>
            </li>
            <li>
              <ParagraphLabel>
                <a href="#html">{strings.tagTypeOverview_lblHeaderQRitHtml_BB}</a>
              </ParagraphLabel>
            </li>
          </ul>
          <Stack.Item>
            <ParagraphText>{strings.tagTypeOverview_RegisterLoginInfo_BB}</ParagraphText>
            {/* TODO: How to push to register user */}
            {/* <PrimaryButton onClick={props.history.push("/register?s=" + qrCode)}>
                {strings.tagTypeOverview_RegisterLogin}
            </PrimaryButton> */}
          </Stack.Item>
          <Stack.Item shrink>
            <PrimaryButton onClick={() => history.push("/register")}>{strings.home_loginRegister}</PrimaryButton>
          </Stack.Item>
          {showStorageCodes && (
            <Stack.Item>
              <hr />
              <ParagraphTitle>{strings.tagTypeOverview_lblHeaderQRitStorage_BB}</ParagraphTitle>
              <ParagraphText>{strings.tagTypeOverview_QRitStorage1_BB}</ParagraphText>
              {/* <Image className="overview-image" width={'80vw'} src="/ExampleRyobiSetTagged_1200_v1.jpg" /> */}
              <Stack horizontal tokens={{ childrenGap: 16 }}>
                <Image className="overview-image-half" src={imageDir + "/boxes_Attic.jpg"} />
                <Image className="overview-image-half" src={imageDir + "/boxes_garage.jpg"} />
              </Stack>
              <ParagraphText>{strings.tagTypeOverview_QRitStorage2_BB}</ParagraphText>
            </Stack.Item>
          )}
          {/* {showKeyCodes && (
            <Stack.Item>
              <hr id="keyCodes" />
              <ParagraphTitle>{strings.tagTypeOverview_lblHeaderQRitKeys_BB}</ParagraphTitle>
              <ParagraphText>{strings.tagTypeOverview_QRitKeys1_BB}</ParagraphText>
              <Image className="overview-image" src={imageDir + "/key_simple.jpg"} />
              <ParagraphText>{strings.tagTypeOverview_QRitKeys2_BB}</ParagraphText>
            </Stack.Item>
          )} */}
          {showStaticCodes && (
            <Stack.Item>
              <hr id="statistic" />
              <ParagraphTitle>{strings.tagTypeOverview_lblHeaderQRitStatic_BB}</ParagraphTitle>
              <ParagraphText>{strings.tagTypeOverview_QRitStatic1_BB}</ParagraphText>
              <Image className="overview-image" src={imageDir + "/ExampleRyobiSetTagged_1200_v1.jpg"} />
              {/* <Stack horizontal tokens={{ childrenGap: 16 }}>
                <Image style={{ marginLeft: 0 }} width={'25vw'} src="/boxes_Attic.jpg" />
                <Image width={'25vw'} src="/boxes_garage.jpg" />
            </Stack> */}
              <ParagraphText>{strings.tagTypeOverview_QRitStatic2_BB}</ParagraphText>
            </Stack.Item>
          )}
          {showLostAndFoundCodes && (
            <Stack.Item>
              <hr id="lostFound" />
              <ParagraphTitle>{strings.tagTypeOverview_lblHeaderQRitLostFound_BB}</ParagraphTitle>
              <ParagraphText>{strings.tagTypeOverview_QRitLostFound1_BB}</ParagraphText>
              <Image className="overview-image" width={'80vw'} src={imageDir + "/key_simple.jpg"} />
              {/* <Image className="overview-image" width={'80vw'} src="/ExampleRyobiSetTagged_1200_v1.jpg" /> */}
              <Stack horizontal tokens={{ childrenGap: 16 }}>
                <StackHorizontal>
                  <Image className="overview-image-half" src={imageDir + "/lostFoundGloves.jpg"} />
                  <Image className="overview-image-half" src="/lostFoundItem.jpg" />
                </StackHorizontal>
              </Stack>
              <ParagraphText>{strings.tagTypeOverview_QRitLostFound2_BB}</ParagraphText>
            </Stack.Item>
          )}
          {showFreezerCodes && (
            // TODO: This is not a freezer
            <Stack.Item>
              <hr id="freezer" />
              <ParagraphTitle>{strings.tagTypeOverview_lblHeaderQRitRestaurant_BB}</ParagraphTitle>
              <ParagraphText>{strings.tagTypeOverview_QRitRestaurant1_BB}</ParagraphText>
              {/* <Image className="overview-image" src={imageDir + "/freezer_top_multi.jpg"} /> */}
              <ParagraphText>{strings.tagTypeOverview_QRitRestaurant2_BB}</ParagraphText>
            </Stack.Item>
          )}
          {showHtmlCodes && (
            <Stack.Item>
              <div id="html" />
              <hr />
              <ParagraphTitle>{strings.tagTypeOverview_lblHeaderQRitHtml_BB}</ParagraphTitle>
              <ParagraphText>{strings.tagTypeOverview_QRitHtml1_BB}</ParagraphText>
              {/* <Stack horizontal tokens={{ childrenGap: 16 }}>
                <Image style={{ marginLeft: 0 }} width={'40vw'} src={imageDir + "/htmlExample1.jpg"} />
                <Image width={'40vw'} src="/htmlExample2.jpg" />
            </Stack> */}
            </Stack.Item>
          )}
        </StackVert>
      )}
    </StackVert>
  );
};
