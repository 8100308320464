import React from "react";
import { IColumn, Icon } from "@fluentui/react";
import { CodeInfo } from "../models";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { Language } from "./Strings";
import { ItemImage } from "./ItemImage";
import { ItemsView } from "./ItemsView";
import { ParagraphText } from "./Controls";

const strings = Language.strings;

const classNames = mergeStyleSets({
  fileIconHeaderIcon: {
    padding: 0,
    fontSize: "16px",
  },
  fileIconCell: {
    textAlign: "center",
    selectors: {
      "&:before": {
        content: ".",
        display: "inline-block",
        verticalAlign: "middle",
        height: "100%",
        width: "0px",
        visibility: "hidden",
      },
    },
  },
  fileIconImg: {
    verticalAlign: "middle",
    maxHeight: "16px",
    maxWidth: "16px",
  },
  controlWrapper: {
    display: "flex",
    flexWrap: "wrap",
  },
  exampleToggle: {
    display: "inline-block",
    marginBottom: "10px",
    marginRight: "30px",
  },
  selectionDetails: {
    marginBottom: "20px",
  },
});

interface ListLostFoundItemsProps {
  items: CodeInfo[];
  onSelected: (code: string) => void;
  busy: boolean;
  viewDetailedList: boolean;
}

interface ListLostFoundItemsState {
  columns: IColumn[];
}

export class ListLostFoundItems extends React.Component<ListLostFoundItemsProps, ListLostFoundItemsState> {
  constructor(props: ListLostFoundItemsProps) {
    super(props);

    const columns: IColumn[] = [
      {
        key: "column1",
        name: "File Type",
        className: classNames.fileIconCell,
        iconClassName: classNames.fileIconHeaderIcon,
        ariaLabel: "",
        isIconOnly: true,
        fieldName: "file",
        minWidth: 20,
        maxWidth: 20,
        onRender: (item: CodeInfo) => {
          return <Icon iconName="SearchIssue" /> 
        },
      },
      {
        key: "column2",
        name: strings.generic_name,
        fieldName: "name",
        minWidth: 80,
        maxWidth: 110,
        isRowHeader: true,
        isResizable: true,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        data: "string",
        isPadded: true,
      },
      // {
      //   key: "column3",
      //   name: strings.listLostFoundItems_created,
      //   fieldName: "createdAt",
      //   minWidth: 70,
      //   maxWidth: 90,
      //   isResizable: true,
      //   data: "number",
      //   isPadded: true,
      //   onRender: (item: CodeInfo) => <span>{new Date(Date.parse(item.createdAt)).toLocaleDateString()}</span>,
      // },
      {
        key: "column4",
        name: strings.generic_description,
        fieldName: "description",
        minWidth: 120,
        maxWidth: 300,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column5",
        name: strings.listLostFoundItems_photoDescription,
        fieldName: "photo",
        minWidth: 120,
        maxWidth: 200,
        onRender: (item: CodeInfo) => {
          if (item.properties["defaultImageId"]) {
            return <ItemImage id={item.properties["defaultImageId"]} isSmall />;
          } else {
            return <ParagraphText>{item.description ? item.description : strings.itemImage_NoImage}</ParagraphText>;
          }
        },
      },
    ];

    this.state = {
      columns,
    };
  }

  render() {
    const { items, busy, viewDetailedList } = this.props;
    const { columns } = this.state;

    return (
      <ItemsView
        items={items}
        busy={busy}
        columns={columns}
        viewList={viewDetailedList}
        onItemInvoked={this._onItemInvoked}
      />
    );
  }

  private _onItemInvoked = (item?: CodeInfo) => {
    if (item) {
      this.props.onSelected(item.id);
    }
  };
}
