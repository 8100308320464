import React from "react";
import { FocusZone, PrimaryButton, Stack } from "@fluentui/react";
import { useHistory, useParams } from "react-router-dom";
import { useObserveStore } from "../hooks/useObserveStore";
import { ListStoreItems } from "./ListStoreItems";
import { Content, Header, ParagraphText, StackHorizontal, StackVert } from ".";
import { TitleWithInfo } from "./TitleWithInfo";
import { Helpers } from "../helpers";
import { strings } from "./Strings";

export const ViewStore = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { busy, name, description, hasListAccess, join, canEditCodes } = useObserveStore({
    storeId: id,
    onSaved: () => {},
    onRemoved: () => {},
  });

  const onSelected = (code: string) => {
    history.push(`/${code}`);
  };

  // TODO: move to helpers? Or to new observable (that uses useHistory)
  const close = () => {
    console.log("close", history.length);
    if (history.length > 1) {
      history.goBack();
    } else {
      history.replace("/");
    }
  };

  const joinList = async () => {
    const ok = await join();
    if (!ok) {
      // TODO: set error? Or, display dialog alert?
      alert(strings.viewStore_CannotReqestJoinList);
    }
    // TODO: display info bar that request has been sent (copy Error control to InfoBar control)?
  };

  return (
    <>
      <Header />
      <Content>
        <StackVert>
          <Stack.Item>
            <TitleWithInfo title={name} infoTitle={name} onClose={close}>
              <ParagraphText>{description}</ParagraphText>
            </TitleWithInfo>
          </Stack.Item>
          {hasListAccess && (
            <StackVert>
              <Stack.Item grow>
                <FocusZone>
                  <ListStoreItems storeId={id} busy={busy} onSelected={onSelected} viewDetailedList={false} />
                </FocusZone>
                {/* TODO: move the below to a footer component */}
              </Stack.Item>
              <Stack.Item>
                <StackHorizontal>
                  <Stack.Item grow>
                    {canEditCodes && (
                      <PrimaryButton onClick={() => history.push("/editstore/" + id)}>
                        {strings.generic_edit}
                      </PrimaryButton>
                    )}
                  </Stack.Item>
                  <Stack.Item>
                    <PrimaryButton
                      onClick={async () => {
                        try {
                          await Helpers.shareStore(id);
                        } catch (error) {
                          // this.setState({ error: error.message }); // TODO: show error message
                        }
                      }}
                    >
                      {strings.generic_share}
                    </PrimaryButton>
                  </Stack.Item>

                  <Stack.Item align="end">
                    <PrimaryButton onClick={close}>{strings.generic_close}</PrimaryButton>
                  </Stack.Item>
                </StackHorizontal>
              </Stack.Item>
            </StackVert>
          )}
          {!hasListAccess && (
            <StackVert>
              <Stack.Item>
                <StackHorizontal>
                  <Stack.Item grow>
                    <PrimaryButton onClick={joinList}>{strings.viewStore_Join}</PrimaryButton>
                  </Stack.Item>
                  <Stack.Item align="end">
                    <PrimaryButton onClick={close}>{strings.viewStore_Cancel}</PrimaryButton>
                  </Stack.Item>
                </StackHorizontal>
              </Stack.Item>
            </StackVert>
          )}
        </StackVert>
      </Content>
    </>
  );
};
