import React from "react";
import { mergeStyleSets, Stack } from "@fluentui/react";
import { IconTableRow, ParagraphLabel } from "./Controls";
import { Language } from "./Strings";

const strings = Language.strings;

export const ShowWhatBoxIconsMean = () => {
  const classNamesIconTableRow = mergeStyleSets({
    table: {
      fontFamily: "arial, sans-serif",
      borderCollapse: "collapse",
      width: "100%",
    },
    td_th: {
      border: "1px solid #dddddd",
      textAlign: "left",
      padding: "8px",
    },
  });

  return (
    <Stack>
      <Stack.Item>
        <ParagraphLabel>{strings.home_iconTableWhatDoTheIconsMean}</ParagraphLabel>
      </Stack.Item>
      <Stack.Item>
        <li>
          <table className={classNamesIconTableRow.table}>
            <thead>
              <tr>
                <th className={classNamesIconTableRow.td_th}>{strings.home_iconTableHeaderIcon}</th>
                <th className={classNamesIconTableRow.td_th}>{strings.home_iconTableHeaderDescription}</th>
              </tr>
            </thead>
            <tbody>
              <IconTableRow iconName="ICON_Home_32.png" description={strings.home_iconTableStaticHome} />
              <IconTableRow iconName="ICON_HomeAtOther_64.png" description={strings.home_iconTableStaticHomeAtOther} />
              <IconTableRow
                iconName="ICON_MineBorrowedByOther_32.png"
                description={strings.home_iconTableStaticMyBorrowedByOther}
              />
              <IconTableRow
                iconName="ICON_OthersBorrowedByMe_64.png"
                description={strings.home_iconTableStaticOtherBorrowedByMe}
              />
              <IconTableRow
                iconName="ICON_OtherBorrowedByOther_64.png"
                description={strings.home_iconTableStaticOtherBorrowedByOther}
              />
            </tbody>
            <tbody>
              <IconTableRow iconName="BoxOnly_White_32x32.png" description={strings.home_iconTableBoxOpen} />
              <IconTableRow
                iconName="BoxArrowIn_WhiteGreenArrow_32x32.png"
                description={strings.home_iconTableBoxPacking}
              />
              <IconTableRow iconName="BoxOnlyClosed_White_32x32.png" description={strings.home_iconTableBoxClosed} />
              <IconTableRow
                iconName="BoxOnlyClosedStored_Green_32x32.png"
                description={strings.home_iconTableBoxStorageHome}
              />
              <IconTableRow
                iconName="BoxOnlyClosedStored_Red_32x32.png"
                description={strings.home_iconTableBoxStorageAway}
              />
              <IconTableRow
                iconName="BoxArrowOut_WhiteBlueArrow_32x32.png"
                description={strings.home_iconTableBoxUnpacking}
              />
            </tbody>
          </table>
        </li>
      </Stack.Item>
    </Stack>
  );
};
