import React, { useEffect, useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import {
  Dialog,
  DialogType,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  MessageBar,
  MessageBarType,
  Spinner,
  SpinnerSize,
  Stack,
  mergeStyleSets,
} from "@fluentui/react";
import Api from "../api";
import { Language } from "./Strings";
import { StackVert } from "./Controls";

const strings = Language.strings;

const styles = mergeStyleSets({
  container: {
    maxHeight: "40vh",
    height: "40vh",
  },
});

interface UploadDialogProps {
  hidden: boolean;
  onDismiss: () => void;
  filename: string;
  code: string;
  quickAdd?: boolean;
}

export const UploadDialog = (props: UploadDialogProps) => {
  const [error, setError] = useState("");
  const [uploading, setUploading] = useState(false);
  const { hidden, onDismiss, filename, code } = props;
  const [hasImage, setHasImage] = useState(false);
  const [image, setImage] = useState("");
  const [cropper, setCropper] = useState<any>();
  const [type, setType] = useState("");
  const [ext, setExt] = useState("");
  const fileInput = React.createRef<HTMLInputElement>();
  const [quickAdd, setQuickAdd] = useState(props.quickAdd === true);

  useEffect(() => {
    if (fileInput.current && !hidden && quickAdd) {
      setQuickAdd(false);
      fileInput.current.click();
    }
  }, [hidden, quickAdd, fileInput]);

  const onChange = (e: any) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (files.length > 0) {
      setType(files[0].type);
      setExt(files[0].name.split(".")[1]);
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result as any);
        setHasImage(true);
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const rotate = () => {
    cropper.rotate(90);
  };
  const dataURItoBlob = (dataURI: string) => {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  };

  const save = () => {
    if (typeof cropper !== "undefined") {
      setUploading(true);
      const url = cropper.getCroppedCanvas().toDataURL();
      const blob = dataURItoBlob(url);

      const formData = new FormData();
      formData.append("file", blob);
      Api.uploadFile2(filename + "." + ext, code, type, formData, "{}")
        .then(() => {
          setUploading(false);
          dismiss();
        })
        .catch((error) => {
          setUploading(false);
          setError(error.message);
        });
      // setCropData(url);
    }
  };

  const dismiss = () => {
    setImage("");
    setType("");
    setCropper(undefined);
    setHasImage(false);
    setError("");
    onDismiss();
  };

  return (
    <Dialog
      hidden={hidden}
      onDismiss={dismiss}
      dialogContentProps={{
        type: DialogType.normal,
        title: strings.uploadDialogChooseImage,
        // subText: strings.uploadDialogChooseImage,
      }}
      modalProps={{
        isBlocking: true,
        styles: { main: { minWidth: "90%" } },
      }}
    >
      <div className={styles.container}>
        <StackVert>
          {/* <Stack.Item>
            <ParagraphTitle>STR: Image</ParagraphTitle>
          </Stack.Item> */}
          <Stack.Item>
            <Cropper
              style={{ maxHeight: "40vh", minHeight: "40vh", width: "100%" }}
              src={image}
              viewMode={0}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              rotatable={true}
              autoCropArea={1}
              checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
              onInitialized={(instance) => {
                setCropper(instance);
              }}
              guides={true}
            />
          </Stack.Item>
        </StackVert>
      </div>
      {uploading && <Spinner title="Uploading..." size={SpinnerSize.large} />}
      {error !== "" && (
        <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
          {error}
        </MessageBar>
      )}
      <Stack tokens={{ childrenGap: 10 }}>
        <input
          aria-label="File upload"
          required={false}
          ref={fileInput}
          type="file"
          name="files"
          multiple={false}
          accept="image/*"
          // @ts-ignore
          onChange={onChange}
          capture="environment"
        />
        <Stack.Item>
          <DefaultButton onClick={rotate} disabled={!hasImage || uploading} text={strings.uploadDialogRotate} />
        </Stack.Item>
        <DialogFooter>
          <PrimaryButton onClick={save} disabled={!hasImage || uploading} text={strings.uploadDialogUpload} />
          <DefaultButton onClick={dismiss} disabled={uploading} text={strings.generic_cancel} />
        </DialogFooter>
      </Stack>
    </Dialog>
  );
};
