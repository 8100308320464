import React from "react";
import { mergeStyleSets, Stack, TextField, PrimaryButton, FontIcon, Callout } from "@fluentui/react";
import { Header } from "./Header";
import { Content } from "./Content";
import { StackVert, PageTitle, ParagraphTitle, ParagraphText } from "./Controls";
import { CodeInfo } from "../models";
import { Language } from "./Strings";

const strings = Language.strings;

interface ItemFoundProps {
  value: CodeInfo;
  onChanged: (value: CodeInfo, userId: string) => void;
}

interface ItemFoundState {
  showInfoTargetMess: boolean;
  showInfoSendMess: boolean;
}

const styles = mergeStyleSets({
  callout: {
    maxWidth: 300,
  },
});

export class ItemFound extends React.Component<ItemFoundProps, ItemFoundState> {
  constructor(props: ItemFoundProps) {
    super(props);

    this.state = {
      showInfoTargetMess: false,
      showInfoSendMess: false,
    };
  }

  private _infoTargetElement = React.createRef<HTMLDivElement>();
  private _infoSendMessageElement = React.createRef<HTMLDivElement>();

  render() {
    const { value } = this.props;
    return (
      <StackVert>
        <Header />
        <Content>
          <StackVert>
            <Stack.Item>
              <PageTitle>{strings.itemFound_youFoundQR}</PageTitle>
              <ParagraphText>{strings.itemFound_someoneMarkedItem}</ParagraphText>
            </Stack.Item>
            {value.showPhoneNumberLost && (
              <Stack.Item>
                <ParagraphTitle>{strings.itemFound_ownersPhoneNumber}</ParagraphTitle>
                {/* TODO: Read phone number from database */}
                <ParagraphText>031-1234567</ParagraphText>
              </Stack.Item>
            )}
            {value.showHowMuchLostPay && (
              <Stack.Item>
                <ParagraphTitle>{strings.itemFound_ownersOffersThisAmount} </ParagraphTitle>
                <ParagraphText>{value.lostPayValue}</ParagraphText>
              </Stack.Item>
            )}
            <Stack.Item>
              <Stack horizontal>
                <TextField id="ownerMessage" label={strings.itemFound_whatMessageDoYouWantToSend} />
                <div ref={this._infoTargetElement}>
                  <FontIcon iconName="Info" onClick={() => this.setState({ showInfoTargetMess: true })} />
                  {this.state.showInfoTargetMess && (
                    <Callout
                      className={styles.callout}
                      target={this._infoTargetElement.current}
                      preventDismissOnLostFocus
                    >
                      <Stack style={{ margin: 10 }}>{strings.itemFound_insteadSendMessageToOwner}</Stack>
                    </Callout>
                  )}
                </div>
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <PrimaryButton>{strings.itemFound_sendMessage}</PrimaryButton>
            </Stack.Item>
            {/* <Stack.Item>
                                <Stack horizontal>
                                    <PrimaryButton>{strings.itemFound_sendGPS}</PrimaryButton>
                                    <div ref={this._infoSendMessageElement}>
                                        <FontIcon
                                            iconName="Info"
                                            onClick={() => this.setState({ showInfoSendMess: true })}
                                        />
                                        {this.state.showInfoSendMess && <Callout className={styles.callout} target={this._infoSendMessageElement.current}>
                                            <Stack style={{ margin: 10 }}>{strings.itemFound_sendGPSInfo}</Stack>
                                        </Callout>}
                                    </div>
                                </Stack>
                            </Stack.Item> */}
          </StackVert>
        </Content>
      </StackVert>
    );
  }
}
