import React, { useState } from "react";
import { Stack, MessageBarType, MessageBar, Label } from "@fluentui/react";
// import { MailIcon, SettingsIcon } from '@fluentui/react-icons'
import {
  StackVert,
  ItemInfoStacked,
  ParagraphLabel,
  ShowList,
  CodeShowAndLinkToQRImage,
  ParagraphText,
} from "./Controls";
import { strings } from "./Strings";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useObserveCode } from "../hooks/useObserveCode";
import { ItemFooterEdit } from "./ItemFooterEdit";
import { TitleWithInfo } from "./TitleWithInfo";
import { ListMessages } from "./ListMessages";
import { ImagesPanel } from "./ImagesPanel";

interface HandleOwnLostFoundProps extends RouteComponentProps {
  code: string;
}
export const HandleOwnLostFound = withRouter(({ history, code }: HandleOwnLostFoundProps) => {
  const {
    showHowMuchPay,
    lostPayValue,
    showPhoneNumber,
    codeStores,
    possibleSendMess,
    enablePosition,
    description,
    name,
    defaultImageId,
    setDefaultImageId,
    images,
  } = useObserveCode({ code, onSaved: () => history.goBack() });

  const [error, setError] = useState(undefined);

  return (
    <>
      <StackVert>
        <Stack.Item>
          {error && (
            <MessageBar messageBarType={MessageBarType.error} onDismiss={() => setError(error)}>
              {error}
            </MessageBar>
          )}
        </Stack.Item>
        <Stack.Item>
          <Stack.Item>
            <TitleWithInfo
              title={name ? name : ""}
              iconFileName="SearchIssue"
              infoTitle={strings.handleOwnLostFound_infoOnLostFound}
              code={code}
            >
              <ParagraphLabel>{strings.generic_PageInfo}</ParagraphLabel>
              <ul>
                <li>{strings.handleOwnLostFound_thisIsLostFoundCode}</li>
                <li>
                  {
                    strings.handleOwnLostFound_whenYouMarkSometingWithTag
                  }
                </li>
                <li>
                  {
                    strings.handleOwnLostFound_whenYouEditThisTag
                  }
                </li>
              </ul>
            </TitleWithInfo>
            {/* <ItemInfoStacked header={strings.handleOwnLostFound_ShowFindersFee} content={(Boolean(showHowMuchPay) ? strings.generic_yes : strings.generic_no)}></ItemInfoStacked> */}
            <ItemInfoStacked header={strings.generic_name + ":"} content={name}></ItemInfoStacked>
            <Stack.Item>
              <ParagraphLabel>{strings.generic_description}</ParagraphLabel>
              <ParagraphText>{description}</ParagraphText>
            </Stack.Item>
            {images.length > 0 && (
              <Stack.Item>
                <Label>{strings.item_photo}</Label>
                <ImagesPanel
                  viewMode
                  defaultImageId={defaultImageId}
                  setDefaultImageId={setDefaultImageId}
                  code={code}
                  images={images}
                />
              </Stack.Item>
            )}
            {/* {Boolean(showHowMuchPay) && <ItemInfoStacked header={strings.handleOwnLostFound_FindersFee} content={strings.currency_prefix + lostPayValue + strings.currency_suffix}></ItemInfoStacked>} */}
            {Boolean(showHowMuchPay) && (
              <ItemInfoStacked header={strings.handleOwnLostFound_FindersFee} content={lostPayValue}></ItemInfoStacked>
            )}
            <ItemInfoStacked
              header={strings.handleOwnLostFound_ShowYourPhoneNum}
              content={Boolean(showPhoneNumber) ? strings.generic_yes : strings.generic_no}
            ></ItemInfoStacked>
            <ItemInfoStacked
              header={strings.handleOwnLostFound_FinderCanSendGPS + ":"}
              content={Boolean(enablePosition) ? strings.generic_yes : strings.generic_no}
            ></ItemInfoStacked>
            <ItemInfoStacked
              header={strings.handleOwnLostFound_MessageSendingEnabled}
              content={Boolean(possibleSendMess) ? strings.generic_yes : strings.generic_no}
            ></ItemInfoStacked>
            <CodeShowAndLinkToQRImage code={code}></CodeShowAndLinkToQRImage>
            <ShowList header={strings.generic_ListsShow} codesLists={codeStores}></ShowList>
          </Stack.Item>
        </Stack.Item>
        <ListMessages code={code} showSendMessage={false} />
        <Stack.Item>
          <ItemFooterEdit code={code} />
        </Stack.Item>
      </StackVert>
    </>
  );
});
