import { Stack, PrimaryButton, DialogFooter, DefaultButton, Dialog, DialogType } from "@fluentui/react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Helpers } from "../helpers";
import { useObserveCode } from "../hooks";
import { StackHorizontal } from "./Controls";
import { Language } from "./Strings";

const strings = Language.strings;

interface ItemFooterProps {
  code: string;
}

// const changeCodeOwner = async (code: string, newUserID: string) => {
//   Api.changeOwner(code, newUserID)
// };

// const canChangeCodeOwner = async (code: string) => {
//   return await Api.canChangeOwner(code)
// };

export const ItemFooterEdit = ({ code }: ItemFooterProps) => {
  const history = useHistory();
  const { isMine, type, unregister, copyCodeToLocalSettings } = useObserveCode({ code });
  const [showUnregister, setShowUnregister] = useState(false);
  const closeUnregister = () => setShowUnregister(false);

  // TODO: move to helpers? Or to new observable (that uses useHistory)
  const close = () => {
    console.log("close", history.length);
    if (history.length > 1) {
      history.goBack();
    } else {
      history.replace("/");
    }
  };

  return (
    <StackHorizontal>
      <Stack.Item grow>
        <PrimaryButton onClick={() => history.push("/edit/" + code)}>{strings.generic_edit}</PrimaryButton>
      </Stack.Item>
      <Stack.Item>
        <PrimaryButton onClick={copyCodeToLocalSettings}>{strings.generic_copy}</PrimaryButton>
      </Stack.Item>
      {/* {{ canChangeCodeOwner(code) } && <Stack.Item>
        <PrimaryButton onClick={() => { changeCodeOwner(code,) }}>
          {'STR: Change code owner'}
        </PrimaryButton>
      </Stack.Item>} */}
      <Stack.Item grow>
        {type === "freezer" && (
          <PrimaryButton onClick={() => setShowUnregister(true)}>{strings.itemFooter_unregisterItem}</PrimaryButton>
        )}
      </Stack.Item>
      <Dialog
        hidden={!showUnregister}
        onDismiss={closeUnregister}
        dialogContentProps={{
          type: DialogType.normal,
          title: strings.itemFooter_unregister,
          closeButtonAriaLabel: strings.itemFooter_close,
          subText: strings.itemFooter_realyUnregister,
        }}
      >
        <DialogFooter>
          <PrimaryButton onClick={unregister} text={strings.generic_yes} />
          <DefaultButton onClick={closeUnregister} text={strings.generic_no} />
        </DialogFooter>
      </Dialog>
      <Stack.Item>
        <PrimaryButton
          disabled={!isMine}
          onClick={async () => {
            try {
              await Helpers.share(code);
            } catch (error) {
              // this.setState({ error: error.message }); // TODO: show error message
            }
          }}
        >
          {strings.generic_share}
        </PrimaryButton>
      </Stack.Item>

      <Stack.Item align="end">
        <PrimaryButton onClick={close}>{strings.generic_close}</PrimaryButton>
      </Stack.Item>
    </StackHorizontal>
  );
};
