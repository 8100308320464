import React from "react";
import { Stack, Label } from "@fluentui/react";
import { StackVert, ParagraphTextPre, ItemInfoStacked, CodeShowAndLinkToQRImage } from "./Controls";
import { Language } from "./Strings";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { TitleWithInfo } from "./TitleWithInfo";
import { useObserveCode } from "../hooks/useObserveCode";
import { ItemFooterEdit } from "./ItemFooterEdit";
import { ListMessages } from "./ListMessages";
import { ImagesPanel } from "./ImagesPanel";
import { ShowWhatBoxIconsMean } from "./ShowWhatBoxIconsMean";

const strings = Language.strings;

interface HandleOwnBoxesProps extends RouteComponentProps {
  code: string;
}

export const HandleOwnBoxes = withRouter(({ history, code }: HandleOwnBoxesProps) => {
  const {
    contentType,
    location,
    content,
    description,
    room,
    fragility,
    priority,
    boxColor,
    boxState,
    storageDate,
    shortId,
    boxStateIconFilename,
    defaultImageId,
    setDefaultImageId,
    images,
  } = useObserveCode({ code, onSaved: () => history.goBack() });

  return (
    <StackVert>
      <TitleWithInfo
        title={shortId ? shortId : "NO SHORT-ID"}
        iconFileName={boxStateIconFilename}
        infoTitle={strings.handleOwnBoxes_infoHeader}
        code={code}
      >
        <ul>
          <li>{strings.handleBoxes_0}</li>
          <li>{strings.handleBoxes_1}</li>
          <li>{strings.handleBoxes_2}</li>
          <li>{strings.handleBoxes_3}</li>
          <li>{strings.handleBoxes_4}</li>
          <li>{strings.handleBoxes_5}</li>
          <li>{strings.handleBoxes_6}</li>
          <li>{strings.handleBoxes_7}</li>
          <li>{strings.handleBoxes_8}</li>
          <li>{strings.handleBoxes_9}</li>
          <li>{strings.handleBoxes_10}</li>
        </ul>
        <Stack.Item>
          <ShowWhatBoxIconsMean />
        </Stack.Item>
      </TitleWithInfo>
      <StackVert tokens={{ childrenGap: 10 }}>
        {/* <ItemInfoStacked header={strings.EditBoxes_BoxName + ":"} content={name ? name : ""}></ItemInfoStacked> */}
        {/* <ItemInfoStacked header={strings.handleBoxes_Owner} content={ownerUserName}></ItemInfoStacked> */}
        {/* <ItemInfoStacked header={strings.handleBoxes_ShortId} content={shortId}></ItemInfoStacked> */}
        <ItemInfoStacked
          header={strings.handleBoxes_BoxState}
          content={strings.EditBoxes_BoxStateList2[boxState]}
        ></ItemInfoStacked>
        <ItemInfoStacked header={strings.handleBoxes_ContentType} content={contentType}></ItemInfoStacked>
        <ItemInfoStacked header={strings.handleBoxes_Content} content={content}></ItemInfoStacked>
        {description && (
          <Stack.Item>
            <Label>{strings.handleBoxes_Description}</Label>
            <ParagraphTextPre>{description}</ParagraphTextPre>
          </Stack.Item>
        )}
        {images.length > 0 && (
          <Stack.Item>
            <Label>{strings.item_photo}</Label>
            <ImagesPanel
              viewMode
              defaultImageId={defaultImageId}
              setDefaultImageId={setDefaultImageId}
              code={code}
              images={images}
            />
          </Stack.Item>
        )}
        <ItemInfoStacked header={strings.handleBoxes_Location} content={location}></ItemInfoStacked>
        <ItemInfoStacked header={strings.handleBoxes_Room} content={room}></ItemInfoStacked>
        <ItemInfoStacked
          header={strings.handleBoxes_Fragility + ": "}
          content={fragility ? strings.generic_yes : strings.generic_no}
        ></ItemInfoStacked>
        <ItemInfoStacked header={strings.handleBoxes_Priority} content={priority}></ItemInfoStacked>
        <ItemInfoStacked header={strings.handleBoxes_BoxColor} content={boxColor}></ItemInfoStacked>
        <ItemInfoStacked
          header={strings.handleBoxes_StorageDate}
          content={storageDate && storageDate instanceof Date ? storageDate.toLocaleDateString() : ""}
        ></ItemInfoStacked>
        <CodeShowAndLinkToQRImage code={code}></CodeShowAndLinkToQRImage>
        {/* <ShowList header={strings.generic_ListsShow} codesLists={codeStores}></ShowList> */}
        <ListMessages code={code} showSendMessage={false} />
        <Stack.Item>
          <ItemFooterEdit code={code} />
        </Stack.Item>
      </StackVert>
    </StackVert>
  );
});
