import { useCallback, useEffect, useState } from "react";
import Api from "../api";
import { Helpers } from "../helpers";
import { AccessInfo, CodeInfo, Roles, Store } from "../models";
import { useObserveUser } from "./useObserveUser";

interface ObserveStoreProps {
  storeId: string;
  onSaved: () => void;
  onRemoved: () => void;
}

export const useObserveStore = ({ storeId, onSaved, onRemoved }: ObserveStoreProps) => {
  const [name, setName] = useState("");
  const [busy, setBusy] = useState(false);
  const [description, setDescription] = useState("");
  const [codes, setCodes] = useState<CodeInfo[]>([]);
  const [store, setStore] = useState<Store>();
  const [requireCodesOwnerChange, setRequireCodesOwnerChange] = useState(false);
  const [isPrivateList, setIsPrivateList] = useState(true);
  const [allowResharing, setAllowResharing] = useState(false);
  const [canDeleteStore, setCanDeleteStore] = useState(false);
  const [canAskToRequireCodeOwnerChange, setCanAskToRequireCodeOwnerChange] = useState(false);
  const [hasListAccess, setHasListAccess] = useState(false);
  const [canEditCodes, setCanEditCodes] = useState(false);
  const [canListUsers, setCanListUsers] = useState(false);
  const [isPendingUser, setIsPendingUser] = useState(false);
  const [access, setAccess] = useState<AccessInfo[]>([]);
  const [storeCodes, setStoreCodes] = useState<CodeInfo[]>([]);
  const [userCodes, setUserCodes] = useState<CodeInfo[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [ownerRole, setOwnerRole] = useState<Roles>("pending");

  const { userId } = useObserveUser();

  const canDoStoreOperation = useCallback(
    async (
      operation:
        | "deleteStore"
        | "changeStoreOwner"
        | "inviteStoreUser"
        | "requireCodeOwnerChange"
        | "hasListAccess"
        | "canEditCodes"
        | "pendingUser"
        | "listUser"
    ) => {
      return await Api.canDoStoreOperation(storeId, operation);
    },
    [storeId]
  );

  const deleteStore = () => {
    Api.removeStore(storeId)
      .then(onRemoved)
      .catch((error) => console.warn(error));
  };

  useEffect(() => {
    Api.getCodesForUser()
      .then(setUserCodes)
      .catch((error) => console.warn("Failed to get codes", error));
  }, []);

  const getStore = useCallback(async () => {
    setBusy(true);
    Api.getStore(storeId)
      .then((store: Store) => {
        setStore({ ...store });
        const { name, description, codes } = store;
        setName(name);
        setDescription(description);
        setStoreCodes(store.codes);
        //TODO: How to handle selected keys...
        setSelectedKeys(codes.map((ci) => ci.id));
        setRequireCodesOwnerChange(store.requireCodesOwnerChange);
        setIsPrivateList(store.isPrivateList);
        setAllowResharing(store.allowResharing === true);

        setAccess(store.access ? [...store.access] : []);
        const storeOwnerAccess = store.access?.find((a) => a.userId === store.userId)?.accessType!;
        setOwnerRole(Helpers.getRole(storeOwnerAccess));
        setBusy(false);
      })
      .catch(() => {
        console.warn("Failed to get store info.");
        setBusy(false);
      });

    canDoStoreOperation("deleteStore").then((can) => setCanDeleteStore(can));
    canDoStoreOperation("requireCodeOwnerChange").then((can) => setCanAskToRequireCodeOwnerChange(can));
    canDoStoreOperation("hasListAccess").then((can) => setHasListAccess(can));
    canDoStoreOperation("canEditCodes").then((can) => setCanEditCodes(can));
    canDoStoreOperation("pendingUser").then((can) => setIsPendingUser(can));
    canDoStoreOperation("listUser").then((can) => setCanListUsers(can));
  }, [storeId, canDoStoreOperation]);

  useEffect(() => {
    if (storeId) {
      getStore();
    }
  }, [storeId, canDoStoreOperation, getStore]);

  const updateCodes = useCallback(
    (updatedCodes: CodeInfo[]) => {
      const updatedStore: Store = {
        id: storeId,
        userId: Helpers.getCurrentUserId(),
        name,
        description,
        requireCodesOwnerChange,
        isPrivateList,
        allowResharing,
        codes: updatedCodes,
        // TODO: needed here? access,
      };
      Api.updateStore(updatedStore);
    },
    [storeId, name, description, requireCodesOwnerChange, isPrivateList, allowResharing]
  );

  //TODO: How to handle this... is this correct?
  // useEffect(() => {
  //   updateCodes(codes);
  // }, [codes, updateCodes]);

  useEffect(() => {
    const otherStoreCodes = storeCodes.filter((c) => c.userId !== userId);
    setCodes(userCodes.concat(otherStoreCodes));
  }, [userCodes, storeCodes, userId]);

  useEffect(() => {
    const options = codes
      .filter((ci) => ci.name && ci.name.trim().length > 0)
      .map((ci) => ({ key: ci.id, text: formatCodeInfo(ci) }));
    setOptions(options);
  }, [codes]);

  const [options, setOptions] = useState<
    {
      key: string;
      text: string;
    }[]
  >([]);

  const formatCodeInfo = (codeInfo: CodeInfo): string => {
    const { id, name, shortID: shortId } = codeInfo;
    const type = Helpers.getCodeTypeString(codeInfo);
    return `${name} (${id}${shortId ? " " + shortId : ""}) - ${type}`;
  };

  const join = async () => {
    const ok = await Api.requestJoinList(storeId);
    return ok;
  };

  const addUser = async (phone: string) => {
    const ok = await Api.addUserToStore(phone.trim(), storeId);
    if (ok) {
      getStore();
    }

    return ok;
  };

  const removeUser = async (accessId: string) => {
    await Api.deleteStoreAccess(accessId);
    getStore();
  };

  const changeOwner = async (ownerUserId: string) => {
    await Api.changeStoreOwner(storeId, ownerUserId);
    getStore();
  };

  return {
    busy,
    name,
    setName,
    description,
    setDescription,
    codes,
    setCodes,
    store,
    setStore,
    requireCodesOwnerChange,
    setRequireCodesOwnerChange,
    isPrivateList,
    setIsPrivateList,
    allowResharing,
    setAllowResharing,
    canDeleteStore,
    setCanDeleteStore,
    canAskToRequireCodeOwnerChange,
    setCanAskToRequireCodeOwnerChange,
    hasListAccess,
    setHasListAccess,
    canEditCodes,
    isPendingUser,
    canListUsers,
    access,
    setAccess,
    storeCodes,
    setStoreCodes,
    userCodes,
    setUserCodes,
    options,
    setOptions,

    canDoStoreOperation,
    deleteStore,
    updateCodes,
    join,

    addUser,
    removeUser,
    changeOwner,

    selectedKeys, // TODO: needed, rename?

    ownerRole,
  };
};
