import React from "react";

import { ParagraphLabel } from ".";
import { Store, StoreInfo } from "../models";
import { MultiItemSelect } from "./MultiItemSelect";
import { Language } from "./Strings";

const strings = Language.strings;

interface ListsDropdownProps {
  label: string;
  lists: Store[];
  selectedLists: string[];
  setCodeStores: (stores: StoreInfo[]) => void;
  setError: (error: string) => void;
  setInfo: (info: string) => void;
  ownerUserId: string;
}

export const ListsDropdown = ({
  label,
  lists: stores,
  selectedLists,
  setCodeStores,
  setInfo,
  setError,
  ownerUserId,
}: ListsDropdownProps) => {
  const onStoresSelected = (storeIds: string[]) => {
    const requireCodesOwnerChangeCountTotal = storeIds.filter((storeId) => {
      const store = stores.find((s) => s.id === storeId);
      return store && store.requireCodesOwnerChange;
    }).length;

    const requireCodesOwnerChangeCount = storeIds.filter((storeId) => {
      const store = stores.find((s) => s.id === storeId);
      return store && store.requireCodesOwnerChange && store.userId !== ownerUserId;
    }).length;

    setInfo("");
    setError("");
    if (requireCodesOwnerChangeCountTotal > 1) {
      setError(strings.editStatic_codeCannotBelongToMoreThanOne);
    } else if (requireCodesOwnerChangeCount === 1) {
      setInfo(strings.editStatic_codeWillChangeOwner);
    }

    setCodeStores(
      storeIds.map((k) => {
        const store = stores.find((s) => s.id === k)!;
        return {
          id: store.id,
          name: store.name,
          description: store.description,
        } as StoreInfo;
      })
    );
  };

  return (
    <>
      <ParagraphLabel>{label}</ParagraphLabel>
      <MultiItemSelect
        selectedItems={selectedLists}
        items={stores.map((s) => ({
          key: s.id,
          text: s.name + (s.requireCodesOwnerChange ? " ⚠" : ""),
          item: s,
        }))}
        onSelected={onStoresSelected}
      />
    </>
  );
};
