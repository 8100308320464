import React from "react";
import { IColumn } from "@fluentui/react";
import { CodeInfo } from "../models";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { Helpers } from "../helpers";
import { ItemImage } from "./ItemImage";
import { Language } from "./Strings";
import { ItemsView } from "./ItemsView";

const strings = Language.strings;

const classNames = mergeStyleSets({
  fileIconHeaderIcon: {
    padding: 0,
    fontSize: "16px",
  },
  fileIconCell: {
    textAlign: "center",
    selectors: {
      "&:before": {
        content: ".",
        display: "inline-block",
        verticalAlign: "middle",
        height: "100%",
        width: "0px",
        visibility: "hidden",
      },
    },
  },
  fileIconImg: {
    verticalAlign: "middle",
    maxHeight: "16px",
    maxWidth: "16px",
  },
  controlWrapper: {
    display: "flex",
    flexWrap: "wrap",
  },
  exampleToggle: {
    display: "inline-block",
    marginBottom: "10px",
    marginRight: "30px",
  },
  selectionDetails: {
    marginBottom: "20px",
  },
});

interface ListItemsProps {
  items: CodeInfo[];
  onSelected: (code: string) => void;
  busy: boolean;
  viewDetailedList: boolean;
}

const columns: IColumn[] = [
  {
    key: "column1",
    name: "File Type",
    className: classNames.fileIconCell,
    iconClassName: classNames.fileIconHeaderIcon,
    ariaLabel: "Column operations for File type, Press to sort on File type",
    // iconName: 'Page',
    isIconOnly: true,
    fieldName: "name",
    minWidth: 24,
    maxWidth: 24,
    onRender: (item: CodeInfo) => {
      const statusIconFilename = Helpers.getStatusIconFilename(item);
      return <img src={statusIconFilename} className={classNames.fileIconImg} alt="State" />;
    },
  },
  {
    key: "column2",
    name: strings.generic_name,
    fieldName: "name",
    minWidth: 80,
    maxWidth: 110,
    isRowHeader: true,
    isResizable: true,
    // isSorted: true,
    // isSortedDescending: false,
    sortAscendingAriaLabel: "Sorted A to Z",
    sortDescendingAriaLabel: "Sorted Z to A",
    // onColumnClick: this._onColumnClick,
    data: "string",
    isPadded: true,
  },
  // {
  //     key: 'column3',
  //     name: 'Återlämnas om',
  //     fieldName: 'returnDate',
  //     minWidth: 40,
  //     maxWidth: 90,
  //     isResizable: true,
  //     isCollapsible: false,
  //     data: 'string',
  //     onRender: (item: CodeInfo) => {
  //         return <span>{this._renderReturnDate(item)}</span>;
  //     }
  // },
  // {
  //     key: 'column3',
  //     name: 'Skapad',
  //     fieldName: 'createdAt',
  //     minWidth: 70,
  //     maxWidth: 90,
  //     isResizable: true,
  //     // onColumnClick: this._onColumnClick,
  //     data: 'number',
  //     // onRender: (item: CodeInfo) => {
  //     //     return <span>{item.dateModified}</span>;
  //     // },
  //     isPadded: true
  // },
  // {
  //     key: 'column3',
  //     name: strings.listItems_state,
  //     fieldName: 'state',
  //     minWidth: 120,
  //     maxWidth: 180,
  //     isResizable: true,
  //     isCollapsible: false,
  //     data: 'string',
  //     // onColumnClick: this._onColumnClick,
  //     onRender: (item: CodeInfo) => {
  //         return <span>{this._renderState(item)}</span>;
  //     }
  // },
  {
    key: "column4",
    name: strings.generic_description,
    fieldName: "description",
    minWidth: 120,
    maxWidth: 200,
    isResizable: true,
    isCollapsible: true,
    data: "string",
    // onColumnClick: this._onColumnClick,
    // onRender: (item: CodeInfo) => {
    //     return <span>{item.modifiedBy}</span>;
    // },
    isPadded: true,
  },
  {
    key: "column5",
    name: strings.listItems_photo,
    fieldName: "description",
    minWidth: 120,
    maxWidth: 200,
    // isResizable: true,
    // isCollapsible: true,
    // data: 'string',
    // onColumnClick: this._onColumnClick,
    onRender: (item: CodeInfo) => {
      if (item.type !== "freezer") {
        return <ItemImage id={item.properties["defaultImageId"]} isSmall />;
      } else {
        return <></>;
      }
    },
    isPadded: true,
  },
];

export const ListItems = (props: ListItemsProps) => {
  const { items, busy, viewDetailedList } = props;
  const onItemInvoked = (item?: CodeInfo) => {
    if (item) {
      props.onSelected(item.id);
    }
  };

  return (
    <ItemsView items={items} busy={busy} columns={columns} viewList={viewDetailedList} onItemInvoked={onItemInvoked} />
  );
};
