import React, { useEffect, useState } from "react";
import { Checkbox, Panel, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from "@fluentui/react";
import { ParagraphLabel, ParagraphText, StackVert } from "./Controls";
import { Language } from "./Strings";
import { useObserveCode } from "../hooks/useObserveCode";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ItemFooterSave } from ".";
import { TitleWithInfo } from "./TitleWithInfo";
import { ImagesPanel } from "./ImagesPanel";
import { useObserveUser } from "../hooks";
import { ListsDropdown } from "./ListsDropdown";
import { CodePropsType } from "../models";

const strings = Language.strings;

interface LFsProps extends RouteComponentProps {
  //   onChange: (props: Partial<CodeProps>) => void;
  qrCode: string;
  header: string;
  onSaved: () => void;
  onChangeType: (type: CodePropsType) => void;
}

export const EditLostFound = withRouter(({ qrCode, header, history, onSaved, onChangeType }: LFsProps) => {
  const [isValid, setIsValid] = useState(false);
  const [, setInfo] = useState(""); // TODO: display info and error messages!
  const [, setError] = useState("");

  const {
    busy,
    save,
    name,
    setName,
    description,
    setDescription,
    isRegistered,
    lostPayValue,
    setLostPayValue,
    showHowMuchPay,
    setShowHowMuchPay,
    enablePosition,
    setEnablePosition,
    offerPay,
    setOfferPay,
    possibleSendMess,
    setPossibleSendMess,
    showPhoneNumber,
    setShowPhoneNumber,
    images,
    defaultImageId,
    setDefaultImageId,
    addImage,
    refreshImages,
    unregister,
    codeStores,
    setCodeStores,
    ownerUserId,
    changed,
    copyCodeFromLocalSettings,
  } = useObserveCode({ code: qrCode, onSaved, onUnregistered: () => history.replace("/") });

  const { stores } = useObserveUser();

  const [showEditImagesPanel, setShowEditImagesPanel] = useState(false);

  useEffect(() => {
    setIsValid(name !== "" || description !== "");
  }, [name, description]);

  // useEffect(() => {
  //   Api.getProperties("user").then((properties) => {
  //     console.warn(properties);
  //     // setLostPayValue(properties['lostPayValueJson']);
  //   });
  // }, []);

  // let _infoSendMess = React.createRef<HTMLDivElement>();
  // let _infoOfferPay = React.createRef<HTMLDivElement>();
  // let _infoShowHowMuchPay = React.createRef<HTMLDivElement>();

  return (
    <StackVert>
      <TitleWithInfo
        title={header + (name ? " - " + name : "")}
        iconFileName="SearchIssue"
        infoTitle={strings.editLostFound_infoHeader}
        code={qrCode}
      >
        <ul>
          <li>{strings.editLostFound_settingsLostText}</li>
          <li>{strings.editLostFound_info0}</li>
          <li>{strings.editLostFound_info1}</li>
          <li>{strings.editLostFound_info2}</li>
          <li>{strings.editLostFound_info3}</li>
          <li>{strings.editLostFound_info4}</li>
          <li>{strings.editLostFound_info5}</li>
          <li>{strings.editLostFound_info6}</li>
          <li>{strings.editLostFound_info7}</li>
          <li>{strings.editLostFound_info8}</li>
          <li>{strings.editLostFound_info10}</li>
          <li>{strings.editLostFound_info11}</li>
        </ul>
      </TitleWithInfo>
      <Stack.Item>
        <TextField
          id="prylnamn"
          label={strings.generic_name}
          value={name}
          onChange={(_e, value) => setName(value || "")}
        />
      </Stack.Item>
      <Stack.Item>
        <TextField
          multiline
          autoAdjustHeight
          label={strings.generic_description}
          value={description}
          onChange={(_e, value) => setDescription(value || "")}
        />
      </Stack.Item>
      <Stack.Item>
        <ParagraphLabel>{strings.editStatic_photo}</ParagraphLabel>
        {images.length > 0 && (
          <div onClick={() => setShowEditImagesPanel(true)}>
            {/* TODO: move defaultImageId to useObserveCode */}
            {images.length > 0 && (
              <ImagesPanel
                viewMode
                defaultImageId={defaultImageId}
                setDefaultImageId={setDefaultImageId}
                code={qrCode}
                images={images}
              />
            )}
          </div>
        )}
        <PrimaryButton disabled={!qrCode} onClick={() => setShowEditImagesPanel(true)}>
          {strings.generic_manageImages}
        </PrimaryButton>
        {qrCode && (
          <Panel
            headerText={strings.generic_manageImages}
            isOpen={showEditImagesPanel}
            isLightDismiss
            onDismiss={() => setShowEditImagesPanel(false)}
            closeButtonAriaLabel={strings.generic_close}
          >
            <ImagesPanel
              code={qrCode}
              images={images}
              defaultImageId={defaultImageId}
              setDefaultImageId={setDefaultImageId}
              onImageAdded={() => addImage()}
              onImageDeleted={refreshImages}
            />
            <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign="end">
              <PrimaryButton onClick={() => setShowEditImagesPanel(false)}>{strings.generic_close}</PrimaryButton>
            </Stack>
          </Panel>
        )}
      </Stack.Item>
      <Stack.Item>
        <Checkbox
          label={strings.editLostFound_IwantFinderToSeePhoneNumber}
          checked={showPhoneNumber}
          onChange={(_ev, checked) => setShowPhoneNumber(!!checked)}
        />
      </Stack.Item>
      <Stack.Item>
        <Checkbox
          label={strings.handleOwnLostFound_FinderCanSendGPS}
          checked={enablePosition}
          onChange={(_ev, checked) => setEnablePosition(!!checked)}
        />
      </Stack.Item>
      <Stack.Item>
        <Stack horizontal>
          <Checkbox
            label={strings.editLostFound_IwantFinderAbleSendMess}
            checked={possibleSendMess}
            onChange={(_ev, checked) => setPossibleSendMess(!!checked)}
          />
          {/* <div ref={_infoSendMess}>
                <FontIcon
                  iconName="Info"
                  onClick={() => setShowInfoSendMess(true)}
                />
                {showInfoSendMess && <Callout target={_infoSendMess.current}>
                  <Stack>{strings.configLost_withThisSettingFinderShowPos}</Stack>
               </Callout>}
              </div> */}
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <Stack horizontal>
          <Checkbox
            label={strings.editLostFound_IwantOfferLostPay}
            checked={offerPay}
            onChange={(_ev, checked) => setOfferPay(!!checked)}
          />
          {/* <div ref={_infoOfferPay}>
                <FontIcon
                  iconName="Info"
                  onClick={() => setShowInfoOfferPay(true)}
                />
                {showInfoOfferPay && <Callout target={_infoOfferPay.current}>
                  <Stack>{strings.configLost_IwantOfferLostPayInfo}</Stack>
               </Callout>}
              </div> */}
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <Stack horizontal>
          <Checkbox
            label={strings.editLostFound_IwantFinderSeeHowMuchPay}
            checked={showHowMuchPay}
            disabled={!offerPay}
            onChange={(_ev, checked) => setShowHowMuchPay(!!checked)}
          />
          {/* <div ref={_infoShowHowMuchPay}>
                <FontIcon
                  style={{ marginLeft: 10 }}
                  iconName="Info"
                  onClick={() => setShowInfoShowHowMuchPay(true)}
                />
                {showInfoShowHowMuchPay && <Callout target={_infoShowHowMuchPay.current}>
                  <Stack>{strings.configLost_IwantFinderSeeHowMuchPayInfo}</Stack>
               </Callout>}
              </div> */}
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <TextField
          id="lostPayValue"
          type="text"
          required={offerPay && (!lostPayValue || lostPayValue.trim().length === 0)}
          disabled={!offerPay}
          label={strings.editLostFound_HowMuchLostPay}
          value={lostPayValue}
          onChange={(_e, value) => setLostPayValue(value || "")}
        />
        {offerPay && <ParagraphText>{strings.editLostFound_HowMuchLostPayInfo}</ParagraphText>}
      </Stack.Item>
      <Stack.Item>
        <ListsDropdown
          setCodeStores={setCodeStores}
          label={strings.editStatic_ListsHeader}
          selectedLists={codeStores.map((s) => s.id)}
          lists={stores}
          ownerUserId={ownerUserId}
          setError={setError}
          setInfo={setInfo}
        />
      </Stack.Item>
      <Stack.Item>{busy && <Spinner size={SpinnerSize.large} />}</Stack.Item>

      {/* TODO: How to make the editor know that at least one option for communication needs to be set 
        for it to make sense? I can set isValid to false when "{(!showHowMuchPay && !showPhoneNumber && possibleSendMess)"
        but I would want to send back the text that should be shown (the reason why save is not OK)  */}
      <ItemFooterSave
        code={qrCode}
        onSave={save}
        isValid={isValid}
        onUnregister={isRegistered ? unregister : undefined}
        onChangeType={onChangeType}
        canSave={changed && !busy}
        onPasteCode={copyCodeFromLocalSettings}
      />
    </StackVert>
  );
});
