import { IDropdownStyles, IComboBoxOption, IDropdownOption, Dropdown } from "@fluentui/react";
import React, { useEffect, useState } from "react";

const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 300 } };

export const MultiItemSelect = ({
  placeholder,
  label,
  items,
  // onRenderItem,
  onSelected,
  selectedItems,
}: {
  //   options: [{ key: string; text: string }];
  placeholder?: string;
  label?: string;
  items: IComboBoxOption[];
  // onRenderItem?: (item: any) => JSX.Element;
  onSelected: (selectedKeys: string[]) => void;
  selectedItems: string[];
}) => {
  const [selectedKeys, setSelectedKeys] = useState<string[]>(selectedItems);

  useEffect(() => {
    setSelectedKeys(selectedItems);
  }, [selectedItems]);

  const onChange = (_event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
    if (item) {
      setSelectedKeys(
        item.selected ? [...selectedKeys, item.key as string] : selectedKeys.filter((key) => key !== item.key)
      );
    }
  };

  const onRenderTitle = (options: any) => {
    return (
      <span>
        {(options || []).map(({ key }: { key: any }) => items.find((c) => c.key === key)!.text).join(", ")}
        {/* .map((o: { key: any }) => items.find((c) => c.id === o.key)!.name)
          .join(", ")} */}
      </span>
    );
  };

  return (
    <Dropdown
      placeholder={placeholder}
      label={label}
      selectedKeys={selectedKeys}
      // eslint-disable-next-line react/jsx-no-bind
      onChange={onChange}
      multiSelect
      options={items}
      styles={dropdownStyles}
      onDismiss={() => onSelected(selectedKeys)}
      onRenderTitle={onRenderTitle}
      // onRenderItem={onRenderItem}
    />
  );
};
