import {
  ChoiceGroup,
  DefaultButton,
  Dialog,
  DialogContent,
  DialogFooter,
  IconButton,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
} from "@fluentui/react";
import React, { useEffect, useState } from "react";
import Api from "../api";
import { Helpers } from "../helpers";
import { useObserveStrings } from "../hooks/useObserveStrings";
import { AccessInfo, Roles, Store } from "../models";
import { AccessAddUserDialog } from "./AccessAddUserDialog";
import { AccessChangeOwnerDialog } from "./AccessChangeOwnerDialog";
import { StackHorizontal, StackVert } from "./Controls";

// TODO: record changes and save/update access
// TODO: use FluentUI controls instead of table and input
// TODO: prevent current user from removing itself as admin?
const AccessRow = ({
  access,
  //   userAccessLevel,
  ownerRole,
  onChange,
  owner,
  onRemoveUser,
}: {
  access: AccessInfo;
  //   userAccessLevel: number;
  ownerRole: Roles;
  onChange: (accessType: string) => void;
  owner: string;
  onRemoveUser(accessId: string): void;
}) => {
  const strings = useObserveStrings();
  const [claims, setClaims] = useState<string[]>(access.accessType.split(","));
  const [showChangeRoleDialog, setShowChangeRoleDialog] = useState(false);
  const [showRemoveRoleDialog, setShowRemoveRoleDialog] = useState(false);
  const [role, setRole] = useState<Roles>("pending");

  useEffect(() => setRole(Helpers.getRole(access.accessType)), [access]);

  const onChangeHandler = (claim: string, checked: boolean) => {
    let newClaims = claims.filter((c) => c !== claim);
    if (checked) {
      newClaims.push(claim);
    }

    setClaims(newClaims);

    const newAccessType = newClaims.join(",");
    // console.log("newAccessType", newAccessType);
    onChange(newAccessType);
  };

  const updateAccess = (newRole: Roles) => {
    switch (newRole) {
      case "administrator":
        onChange("listAdmin");
        break;
      case "maintainer":
        onChange("addUser");
        break;
      case "editor":
        onChange("editCodes");
        break;
      case "pending":
        onChange("pending");
        break;
      case "member":
        onChange("");
        break;
    }

    setShowChangeRoleDialog(false);
  };

  const removeUser = () => {
    onRemoveUser(access.accessId);
    setShowRemoveRoleDialog(false);
  };

  return (
    <tr>
      <td>{access.username}</td>
      <td>{role}</td>
      <td>
        {(ownerRole === "administrator" || ownerRole === "maintainer") && owner !== access.userId && (
          <>
            <IconButton iconProps={{ iconName: "AccountActivity" }} onClick={() => setShowChangeRoleDialog(true)} />
            {/* <Link text={"STR: change"} onClick={() => setShowChangeRoleDialog(true)} /> */}
            <Dialog
              hidden={!showChangeRoleDialog}
              onDismiss={() => setShowChangeRoleDialog(false)}
              title={strings.accessTable_changeRole}
              dialogContentProps={{ subText: strings.accessTable_changeRoleInfo }}
              modalProps={{ isBlocking: true }}
            >
              <DialogContent>
                <ChoiceGroup
                  selectedKey={role}
                  options={[
                    {
                      key: "administrator",
                      text: strings.accessTable_roleAdmin,
                      disabled: ownerRole === "maintainer",
                    },
                    {
                      key: "maintainer",
                      text: strings.accessTable_roleMaintain,
                      disabled: ownerRole === "maintainer",
                    },
                    {
                      key: "editor",
                      text: strings.accessTable_roleEditor,
                    },
                    {
                      key: "pending",
                      text: strings.accessTable_rolePending,
                      disabled: true,
                    },
                    {
                      key: "member",
                      text: strings.accessTable_roleMember,
                    },
                  ]}
                  onChange={(ev_, option) => {
                    if (option) {
                      setRole(option.key as Roles);
                    }
                  }}
                  label={strings.accessTable_setRole}
                />
              </DialogContent>
              <DialogFooter>
                <PrimaryButton onClick={() => updateAccess(role)} text={strings.generic_save} />
                <DefaultButton onClick={() => setShowChangeRoleDialog(false)} text={strings.generic_cancel} />
              </DialogFooter>
            </Dialog>
          </>
        )}
      </td>
      <td>
        {ownerRole === "administrator" && owner !== access.userId && (
          <>
            <IconButton iconProps={{ iconName: "Delete" }} onClick={() => setShowRemoveRoleDialog(true)} />
            <Dialog
              title={strings.accessTable_removeUser}
              dialogContentProps={{ subText: strings.accessTable_removeUserFromList }}
              hidden={!showRemoveRoleDialog}
              onDismiss={() => setShowRemoveRoleDialog(false)}
              modalProps={{ isBlocking: true }}
            >
              <DialogFooter>
                <PrimaryButton onClick={removeUser} text={strings.viewStore_Delete} />
                <DefaultButton onClick={() => setShowRemoveRoleDialog(false)} text={strings.generic_cancel} />
              </DialogFooter>
            </Dialog>
          </>
        )}
      </td>
    </tr>
  );
};

export const AccessTableNew = ({
  store,
  onChange,
  addUser,
  ownerRole,
  removeUser,
  changeOwner,
}: {
  store: Store;
  ownerRole: Roles;
  onChange: (store: Store) => void;
  addUser: (phone: string) => Promise<boolean>;
  removeUser: (accessId: string) => Promise<void>;
  changeOwner: (ownerUserId: string) => Promise<void>;
}) => {
  const userId = Helpers.getCurrentUserId();
  const [showDialog, setShowDialog] = useState(false);
  const [hideOwnerDialog, setHideOwnerDialog] = useState(true);
  const [error, setError] = useState<string | undefined>(undefined);
  const [canChangeStoreOwner, setCanChangeStoreOwner] = useState(false);
  const [canInviteStoreUser, setCanInviteStoreUser] = useState(false);
  const [access, setAccess] = useState<AccessInfo[]>([]);
  const [highestAccessLevel, setHighestAccessLevel] = useState(0);
  const strings = useObserveStrings();

  useEffect(() => {
    setAccess(store.access || []);
  }, [store]);

  useEffect(() => {
    // TODO: move access claims rights calculation below to useObserveStore?
    const userAccess = access.filter((a) => a.userId === userId);
    const claims = userAccess.length > 0 ? userAccess[0].accessType.split(",") : ["listAdmin"];
    setHighestAccessLevel(
      store.userId === userId
        ? 0
        : Object.keys(strings.AccessTypes).reduce((prev, curr, index) => {
            if (prev === 100 && claims.includes(curr)) {
              return index;
            }
            return prev;
          }, 100)
    );
  }, [userId, access, store.userId, strings.AccessTypes]);

  const onAddUser = async (phone: string) => {
    const ok = await addUser(phone);
    if (!ok) {
      setError(strings.accessTable_FaildAddUser);
    }
  };

  useEffect(() => {
    // TODO: move to observer
    Api.canDoStoreOperation(store.id, "changeStoreOwner").then(setCanChangeStoreOwner);
    Api.canDoStoreOperation(store.id, "inviteStoreUser").then(setCanInviteStoreUser);
  }, [store]);

  const onChangeHandler = () => {
    store.access = access;
    onChange(store);
  };

  return (
    <StackVert>
      <Stack.Item>
        {/* TODO: make a component of this stack item (<ErrorMessage/>) */}
        {error && (
          <MessageBar messageBarType={MessageBarType.error} onDismiss={() => setError(undefined)}>
            {error}
          </MessageBar>
        )}
      </Stack.Item>
      {/* <ParagraphTitle>STR: Access</ParagraphTitle> */}
      <table className="access-table">
        <thead>
          <tr>
            <th align="left">{strings.generic_user}</th>
            <th align="left">{strings.accessTable_role}</th>
            <th align="left">{strings.accessTable_command}</th>
            <th align="left">{strings.accessTable_delete}</th>
          </tr>
        </thead>
        <tbody>
          {access.map((a, i) => (
            <AccessRow
              key={i}
              access={a}
              ownerRole={ownerRole}
              owner={store.userId}
              onChange={(accessType) => {
                //  console.log("accessId", a.accessId, "accessType", accessType);
                const item = access.find((i) => i.accessId === a.accessId);
                if (item) {
                  item.accessType = accessType;
                  setAccess([...access]);
                }

                onChangeHandler();
              }}
              onRemoveUser={removeUser}
            />
          ))}
        </tbody>
      </table>
      <StackHorizontal>
        {canInviteStoreUser && (
          <PrimaryButton onClick={() => setShowDialog(true)}>{strings.accessTable_InviteUser}</PrimaryButton>
        )}
        {canChangeStoreOwner && (
          <PrimaryButton onClick={() => setHideOwnerDialog(false)}>{strings.accessTable_ChangeOwner}</PrimaryButton>
        )}
      </StackHorizontal>
      <AccessAddUserDialog onAddUser={onAddUser} onDismiss={() => setShowDialog(false)} hidden={!showDialog} />
      <AccessChangeOwnerDialog
        hidden={hideOwnerDialog}
        onDismiss={() => setHideOwnerDialog(true)}
        onChangeOwner={changeOwner}
        users={access.map((a) => ({ userId: a.userId, name: a.username }))}
        ownerUserId={userId}
      />
    </StackVert>
  );
};
