import React, { useEffect, useState } from "react";
import { DatePicker, Dropdown, IComboBoxOption, Rating, RatingSize, Stack, TextField } from "@fluentui/react";
import { ItemInfoStacked, OnRenderOptionDropdown, ParagraphLabel, StackHorizontal, StackVert } from "./Controls";
import { Language } from "./Strings";
import { EditTypeModal } from "./EditTypeModal";
import Api from "../api";
import { TitleWithInfo } from "./TitleWithInfo";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useObserveCode } from "../hooks/useObserveCode";
import { ItemFooterSave } from ".";
import { ListsDropdown } from "./ListsDropdown";
import { useObserveUser } from "../hooks";
import { CodePropsType } from "../models";
import { Helpers } from "../helpers";

const strings = Language.strings;

// TODO: see if we can use these icons with the Rating control...
// registerIcons({
//     icons: {
//         'body-solid': (
//             <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><title>Body</title><circle cx='256' cy='56' r='56' /><path d='M437 128H75a27 27 0 000 54h101.88c6.91 0 15 3.09 19.58 15 5.35 13.83 2.73 40.54-.57 61.23l-4.32 24.45a.42.42 0 01-.12.35l-34.6 196.81A27.43 27.43 0 00179 511.58a27.06 27.06 0 0031.42-22.29l23.91-136.8S242 320 256 320c14.23 0 21.74 32.49 21.74 32.49l23.91 136.92a27.24 27.24 0 1053.62-9.6L320.66 283a.45.45 0 00-.11-.35l-4.33-24.45c-3.3-20.69-5.92-47.4-.57-61.23 4.56-11.88 12.91-15 19.28-15H437a27 27 0 000-54z' /></svg>
//         ),
//         'body-outline': (
//             <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><title>Body</title><circle fill='none' stroke='currentColor' stroke-miterlimit='10' stroke-width='32' cx='256' cy='56' r='40' /><path fill='none' stroke='currentColor' stroke-miterlimit='10' stroke-width='32' d='M199.3 295.62h0l-30.4 172.2a24 24 0 0019.5 27.8 23.76 23.76 0 0027.6-19.5l21-119.9v.2s5.2-32.5 17.5-32.5h3.1c12.5 0 17.5 32.5 17.5 32.5v-.1l21 119.9a23.92 23.92 0 1047.1-8.4l-30.4-172.2-4.9-29.7c-2.9-18.1-4.2-47.6.5-59.7 4-10.4 14.13-14.2 23.2-14.2H424a24 24 0 000-48H88a24 24 0 000 48h92.5c9.23 0 19.2 3.8 23.2 14.2 4.7 12.1 3.4 41.6.5 59.7z' /></svg>
//         )
//     }
// });

// const iconClass = mergeStyles({
//     fontSize: 50,
//     height: 50,
//     width: 50,
//     margin: '0 25px',
// });

interface EditFreezerProps extends RouteComponentProps {
  qrCode: string;
  header: string;
  onSaved: () => void;
  onChangeType: (type: CodePropsType) => void;
}

export const EditFreezer = withRouter(({ qrCode, header, history, onSaved, onChangeType }: EditFreezerProps) => {
  const [isValid, setIsValid] = useState(false);
  const [, setInfo] = useState(""); // TODO: show info and error messages
  const [, setError] = useState("");

  const {
    save,
    foodType,
    setFoodType,
    contentType,
    setContentType,
    persons,
    setPersons,
    shortId,
    expirationDate,
    setExpirationDate,
    storageDate,
    amount,
    setAmount,
    amountType,
    setAmountType,
    location,
    setLocation,
    description,
    setDescription,
    unregister,
    codeStores,
    setCodeStores,
    ownerUserId,
    changed,
    busy,
    copyCodeFromLocalSettings,
  } = useObserveCode({ code: qrCode, onSaved, onUnregistered: () => history.replace("/") });

  const { stores } = useObserveUser();

  useEffect(() => {
    setIsValid(foodType !== undefined || contentType !== undefined || (description || "").length > 0);
  }, [foodType, contentType, description]);

  const [showEditFoodChoice, setShowEditFoodChoice] = useState(false);
  const [showEditFoodType, setShowEditFoodType] = useState(false);
  const [showEditLocationChoices, setShowEditLocationChoices] = useState(false);

  const [foodChoices, setFoodChoices] = useState<string[]>(strings.freezer_FoodChoiceList.slice(1));
  const [foodTypes, setFoodTypes] = useState<string[]>(strings.freezer_FoodTypeList.slice(1));
  const [locationChoices, setLocationChoices] = useState<string[]>(strings.freezer_locationChoicesList.slice(1));

  const [optionsFoodChoice, setOptionsFoodChoice] = useState<IComboBoxOption[]>([]);
  const [optionsFoodType, setOptionsFoodType] = useState<IComboBoxOption[]>([]);
  const [optionsLocationChoices, setOptionsLocationChoices] = useState<IComboBoxOption[]>([]);

  useEffect(() => {
    Api.getProperty("user", "freezerFoodChoiceListJson")
      .then((value) => {
        const fc = JSON.parse(value);
        if (fc.length > 0) {
          setFoodChoices(fc);
        }
      })
      .catch(() => {});
    Api.getProperty("user", "freezerFoodTypeListJson")
      .then((value) => {
        const fc = JSON.parse(value);
        if (fc.length > 0) {
          setFoodTypes(fc);
        }
      })
      .catch(() => {});
    Api.getProperty("user", "freezerLocationListJson")
      .then((value) => {
        const fc = JSON.parse(value);
        if (fc.length > 0) {
          setLocationChoices(fc);
        }
      })
      .catch(() => {});
  }, []);

  const updateFoodChoices = (foodChoices: string[]) => {
    setFoodChoices(foodChoices);
    Api.setProperty("user", "freezerFoodChoiceListJson", JSON.stringify(foodChoices));
  };

  const updateFoodTypes = (foodTypes: string[]) => {
    setFoodTypes(foodTypes);
    Api.setProperty("user", "freezerFoodTypeListJson", JSON.stringify(foodTypes));
  };

  const updateLocationChoices = (locationChoices: string[]) => {
    setLocationChoices(locationChoices);
    Api.setProperty("user", "freezerLocationListJson", JSON.stringify(locationChoices));
  };

  useEffect(() => {
    setOptionsFoodChoice(
      Helpers.prepDropdownOptionsWithAddItem(
        foodChoices.map((fc) => ({ key: fc, text: fc })),
        () => setShowEditFoodChoice(true)
      )
    );
  }, [foodChoices]);
  useEffect(() => {
    setOptionsFoodType(
      Helpers.prepDropdownOptionsWithAddItem(
        foodTypes.map((ft) => ({ key: ft, text: ft })),
        () => setShowEditFoodType(true)
      )
    );
  }, [foodTypes]);
  useEffect(() => {
    setOptionsLocationChoices(
      Helpers.prepDropdownOptionsWithAddItem(
        locationChoices.map((lc) => ({ key: lc, text: lc })),
        () => setShowEditLocationChoices(true)
      )
    );
  }, [locationChoices]);

  // const optionsFoodChoice: IComboBoxOption[] = foodChoices.map((title) => {
  //   return { key: title, text: title };
  // });
  // const optionsFoodTypes: IComboBoxOption[] = foodTypes.map((title) => {
  //   return { key: title, text: title };
  // });
  // const optionsLocationChoices: IComboBoxOption[] = locationChoices.map((title) => {
  //   return { key: title, text: title };
  // });

  const optionsAmountTypes: IComboBoxOption[] = strings.freezer_AmountTypeList.map((title) => {
    return { key: title, text: title };
  });

  // useEffect(() => {
  //   if (
  //     location !== props.location ||
  //     foodType !== props.foodType ||
  //     contentType !== props.contentType ||
  //     persons !== props.persons ||
  //     expirationDate !== props.expirationDate ||
  //     amount !== props.amount ||
  //     amountType !== props.amountType ||
  //     description !== props.description
  //   ) {
  //     props.onChange({
  //       location,
  //       foodType,
  //       contentType,
  //       persons,
  //       expirationDate,
  //       date: new Date(),
  //       amount,
  //       amountType,
  //       description,
  //     });
  //   }
  // }, [
  //   location,
  //   foodType,
  //   contentType,
  //   persons,
  //   expirationDate,
  //   amount,
  //   amountType,
  //   description,
  // ]);

  return (
    <>
      <EditTypeModal
        title={strings.freezer_EditFoodChoiceList}
        value={foodChoices}
        onChange={updateFoodChoices}
        show={showEditFoodChoice}
        onDismiss={() => setShowEditFoodChoice(false)}
      />
      <EditTypeModal
        title={strings.freezer_EditFoodTypeList}
        value={foodTypes}
        onChange={updateFoodTypes}
        show={showEditFoodType}
        onDismiss={() => setShowEditFoodType(false)}
      />
      <EditTypeModal
        title={strings.freezer_EditLocationList}
        value={locationChoices}
        onChange={updateLocationChoices}
        show={showEditLocationChoices}
        onDismiss={() => setShowEditLocationChoices(false)}
      />
      <StackVert>
        <Stack.Item>
          <TitleWithInfo
            title={header + (shortId ? " - " + shortId : "")}
            iconFileName={"/ICON_Freezer_64.png"}
            infoTitle={strings.editFreezer_infoHeader}
            code={qrCode}
          >
            <ul>
              <li>{strings.editFreezer_info0}</li>
              <li>{strings.editFreezer_info1}</li>
              <li>{strings.editFreezer_info2}</li>
              <li>{strings.editFreezer_info3}</li>
              <li>{strings.editFreezer_info4}</li>
              <li>{strings.editFreezer_info5}</li>
              <li>{strings.editFreezer_info6}</li>
              <li>{strings.editFreezer_info7}</li>
              <li>{strings.editFreezer_info8}</li>
              <li>{strings.editFreezer_info9}</li>
              <li>{strings.editFreezer_info10}</li>{" "}
            </ul>
          </TitleWithInfo>

          <ParagraphLabel>{strings.freezer_FoodTypeHeader}</ParagraphLabel>
          <StackHorizontal>
            <Stack.Item grow>
              <Dropdown
                options={optionsFoodChoice}
                selectedKey={foodType}
                onRenderOption={OnRenderOptionDropdown}
                onChange={(_ev, option) => {
                  if (option && option.key) setFoodType(option.key as string);
                }}
              />
            </Stack.Item>
          </StackHorizontal>
        </Stack.Item>
        <Stack.Item>
          <ParagraphLabel>{strings.freezer_ContentTypeHeader}</ParagraphLabel>
          <StackHorizontal>
            <Stack.Item grow>
              <Dropdown
                options={optionsFoodType}
                selectedKey={contentType}
                onRenderOption={OnRenderOptionDropdown}
                onChange={(_ev, option) => {
                  if (option && option.key) setContentType(option.key as string);
                }}
              />
            </Stack.Item>
          </StackHorizontal>
        </Stack.Item>
        <Stack.Item>
          <TextField
            multiline
            autoAdjustHeight
            label={strings.generic_description}
            value={description}
            onChange={(_e, value) => setDescription(value || "")}
          />
        </Stack.Item>
        {/* <Stack.Item>
          <ParagraphLabel>{"STR: (The edit date is stored as the freezing date)"}</ParagraphLabel>
        </Stack.Item> */}
        <ItemInfoStacked
          header={strings.handleFreezer_freezing_date}
          content={storageDate ? storageDate.toLocaleDateString() : ""}
        ></ItemInfoStacked>
        <Stack.Item>
          <ParagraphLabel>{strings.freezer_ExpDateHeader}</ParagraphLabel>
          <DatePicker
            placeholder={strings.freezer_SelectADateDotDot}
            ariaLabel={strings.freezer_SelectADate}
            minDate={new Date()}
            value={expirationDate}
            onSelectDate={(d) => setExpirationDate(d || expirationDate)}
          />
        </Stack.Item>
        <Stack.Item>
          <ParagraphLabel>{strings.freezer_PersonCntHeader}</ParagraphLabel>
          {/* icon="Person" unselectedIcon="Person" */}
          <Rating
            size={RatingSize.Large}
            rating={persons}
            max={10}
            title={strings.freezer_PersonCntHeader}
            ariaLabelFormat={strings.freezer_0Of1Persons}
            onChange={(_ev, rating) => setPersons(rating || persons)}
            icon="CircleAdditionSolid"
            unselectedIcon="CircleAddition"
          />
        </Stack.Item>
        <Stack.Item>
          <ParagraphLabel>{strings.freezer_AmountHeader}</ParagraphLabel>
          <StackHorizontal>
            <Stack.Item>
              <TextField
                value={amount ? amount.toString() : ""}
                styles={{ fieldGroup: { width: 100 } }}
                onChange={(_e, newValue) => {
                  setAmount(newValue === undefined ? 0 : +newValue);
                }}
                autoComplete="on"
                type="number"
                onFocus={({ target }) => target.select()}
              />
            </Stack.Item>
            <Stack.Item>
              <Dropdown
                options={optionsAmountTypes}
                selectedKey={amountType}
                onRenderOption={OnRenderOptionDropdown}
                onChange={(_ev, option) => {
                  if (option && option.key) setAmountType(option.key as string);
                }}
              />
            </Stack.Item>
          </StackHorizontal>
        </Stack.Item>
        <Stack.Item>
          <ParagraphLabel>{strings.freezer_Location}</ParagraphLabel>
          <StackHorizontal>
            <Stack.Item grow>
              <Dropdown
                options={optionsLocationChoices}
                selectedKey={location}
                onRenderOption={OnRenderOptionDropdown}
                onChange={(_ev, option) => {
                  if (option && option.key) setLocation(option.key as string);
                }}
              />
            </Stack.Item>
          </StackHorizontal>
        </Stack.Item>
        <Stack.Item>
          <ListsDropdown
            setCodeStores={setCodeStores}
            label={strings.editStatic_ListsHeader}
            selectedLists={codeStores.map((s) => s.id)}
            lists={stores}
            ownerUserId={ownerUserId}
            setError={setError}
            setInfo={setInfo}
          />
        </Stack.Item>
        <ItemFooterSave
          code={qrCode}
          onSave={save}
          isValid={isValid}
          onUnregister={unregister}
          onChangeType={onChangeType}
          canSave={changed && !busy}
          onPasteCode={copyCodeFromLocalSettings}
        />
      </StackVert>
    </>
  );
});
