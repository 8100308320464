import React, { useEffect, useState } from "react";
import { Checkbox, Stack } from "@fluentui/react";
import { ParagraphText, StackVert } from "./Controls";
import { Language } from "./Strings";
import { useObserveUser } from "../hooks/useObserveUser";
import { ListsDropdown } from "./ListsDropdown";

const strings = Language.strings;

export const TabSettings = ({
  tabChoices,
  onTabChoiceChange,
}: {
  tabChoices: string[];
  onTabChoiceChange: (tabChoices: string[]) => Promise<void>;
}) => {
  const { stores, userId } = useObserveUser();

  const [tabState, setTabState] = useState({
    showTabStatic: false,
    showTabBoxes: false,
    showTabLostFound: false,
    showTabFreezer: false,
    showTabMessages: false,
    selectedLists: [] as string[],
  });

  useEffect(() => {
    if (tabChoices) {
      setTabState({
        showTabStatic: tabChoices.some((x) => x === "static"),
        showTabBoxes: tabChoices.some((x) => x === "boxes"),
        showTabLostFound: tabChoices.some((x) => x === "lostFound"),
        showTabFreezer: tabChoices.some((x) => x === "freezer"),
        showTabMessages: tabChoices.some((x) => x === "html"),
        selectedLists: tabChoices.filter((x) => x.length > 20),
      });
    }
  }, [tabChoices]);

  const getTabChoices = (tabState: any) => {
    let tabChoices: string[] = [];
    if (tabState.showTabStatic) tabChoices.push("static");
    if (tabState.showTabBoxes) tabChoices.push("boxes");
    if (tabState.showTabLostFound) tabChoices.push("lostFound");
    if (tabState.showTabFreezer) tabChoices.push("freezer");
    if (tabState.showTabMessages) tabChoices.push("html");
    tabChoices = tabChoices.concat(tabState.selectedLists);
    return tabChoices;
  };

  const setState = (newState: any) => {
    setTabState(newState);
    onTabChoiceChange(getTabChoices(newState));
  };

  return (
    <Stack>
      {/* <Stack.Item>
        <ParagraphTitle>{strings.userSettings_manageViewTabs}</ParagraphTitle>
      </Stack.Item>
      <Stack.Item>
        <ParagraphText>{strings.userSettings_ChooseWhichTabsToShow}</ParagraphText>
      </Stack.Item> */}
      <Stack.Item>
        <StackVert>
          <Checkbox
            label={strings.userSettings_Stuff}
            checked={tabState.showTabStatic}
            onChange={(_ev, checked) => setState({ ...tabState, showTabStatic: !!checked })}
          />
          <Checkbox
            label={strings.userSettings_boxes}
            checked={tabState.showTabBoxes}
            onChange={(_ev, checked) => setState({ ...tabState, showTabBoxes: !!checked })}
          />
          <Checkbox
            label={strings.userSettings_lostFound}
            checked={tabState.showTabLostFound}
            onChange={(_ev, checked) => setState({ ...tabState, showTabLostFound: !!checked })}
          />
          <Checkbox
            label={strings.userSettings_freezer}
            checked={tabState.showTabFreezer}
            onChange={(_ev, checked) => setState({ ...tabState, showTabFreezer: !!checked })}
          />
          <Checkbox
            label={strings.userSettings_messages}
            checked={tabState.showTabMessages}
            onChange={(_ev, checked) => setState({ ...tabState, showTabMessages: !!checked })}
          />
        </StackVert>
      </Stack.Item>
      <Stack.Item>
        <ParagraphText>{strings.userSettings_ChooseWhichTabsToShow}</ParagraphText>
      </Stack.Item>
      {userId && (
        <Stack.Item>
          <ListsDropdown
            setCodeStores={(cs) => setState({ ...tabState, selectedLists: cs.map((c) => c.id) })}
            label={strings.editStatic_ListsHeader}
            selectedLists={tabState.selectedLists}
            lists={stores}
            ownerUserId={userId!}
            setError={() => {}}
            setInfo={() => {}}
          />
        </Stack.Item>
      )}
    </Stack>
  );
};
