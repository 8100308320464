import React, { useEffect, useState } from "react";
import { Stack, PrimaryButton, Checkbox, Dialog, DialogFooter, DefaultButton, DialogType } from "@fluentui/react";
import { StackHorizontal, StackVert } from "./Controls";
import { strings } from "./Strings";
import { UploadDialog } from "./UploadDialog";
import Api from "../api";
import { Files } from "../models";
import { useObserveUser } from "../hooks";

interface ImagesPanelProps {
  code: string;
  defaultImageId?: string;
  images: Files[];
  setDefaultImageId: (id?: string) => void;
  onImageAdded?: () => void;
  onImageDeleted?: () => void;
  viewMode?: boolean;
  quickAdd?: boolean;
}

export const ImagesPanel = ({
  code,
  images,
  defaultImageId,
  setDefaultImageId,
  onImageAdded,
  onImageDeleted,
  viewMode,
  quickAdd,
}: ImagesPanelProps) => {
  const [showUploadDialog, setShowUploadDialog] = useState(quickAdd === true);
  const [urls, setUrls] = useState([] as string[]); // TODO: make this a dictionary
  const [imageId, setImageId] = useState<string>();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [hasImage, setHasImage] = useState(false);
  const [metadata, setMetadata] = useState<any>({});
  // const [image, setImage] = useState<Files>();
  const [isPrivate, setIsPrivate] = useState(true);
  const [showReallyChangeImageToPrivate, setShowReallyChangeImageToPrivate] = useState(false);

  const closeShowReallyChangeToPrivate = () => setShowReallyChangeImageToPrivate(false);

  const { disableShowReallyChangeImageToPrivate, setDisableShowReallyChangeImageToPrivate } = useObserveUser();

  useEffect(() => {
    setImageId(defaultImageId || images[0]?.id);
  }, [defaultImageId, images]);

  useEffect(() => {
    Promise.all(images.map((image) => Api.getFileUrl(image.id))).then((urls) => setUrls(urls));
    // if (imageIndex > images.length - 1) {
    //   setImageIndex(0);
    // }
    if (images.length > 0) {
      setHasImage(true);
    }
    const image = images.find((image) => image.id === imageId);
    if (image) {
      setMetadata(JSON.stringify(image.metadataJson));
      setIsPrivate(image.private);
    }
  }, [images, imageId]);

  // useEffect(() => {
  //   console.log(urls);
  // }, [urls]);

  // useEffect(() => {
  //   if (imageIndex < images.length) {
  //     setMetadata(JSON.stringify(images[imageIndex].metadataJson));
  //   }
  // }, [images, imageIndex]);

  const onChangeDefaultImage = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
    if (checked === true) {
      setDefaultImageId(imageId); // TODO: call setDefaultImageId instead!
    }
  };

  const onChangePrivate = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
    const newValue = checked || false;

    if (!newValue && !disableShowReallyChangeImageToPrivate) {
      setShowReallyChangeImageToPrivate(true);
    } else {
      const image = images.find((image) => image.id === imageId);
      if (image) {
        image.private = newValue;
        Api.updateFile(image);
        setIsPrivate(newValue);
      }
    }
  };

  const onReallyChangeToNotPrivate = () => {
    const image = images.find((image) => image.id === imageId);
    if (image) {
      image.private = false;
      Api.updateFile(image);
      setIsPrivate(false);
      setShowReallyChangeImageToPrivate(false);
    }
  };

  const onChangeDisableShowReallyChangeToPrivate = (
    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => {
    const newValue = checked || false;
    setDisableShowReallyChangeImageToPrivate(newValue);
  };

  // TODO: move to useObserveCode
  const deleteImage = () => {
    const image = images.find((image) => image.id === imageId);
    if (!image) {
      return;
    }

    Api.deleteFile(image.id)
      .then(() => {
        // TODO: the following 5 lines should not be necessary
        setImageId(undefined);
        const imageIndex = images.findIndex((image) => image.id === imageId);
        urls.splice(imageIndex, 1);
        setUrls([...urls]);
        images.splice(imageIndex, 1);
        setHasImage(images.length > 0);

        setShowDeleteDialog(false);
        onImageDeleted!();
      })
      .catch((e) => {
        console.warn(e);
      });
  };

  return (
    <StackVert>
      <Stack.Item>
        {/* <ParagraphLabel>{strings.imagePanel_panelHeader}</ParagraphLabel> */}
        <div
          style={{
            boxSizing: "border-box",
            // width: "80vw",
            maxWidth: "32rem",
            margin: "4px",
            boxShadow: "0 2px 4px 0 rgb(0 0 0 / 20%), 0 2px 4px 0 rgb(0 0 0 / 19%)",
          }}
        >
          {hasImage && (
            <img
              style={{
                boxSizing: "border-box",
                width: "100%",
                objectFit: "contain",
              }}
              src={urls[images.findIndex((image) => image.id === imageId)]}
              alt={"default"}
            />
          )}
        </div>

        {/* {!showUploadDialog && code && <ItemImage code={code} />} */}
        {/* TODO: Fix the code! issue.... */}
        {!viewMode && (
          <>
            <StackVert>
              <StackHorizontal>
                <Stack.Item>
                  <PrimaryButton
                    disabled={!code}
                    onClick={() => {
                      setShowUploadDialog(true);
                    }}
                  >
                    {strings.imagePanel_addNewImage}
                  </PrimaryButton>
                </Stack.Item>
                <Stack.Item>
                  <PrimaryButton
                    disabled={!code || !hasImage}
                    onClick={() => {
                      setShowDeleteDialog(true);
                    }}
                  >
                    {strings.imagePanel_removeImage}
                  </PrimaryButton>
                </Stack.Item>
              </StackHorizontal>
              <Stack.Item>
                <Checkbox
                  label={strings.imagePanel_mainImage}
                  checked={imageId === defaultImageId}
                  onChange={onChangeDefaultImage}
                  disabled={!code || !hasImage || imageId === defaultImageId}
                />
              </Stack.Item>
              <Stack.Item>
                <Checkbox
                  label={strings.imagePanel_private}
                  checked={isPrivate}
                  disabled={!code || !hasImage}
                  onChange={onChangePrivate}
                />
              </Stack.Item>
              {/* <Stack.Item>
                <PrimaryButton onClick={() => setDefaultImageId(images[0].id)}>SAVESAVE</PrimaryButton>
              </Stack.Item> */}
            </StackVert>
            <Dialog
              hidden={!showReallyChangeImageToPrivate}
              onDismiss={closeShowReallyChangeToPrivate}
              dialogContentProps={{
                type: DialogType.normal,
                title: strings.imagePanel_title,
                closeButtonAriaLabel: strings.itemFooter_close,
                subText: strings.imagePanel_text,
              }}
            >
              <DialogFooter>
                <StackVert>
                  <PrimaryButton onClick={onReallyChangeToNotPrivate} text={strings.generic_yes} />
                  <DefaultButton onClick={closeShowReallyChangeToPrivate} text={strings.generic_no} />
                  <Stack.Item>
                    <Checkbox
                      label={strings.imagePanel_disableInFuture}
                      checked={disableShowReallyChangeImageToPrivate}
                      disabled={!code}
                      onChange={onChangeDisableShowReallyChangeToPrivate}
                    />
                  </Stack.Item>
                </StackVert>
              </DialogFooter>
            </Dialog>
            {code && (
              <UploadDialog
                hidden={!showUploadDialog}
                filename={code}
                quickAdd={quickAdd}
                onDismiss={() => {
                  setShowUploadDialog(false);
                  onImageAdded!();
                }}
                code={code}
              />
            )}
          </>
        )}
      </Stack.Item>
      {images.length > 1 && (
        <Stack.Item>
          <div>
            {images.map((_image, i) => {
              // TODO: use images and urls dictionary
              // const md = JSON.parse(images[i].metadataJson);
              return (
                <div
                  key={i}
                  style={{
                    boxSizing: "border-box",
                    float: "left",
                    width: "20%",
                    padding: "4px",
                    boxShadow: "0 2px 4px 0 rgb(0 0 0 / 20%), 0 2px 4px 0 rgb(0 0 0 / 19%)",
                  }}
                  onClick={() => setImageId(images[i].id)}
                >
                  <img
                    style={{
                      border: 1,
                      boxSizing: "border-box",
                      objectFit: "contain",
                      width: "100%",
                    }}
                    src={urls[i]}
                    alt={"small"}
                  />
                </div>
              );
            })}
          </div>
        </Stack.Item>
      )}
      <Dialog
        title={strings.imagePanel_deleteImage + "?"}
        // dialogContentProps={{ subText: strings.imagePanel_deleteSelectedImages }}
        hidden={!showDeleteDialog}
        onDismiss={() => setShowDeleteDialog(false)}
        modalProps={{ isBlocking: true }}
      >
        <DialogFooter>
          {!viewMode && <PrimaryButton onClick={deleteImage} text={strings.generic_yes} />}
          <DefaultButton onClick={() => setShowDeleteDialog(false)} text={strings.generic_cancel} />
        </DialogFooter>
      </Dialog>
    </StackVert>
  );
};
