import React, { useEffect, useState } from "react";
import { Stack, Panel, PrimaryButton, IconButton, Icon } from "@fluentui/react";
import { PageTitle } from "./Controls";
import { useObserveCode } from "../hooks/useObserveCode";
import { strings } from "./Strings";
import { FeedbackForm } from "./FeedbackForm";

export const TitleWithInfo = ({
  title,
  iconFileName,
  infoTitle,
  code,
  children,
  onClose,
}: React.PropsWithChildren<{
  title: string;
  iconFileName?: string;
  infoTitle: string;
  code?: string;
  onClose?: () => void;
}>) => {
  const [showHelp, setShowHelp] = useState(false);
  const [showMisuseButton, setShowMisuseButton] = useState(false);
  const { isMine } = useObserveCode({ code: code || "" });

  useEffect(() => {
    setShowMisuseButton(!isMine && code !== undefined);
  }, [isMine, code]);

  const reportMisuse = () => {
    // TODO: Report misuse
    alert("Report misuse: " + code);
  };

  return (
    <Stack.Item>
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <Stack.Item align="center">
          {iconFileName === "SearchIssue" && <Icon iconName="SearchIssue" />}
          {iconFileName === "FileHTML" && <Icon iconName="FileHTML" />}
          {iconFileName !== "SearchIssue" && iconFileName !== "FileHTML" && iconFileName && (
            <img width={"35rem"} style={{ paddingRight: 4 }} src={iconFileName} alt="icon" />
          )}
        </Stack.Item>
        <Stack.Item grow align="center">
          <PageTitle>{title}</PageTitle>
        </Stack.Item>
        <Stack.Item style={{ marginLeft: 0 }}>
          <IconButton iconProps={{ iconName: "Info" }} onClick={() => setShowHelp(true)} />
        </Stack.Item>
        {onClose && (
          <Stack.Item style={{ marginLeft: 0 }}>
            <IconButton iconProps={{ iconName: "Cancel" }} onClick={onClose} />
          </Stack.Item>
        )}
      </Stack>
      <Panel
        headerText={infoTitle}
        isLightDismiss
        isOpen={showHelp}
        onDismiss={() => setShowHelp(false)}
        closeButtonAriaLabel="Close"
      >
        {children}
        <ul>
          <li>{strings.titleWithInfo_reportMisuseDescript}</li>
        </ul>
        {showMisuseButton && <Stack.Item>{strings.titleWithInfo_reportMisuseDescript}</Stack.Item>}
        {showMisuseButton && (
          <Stack.Item>
            <PrimaryButton onClick={reportMisuse} disabled={isMine} text={strings.titleWithInfo_reportMisuse} />
          </Stack.Item>
        )}
        <FeedbackForm extra={"Code: " + code} />
      </Panel>
    </Stack.Item>
  );
};
