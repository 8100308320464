import React, { useEffect, useState } from "react";
import { MessageBar, MessageBarType } from "@fluentui/react";

export const ErrorBar = ({ error }: { error?: string }) => {
  const [text, setText] = useState<string>();
  useEffect(() => setText(error), [error]);

  if (!text || text.trim().length === 0) return null;
  return (
    <MessageBar messageBarType={MessageBarType.error} onDismiss={() => setText(undefined)}>
      {text}
    </MessageBar>
  );
};
