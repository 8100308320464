import React, { useState } from "react";
import { Stack, MessageBar, MessageBarType, Spinner, SpinnerSize } from "@fluentui/react";
import {
  StackVert,
  ParagraphText,
  ParagraphLabel,
  ItemInfoStacked,
  ShowList,
  CodeShowAndLinkToQRImage,
} from "./Controls";
import { Language } from "./Strings";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useObserveCode } from "../hooks/useObserveCode";
import { ItemFooterEdit } from "./ItemFooterEdit";
import { TitleWithInfo } from "./TitleWithInfo";
import { ListMessages } from "./ListMessages";

const strings = Language.strings;

interface HandleFreezerProps extends RouteComponentProps {
  code: string;
}

export const HandleFreezer = withRouter(({ history, code }: HandleFreezerProps) => {
  const {
    shortId,
    foodType,
    contentType,
    description,
    persons,
    amount,
    amountType,
    storageDate,
    expirationDate,
    busy,
    codeStores,
    location,
  } = useObserveCode({ code, onSaved: () => history.goBack() });

  const [error, setError] = useState(undefined);

  return (
    <StackVert>
      {!busy && (
        <Stack.Item>
          {error && (
            <MessageBar messageBarType={MessageBarType.error} onDismiss={() => setError(undefined)}>
              {error}
            </MessageBar>
          )}
        </Stack.Item>
      )}
      <TitleWithInfo
        title={shortId ? shortId : ""}
        iconFileName=""
        infoTitle={strings.editFreezer_infoHeader}
        code={code}
      >
        <ul>
          {/* TODO: update this list */}
          <li>"{strings.editFreezer_info0}"</li>
          <li>"{strings.editFreezer_info1}"</li>
        </ul>
      </TitleWithInfo>
      <ItemInfoStacked
        header={strings.freezer_FoodTypeHeader + ':'}
        content={foodType ? foodType : strings.generic_noTypeSet}
      ></ItemInfoStacked>
      <ItemInfoStacked
        header={strings.freezer_ContentTypeHeader  + ':'}
        content={contentType ? contentType : strings.generic_noTypeSet}
      ></ItemInfoStacked>
      {description && description !== "-" && (
        <Stack.Item>
          <ParagraphLabel>{strings.generic_description}</ParagraphLabel>
          <ParagraphText>{description}</ParagraphText>
        </Stack.Item>
      )}
      <ItemInfoStacked
        header={strings.freezer_PersonCntHeader + ':'}
        content={persons.toString() + " " + strings.handleFreezer_persons}
      ></ItemInfoStacked>
      <ItemInfoStacked
        header={strings.freezer_AmountHeader + ':'}
        content={amount.toString() + " " + amountType}
      ></ItemInfoStacked>
      <ItemInfoStacked header={strings.freezer_Location} content={location}></ItemInfoStacked>
      <ItemInfoStacked
        header={strings.handleFreezer_freezing_date + ':'}
        content={storageDate?.toLocaleDateString() || ""}
      ></ItemInfoStacked>
      <ItemInfoStacked
        header={strings.freezer_ExpDateHeader + ':'}
        content={expirationDate?.toLocaleDateString() || strings.handleFreezer_noDateSet}
      ></ItemInfoStacked>
      <ShowList header={strings.generic_ListsShow} codesLists={codeStores}></ShowList>
      <CodeShowAndLinkToQRImage code={code}></CodeShowAndLinkToQRImage>
      <ListMessages code={code} showSendMessage={false} />
      <Stack.Item>{busy && <Spinner size={SpinnerSize.large} />}</Stack.Item>
      <ItemFooterEdit code={code} />
    </StackVert>
  );
});
