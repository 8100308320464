import { mergeStyleSets, Stack, StackItem } from "@fluentui/react";
import React from "react";
import { withRouter } from "react-router-dom";
import { Helpers } from "../helpers";
import { Content } from "./Content";
import { PageTitle, ParagraphText, StackVert, StackHorizontal, ParagraphLabel } from "./Controls";
import { Header } from "./Header";
import { Language } from "./Strings";

const strings = Language.strings;

const classNames = mergeStyleSets({
  fileIconImg: {
    verticalAlign: "middle",
    maxHeight: "16px",
    maxWidth: "16px",
  },
});
export const ListItemsHelp = withRouter((props: any) => {
  //const statusIconFilename = Helpers.getStatusIconByName('in')

  return (
    <StackVert>
      <Header />
      <Content>
        <PageTitle>{strings.listItemHelp_Header}</PageTitle>
        <ParagraphLabel>{strings.listItemHelp_WhereIconHeader}</ParagraphLabel>
        <StackHorizontal>
          <Stack.Item>
            <img src={Helpers.getStatusIconByName("home")} className={classNames.fileIconImg} alt="Home" />
          </Stack.Item>
          <Stack.Item>
            <ParagraphText>{strings.listItemHelp_WhereIcon_Home}</ParagraphText>
            <br />
          </Stack.Item>
        </StackHorizontal>
        <StackHorizontal>
          <Stack.Item>
            <img src={Helpers.getStatusIconByName("in")} className={classNames.fileIconImg} alt="In" />
          </Stack.Item>
          <Stack.Item>
            <ParagraphText>{strings.listItemHelp_WhereIcon_In}</ParagraphText>
            <br />
          </Stack.Item>
        </StackHorizontal>
        <StackHorizontal>
          <Stack.Item>
            <img src={Helpers.getStatusIconByName("out")} className={classNames.fileIconImg} alt="Out" />
          </Stack.Item>
          <Stack.Item>
            <ParagraphText>{strings.listItemHelp_WhereIcon_Out}</ParagraphText>
            <br />
          </Stack.Item>
        </StackHorizontal>
        <StackHorizontal>
          <Stack.Item>
            <img src={Helpers.getStatusIconByName("freezer")} className={classNames.fileIconImg} alt="Freezer" />
          </Stack.Item>
          <Stack.Item>
            <ParagraphText>{strings.listItemHelp_WhereIcon_Freezer}</ParagraphText>
            <br />
          </Stack.Item>
        </StackHorizontal>

        <ParagraphLabel>{strings.listItemHelp_OtherHeader}</ParagraphLabel>
        <ParagraphText>
          <StackVert>
            <StackItem>{strings.generic_name}</StackItem>
            <StackItem>{strings.listItemHelp_Descr}</StackItem>
            <StackItem>{strings.listItemHelp_Image}</StackItem>
          </StackVert>
        </ParagraphText>
        <br />
      </Content>
    </StackVert>
  );
});
