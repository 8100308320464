import {
  DefaultButton,
  Dialog,
  DialogContent,
  DialogFooter,
  IContextualMenuProps,
  IconButton,
  mergeStyleSets,
} from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { Store } from "../models";
import { useObserveStore } from "../hooks";
import { Helpers } from "../helpers";
import { useHistory } from "react-router-dom";
import { strings } from "./Strings";
import { ItemInfoStacked } from "./Controls";
import Api from "../api";

const classNames = mergeStyleSets({
  fileIconImg: {
    verticalAlign: "middle",
    maxHeight: "16px",
    maxWidth: "16px",
  },
});

export const StoreContextualMenu = ({ store }: { store: Store }) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [target, setTarget] = useState(null);
  const [userName, setUserName] = useState("");
  const { canEditCodes } = useObserveStore({
    storeId: store.id,
    onSaved: () => { },
    onRemoved: () => { },
  });
  const history = useHistory();
  const [dialogHidden, setDialogHidden] = useState(true);

  const hideDialog = () => {
    setDialogHidden(true);
  };

  useEffect(() => {
    if (!store.isPrivateList && store.userId) {
      Api.getUserInfo(store.userId).then((userInfo) => setUserName(userInfo.username)).catch(() => { });
    }
  }
    , [store.userId, store.isPrivateList]);

  const menuProps: IContextualMenuProps = {
    items: [
      {
        key: "edit",
        text: strings.generic_edit,
        disabled: !canEditCodes,
        iconProps: { iconName: "Edit" },
        onClick: () => history.push("/editstore/" + store.id),
      },
      {
        key: "share",
        text: strings.generic_share,
        iconProps: { iconName: "Share" },
        disabled: store.allowResharing !== true && canEditCodes !== true,
        onClick: () => {
          Helpers.shareStore(store.id).catch(() => { });
        },
      },
      {
        key: "properties",
        text: strings.generic_properties,
        iconProps: { iconName: "View" },
        onClick: () => setDialogHidden(false),
      },
    ],
    //  hidden: !menuVisible,
    target: target,
    onItemClick: () => setMenuVisible(false),
    onDismiss: () => setMenuVisible(false),
  };

  return (
    <>
      <IconButton
        iconProps={{ iconName: "MoreVertical" }}
        className={classNames.fileIconImg}
        title={strings.storeContextualMenu_moreOptions}
        ariaLabel={strings.storeContextualMenu_moreOptions}
        onClick={(event: any) => {
          setTarget(event.currentTarget);
          setMenuVisible(!menuVisible);
        }}
        menuProps={menuProps}
        styles={{
          menuIcon: { display: "none" },
        }}
      />
      <Dialog
        title={strings.storeContextualMenu_listProperties}
        //   dialogContentProps={{ subText: strings.viewStore_DeleteStoreQuestion }}
        hidden={dialogHidden}
        onDismiss={hideDialog}
        modalProps={{ isBlocking: true }}
      >
        <DialogContent>

          {!store.isPrivateList && <ItemInfoStacked header={strings.storeContextualMenu_listOwner + ':'} content={userName} />}
          <ItemInfoStacked header={strings.storeContextualMenu_listName + ':'} content={store.name} />
          <ItemInfoStacked header={strings.generic_description + ':'} content={store.description} />
        </DialogContent>
        <DialogFooter>
          {/* <PrimaryButton onClick={deleteStore} text={strings.viewStore_Delete} /> */}
          <DefaultButton onClick={hideDialog} text={strings.viewStore_Cancel} />
        </DialogFooter>
      </Dialog>
    </>
  );
};
