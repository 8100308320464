import React from "react";
import { Stack, ComboBox, PrimaryButton, IComboBoxProps } from "@fluentui/react";
import { StackVert, ParagraphText, ItemInfoStacked, ShowList, CodeShowAndLinkToQRImage } from "./Controls";
import { Language } from "./Strings";
import { useObserveCode } from "../hooks/useObserveCode";
import { ParagraphLabel } from ".";
import { TitleWithInfo } from "./TitleWithInfo";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ListMessages } from "./ListMessages";
import { ImagesPanel } from "./ImagesPanel";
import { ItemFooterEdit } from "./ItemFooterEdit";

const strings = Language.strings;

interface HandleBorrowingItemProps {
  code: string;
  onBorrow: () => void;
}

interface HandleBorrowingItemProps extends RouteComponentProps {
  code: string;
}

export const HandleBorrowingItem = withRouter(({ code, onBorrow }: HandleBorrowingItemProps) => {
  const {
    name,
    description,
    itemType,
    category,
    ownerUserName,
    returnNumDays,
    rentable,
    codeStores,
    images,
    setReturnNumDays,
    rentCostDescr,
    statusIconFilename,
    defaultImageId,
    setDefaultImageId,
    hasCodeEditAccess,
  } = useObserveCode({ code });

  const _onChange: IComboBoxProps["onChange"] = (event, option) => {
    if (option) {
      const days = +option.key;
      setReturnNumDays(days);
    }
  };

  const returnDateList = [
    { key: 1, text: strings.handleBorrowingItem_oneDay },
    { key: 3, text: strings.handleBorrowingItem_threDays },
    { key: 7, text: strings.handleBorrowingItem_oneWeek },
    { key: 30, text: strings.handleBorrowingItem_onemonth },
    { key: 90, text: strings.handleBorrowingItem_threeMonths },
    { key: 365, text: strings.handleBorrowingItem_oneYear },
    { key: 3650, text: strings.handleBorrowingItem_noEndDate },
  ];

  return (
    <StackVert>
      <TitleWithInfo
        title={strings.handleBorrowingItem_wantToBorrowFrom + (name ? " - " + name : "")}
        iconFileName={statusIconFilename}
        infoTitle={strings.handleBorrowingItem_infoHeader}
        code={code}
      >
        <ul>
          <li>{strings.handleBorrowingItem_Info1}</li>
          <li>{strings.handleBorrowingItem_Info2}</li>
          <li>{strings.handleBorrowingItem_Info3}</li>
          <li>{strings.handleBorrowingItem_Info4}</li>
          <li>{strings.handleBorrowingItem_Info5}</li>
        </ul>
      </TitleWithInfo>
      <Stack.Item>
        <Stack tokens={{ childrenGap: 10 }}>
          <ItemInfoStacked header={strings.handleBorrowingItem_OwnerName} content={ownerUserName}></ItemInfoStacked>
          <ItemInfoStacked header={strings.handleBorrowingItem_TypeOfItem} content={itemType}></ItemInfoStacked>
          <ItemInfoStacked header={strings.handleBorrowingItem_Category} content={category}></ItemInfoStacked>
          <ItemInfoStacked header={strings.handleBorrowingItem_ItemName} content={name}></ItemInfoStacked>
          {description && (
            <Stack.Item>
              <ParagraphLabel>{strings.generic_description}</ParagraphLabel>
              <ParagraphText>{description}</ParagraphText>
            </Stack.Item>
          )}
          {rentable && rentCostDescr && rentCostDescr !== "-" && (
            <Stack.Item>
              <ParagraphLabel>{strings.registerItem_rentInfo}</ParagraphLabel>
              <ParagraphText>{rentCostDescr}</ParagraphText>
            </Stack.Item>
          )}
          {images.length > 0 && (
            <Stack.Item>
              <ParagraphLabel>{strings.item_photo}</ParagraphLabel>
              <ImagesPanel
                viewMode
                code={code}
                defaultImageId={defaultImageId}
                setDefaultImageId={setDefaultImageId}
                images={images}
              />
            </Stack.Item>
          )}
          {/* <Stack.Item>
                    {returnDate && <ParagraphLabel>{strings.handleBorrowingItem_whenGiveBack1} {Helpers.getDateDiffInDays(new Date(), returnDate)} {strings.handleBorrowingItem_whenGiveBack2}</ParagraphLabel>}
                </Stack.Item> */}
          {/* <Stack.Item> */}
          {/* <Label>Beskrivning</Label> */}
          {/* Visas endast om prylen inte är utlånad */}
          {/* </Stack.Item> */}
          <Stack.Item>
            <ComboBox
              style={{ maxWidth: 200 }}
              // TODO: Update props with the selection
              // TODO: Update the CodeLog with the ReturnDate, based on today and the choice here
              // defaultSelectedKey="3"
              selectedKey={returnNumDays}
              // defaultSelectedKey={"7"}
              defaultValue={"7"}
              options={returnDateList}
              label={strings.handleBorrowingItem_whenReturnItem}
              onChange={_onChange}
            // Possibly add a datum chooser?
            />
          </Stack.Item>
          {/* {Helpers.hasFeature('rent') && rentable && <Stack.Item>
                    <ParagraphTitle id="rentable">{strings.handleBorrowingItem_InfoAboutRent}</ParagraphTitle>
                    <ParagraphText>{rentCostDescr}</ParagraphText>
                    {enableRenterChatRent && <Stack.Item>
                        <TextField multiline label={strings.handleBorrowingItem_SendMessToOwner} value={rentMessToOwner} onChange={(_e, value) => setRentMessToOwner(value || '')} />
                    </Stack.Item>}
                </Stack.Item>} */}
          <Stack.Item>
            <ShowList header={strings.generic_ListsShow} codesLists={codeStores}></ShowList>
          </Stack.Item>
          <Stack.Item>
            <CodeShowAndLinkToQRImage code={code}></CodeShowAndLinkToQRImage>
          </Stack.Item>
          <Stack.Item>
            <ListMessages code={code} showSendMessage={true} />
          </Stack.Item>
          {/* <ItemInfoStacked header={strings.generic_QRCode} content={code}></ItemInfoStacked> */}
          <Stack horizontal tokens={{ childrenGap: 15 }}>
            <Stack.Item grow>
              <PrimaryButton onClick={onBorrow}>{strings.handleBorrowingItem_borrow}</PrimaryButton>
            </Stack.Item>
            {/* <Stack.Item>
                                <PrimaryButton disabled={!Helpers.canShare()}
                                    onClick={async () => await Helpers.share(value.id)}>{strings.handleBorrowedItem_share}</PrimaryButton>
                            </Stack.Item> */}
            <Stack.Item>
              {!hasCodeEditAccess && (
                <PrimaryButton onClick={() => /*window.history.back()*/(window.location.href = "/")}>
                  {strings.generic_close}
                </PrimaryButton>
              )}
              {hasCodeEditAccess && <ItemFooterEdit code={code} />}
            </Stack.Item>
          </Stack>
          <Stack.Item></Stack.Item>
        </Stack>
      </Stack.Item>
    </StackVert>
  );
});
