import React, { useEffect } from "react";
import { Html5QrcodeScanner } from "html5-qrcode";
import { strings } from "./Strings";
import { PrimaryButton, Modal, Stack, TextField, IconButton } from "@fluentui/react";
import { ParagraphLabel, StackVert } from "./Controls";

export const ScanDialog = ({
  hidden,
  onDismiss,
  onScanned,
}: {
  hidden: boolean;
  onDismiss: () => void;
  onScanned: (data: any) => void;
}) => {
  const [readerRef, setReaderRef] = React.useState<HTMLDivElement | null>(null);
  const [data, setData] = React.useState<string>("");

  useEffect(() => {
    if (!readerRef) return;
    const html5QrcodeScanner = new Html5QrcodeScanner(
      "reader2",
      {
        fps: 10,
        qrbox: { width: 250, height: 250 },
      },
      false
    );

    html5QrcodeScanner.render(
      (result: any) => {
        // alert(JSON.stringify(result, null, 2));
        onScanned(result);
      },
      (e: any) => {
        // console.log(e);
      }
    );

    return () => {
      html5QrcodeScanner.clear();
    };
  }, [onScanned, readerRef]);

  return (
    <Modal isOpen={!hidden} onDismiss={onDismiss} isBlocking={true}>
      <StackVert>
        <div ref={(ref) => setReaderRef(ref)} id="reader2"></div>
        <div style={{ margin: 10 }}>
          <ParagraphLabel>{strings.scanDialog_manualEnter}</ParagraphLabel>
          <Stack horizontal>
            <Stack.Item grow>
              <TextField value={data} onChange={(_e, newValue) => setData(newValue || "")} />
            </Stack.Item>
            <IconButton
              disabled={data.trim().length === 0}
              iconProps={{ iconName: "Send" }}
              onClick={() => onScanned(data)}
            />
          </Stack>
        </div>
        <Stack horizontal horizontalAlign="end" style={{ margin: 10 }}>
          <PrimaryButton onClick={onDismiss} text={strings.generic_close} />
        </Stack>
      </StackVert>
    </Modal>
  );
};
