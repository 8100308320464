import { useCallback, useEffect, useState } from "react";
import Api from "../api";
import { Language } from "../components/Strings";
import { Helpers } from "../helpers";
import { CodeInfo, CodeProps, CodePropsType, Files, StoreInfo } from "../models";

const strings = Language.strings;

interface ObserveCodeProps {
  code: string;
  onSaved?: () => void;
  onUnregistered?: () => void;
}

export const useObserveCode = ({ code: qrCode, onSaved, onUnregistered }: ObserveCodeProps) => {
  const [busy, setBusy] = useState(false);
  const [code, setCode] = useState(qrCode);
  const [codeInfo, setCodeInfo] = useState<CodeInfo>();
  // const [stores, setStores] = useState<StoreInfo[]>([]);
  const [error, setError] = useState<string>();
  const [hasCodeEditAccess, setHasCodeEditAccess] = useState(false);

  // General
  const [name, setName] = useState("");
  const [type, setType] = useState<CodePropsType>();
  const [state, setState] = useState("");
  const [description, setDescription] = useState("");
  const [changeDate, setChangeDate] = useState<Date>(new Date());
  const [statusIconFilename, setStatusIconFilename] = useState("");

  // Files (images)
  const [images, setImages] = useState<Files[]>([]);
  // const [defaultImageIndex, setDefaultImageIndex] = useState<number>(0);
  const [defaultImageId, setDefaultImageId] = useState<string>();
  const [defaultImage, setDefaultImage] = useState<Files>();
  const [, setHasImage] = useState(false); // TODO: remove?

  // LostFound
  const [lostPayValue, setLostPayValue] = useState("");
  const [showHowMuchPay, setShowHowMuchPay] = useState(false);
  const [enablePosition, setEnablePosition] = useState(false);
  const [offerPay, setOfferPay] = useState(false);
  const [possibleSendMess, setPossibleSendMess] = useState(false);
  const [showPhoneNumber, setShowPhoneNumber] = useState(false);
  const [showInfoSendMess, setShowInfoSendMess] = useState(false);
  const [showInfoOfferPay, setShowInfoOfferPay] = useState(false);
  const [showInfoShowHowMuchPay, setShowInfoShowHowMuchPay] = useState(false);

  // User info
  const [ownerUserId, setOwnerUserId] = useState("");
  const [ownerUserName, setOwnerUserName] = useState("");
  const [ownerPhone, setOwnerPhone] = useState("");
  const [isMine, setIsMine] = useState(false);
  const [isBorrowed, setIsBorrowed] = useState(false);
  const [isRegistered, setIsRegistered] = useState(true);
  const [isAwaitingOwnerReturnAccept, setIsAwaitingOwnerReturnAccept] = useState(false);

  const [borrowerUserName, setBorrowerUserName] = useState("");

  // Stores
  const [codeStores, setCodeStores] = useState<StoreInfo[]>([]);

  // Boxes
  const [shortId, setShortId] = useState("");
  const [storageDate, setStorageDate] = useState<Date>(new Date());

  const [content, setContent] = useState(localStorage.getItem("boxContent") || "");
  const [contentType, setContentType] = useState(
    localStorage.getItem("boxContentType") || strings.EditBoxes_DefaultContentType
  );
  const [location, setLocation] = useState(localStorage.getItem("boxLocation") || "");
  const [room, setRoom] = useState(localStorage.getItem("boxRoom") || "");
  const [category, setCategory] = useState(localStorage.getItem("boxCategory") || "");
  const [boxState, setBoxState] = useState(localStorage.getItem("boxState") || strings.EditBoxes_DefaultBoxState);
  const [priority, setPriority] = useState(localStorage.getItem("boxPriority") || strings.EditBoxes_DefaultPriority);

  // const [storageDate, setStorageDate] = useState(""); // || new Date(new Date().getTime() + 90 * 86400 * 1000)); // TODO: When should date be set?
  const [fragility, setFragility] = useState(false);
  const [boxColor, setBoxColor] = useState("Green");

  // Static
  const [requireUserRemovalApproval, setRequireUserRemovalApproval] = useState(false);
  const [requireBankID, setRequireBankID] = useState(false);
  const [rentable, setRentable] = useState(false);
  const [itemType, setItemType] = useState("");
  const [returnDate, setReturnDate] = useState<Date>();
  const [returnNumDays, setReturnNumDays] = useState(3);
  const [rentMessToOwner, setRentMessToOwner] = useState("");
  const [rentCostDescr, setRentCostDescr] = useState("");
  const [enableRenterChatRent, setEnableRenterChatRent] = useState(false);
  const [boxStateIconFilename, setBoxStateIconFilename] = useState("");
  const [isBorrowedByMe, setIsBorrowedByMe] = useState(false);

  // Freezer
  const [foodType, setFoodType] = useState("");
  const [persons, setPersons] = useState(0);
  const [expirationDate, setExpirationDate] = useState<Date>(new Date(new Date().getTime() + 90 * 86400 * 1000));

  const [amount, setAmount] = useState(0);
  const [amountType, setAmountType] = useState("");

  const [changed, setChanged] = useState(false);

  const getCode = useCallback(() => {
    if (code.length > 0) {
      setBusy(true);
      Promise.all([
        Api.getCode(code)
          .then(setCodeInfo)
          .catch(() => Api.verifyCode(code).catch(() => {})),
        // Api.getStoresByCode(code).then(setStores),
        Api.getFiles(code).then(setImages),
        Api.hasCodeEditAccess(code).then(setHasCodeEditAccess),
      ]).catch(() => {});
      // .finally(() => setBusy(false));
    }
  }, [code]);

  useEffect(() => {
    if (code) {
      getCode();
    }
  }, [code, getCode]);

  useEffect(() => {
    if (images.length > 0) {
      setHasImage(true);
    }
  }, [images]);

  // useEffect(() => {
  //   setDefaultImageId(
  //     defaultImageIndex !== undefined && defaultImageIndex < images.length ? images[defaultImageIndex].id : undefined
  //   );
  //   setDefaultImage(
  //     defaultImageIndex !== undefined && defaultImageIndex < images.length ? images[defaultImageIndex] : undefined
  //   );
  // }, [defaultImageIndex, images]);

  useEffect(() => {
    if (codeInfo) {
      (async () => {
        try {
          setBusy(true);
          setState(codeInfo.state);
          setName(codeInfo.name || "");
          setType(codeInfo.type);
          setDescription(codeInfo.description || "");
          setShortId(codeInfo.shortID);
          setReturnDate(codeInfo.returnDate);
          const {
            showHowMuchPay,
            enablePosition,
            offerPay,
            possibleSendMess,
            showPhoneNumber,
            showInfoSendMess,
            showInfoOfferPay,
            showInfoShowHowMuchPay,

            // Boxes
            content,
            contentType,
            location,
            room,
            category,
            boxState,
            priority,
            storageDate,
            fragility,
            boxColor,

            // Static
            itemType,
            requireUserRemovalApproval,
            lostPayValue,

            // Freezer
            foodType,
            amount,
            amountType,
            persons,
            expirationDate,

            // Files (images)
            // defaultImageIndex, // TODO: remove => file index of default image
            defaultImageId, // file id of default image

            rentCostDescr,
            rentable,
          } = codeInfo.properties;

          setRequireUserRemovalApproval(requireUserRemovalApproval); // TODO: Check if this is both in properties and separate in DB
          setLostPayValue(lostPayValue);
          setShowHowMuchPay(showHowMuchPay);
          setEnablePosition(enablePosition);
          setOfferPay(offerPay);
          setPossibleSendMess(possibleSendMess);
          setShowPhoneNumber(showPhoneNumber);
          setShowInfoOfferPay(showInfoOfferPay);
          setShowInfoSendMess(showInfoSendMess);
          setShowInfoOfferPay(showInfoOfferPay);
          setShowInfoShowHowMuchPay(showInfoShowHowMuchPay);
          setContent(content);
          setContentType(contentType);
          setLocation(location);
          setRoom(room);
          setCategory(category);
          setBoxState(boxState);
          setPriority(priority);
          setStorageDate(storageDate);
          setFragility(fragility);
          setBoxColor(boxColor);
          setItemType(itemType);
          setReturnNumDays(+returnNumDays || 3);
          setRentMessToOwner(rentMessToOwner);
          // setDefaultImageIndex(defaultImageIndex || 0);
          setDefaultImageId(defaultImageId || images[0]?.id);
          setFoodType(foodType);
          setPersons(persons);
          setAmount(amount);
          setAmountType(amountType);
          setExpirationDate(expirationDate);
          setRentCostDescr(rentCostDescr);
          setRentable(rentable === true);

          // Users
          try {
            const userInfo = await Api.getUserInfo(codeInfo.userId, codeInfo.id);
            setOwnerUserId(userInfo.id);
            setOwnerUserName(userInfo.username);
            setOwnerPhone(userInfo.phone);
          } catch {
            if (type !== "lostFound") {
              throw new Error("Unautorized");
            }
          }

          const isMine = Helpers.isMine(codeInfo);
          const isRegistered = Helpers.isRegistered(codeInfo);
          const isBorrowed = Helpers.isBorrowed(codeInfo);
          const isAwaitingOwnerReturnAccept = Helpers.isAwaitingOwnerReturnAccept(codeInfo);
          const isBorrowedByMe = Helpers.isBorrowedByMe(codeInfo);
          setIsMine(isMine);
          setIsBorrowed(isBorrowed);
          setIsRegistered(isRegistered);
          setIsAwaitingOwnerReturnAccept(isAwaitingOwnerReturnAccept);
          setIsBorrowedByMe(isBorrowedByMe);

          // Status icon
          if (!codeInfo) {
            setStatusIconFilename("/ICON_Freezer_64.png");
          } else if (codeInfo.type === "freezer") {
            setStatusIconFilename("/ICON_Freezer_64.png");
          } else if (isRegistered) {
            if (isMine) {
              setStatusIconFilename("/ICON_Home_64.png");
            } else {
              setStatusIconFilename("/ICON_HomeAtOther_64.png");
            }
          } else {
            if (isBorrowed && isMine) {
              setStatusIconFilename("/ICON_MineBorrowedByOther_64.png");
            } else if (isAwaitingOwnerReturnAccept && isMine) {
              setStatusIconFilename("/ICON_MinePendingReturnAccept_64.png");
            } else if (isAwaitingOwnerReturnAccept && isMine) {
              setStatusIconFilename("/ICON_OthersPendingReturnAccept_64.png");
            } else {
              if (isBorrowed && !isBorrowedByMe) {
                setStatusIconFilename("/ICON_OtherBorrowedByOther_64.png");
              } else {
                setStatusIconFilename("/ICON_OthersBorrowedByMe_64.png");
              }
            }
          }

          if (boxState) {
            setBoxStateIconFilename(Helpers.getBoxStatusIconFilename(boxState));
          } else {
            // TODO: make explicit if for this case too, and add also when others have borrowed others
            setBoxStateIconFilename("/BoxOnly_White_32x32.png");
          }

          try {
            setBorrowerUserName(await Helpers.getBorrowerUserName(code));
          } catch {}

          // Stores
          try {
            setCodeStores(await Api.getStoresByCode(codeInfo.id));
          } catch {}
        } catch (e) {
          console.warn(e);
        } finally {
          setBusy(false);
          setChanged(false);
        }
      })();
    }
  }, [
    codeInfo,
    code,
    isAwaitingOwnerReturnAccept,
    isBorrowed,
    isMine,
    isRegistered,
    rentMessToOwner,
    returnNumDays,
    isBorrowedByMe,
  ]);

  useEffect(() => {
    if (defaultImageId && images) {
      setDefaultImage(images.find((image) => image.id === defaultImageId));
    }
  }, [defaultImageId, images]);

  const _save = async () => {
    setBusy(true);
    setChangeDate(new Date());
    setStorageDate(storageDate || new Date());

    const properties: CodeProps = {
      ...codeInfo!.properties,

      // Generic
      changeDate,

      // LostFound
      lostPayValue,
      showHowMuchPay,
      enablePosition,
      offerPay,
      possibleSendMess,
      showPhoneNumber,
      showInfoSendMess,
      showInfoOfferPay,
      showInfoShowHowMuchPay,

      // Boxes
      content,
      contentType,
      location,
      room,
      category,
      boxState,
      priority,
      storageDate,
      fragility,
      boxColor,

      // Static
      itemType,
      enableRenterChatRent,
      requireUserRemovalApproval,

      // Freezer
      foodType,
      persons,
      expirationDate,
      amount,
      amountType,

      // Files (images)
      // defaultImageIndex,
      defaultImageId,

      rentCostDescr,
      rentable,
    };

    const response = await Api.updateCode(
      code,
      name ? name.trim() : "",
      description ? description.trim() : "",
      properties,
      type || "static"
    );

    const ok = await Api.updateCodeStores(
      code,
      codeStores.map((s) => s.id)
    );

    setChanged(false);
    setBusy(false);

    if (response.success && ok) {
      if (onSaved) onSaved();
    } else {
      setError(strings.editItem_failedSaveCode);
    }
  };

  useEffect(() => {
    setChanged(true);
  }, [
    // General
    name,
    type,
    description,
    state,
    statusIconFilename,
    borrowerUserName,
    hasCodeEditAccess,
    // Files (images)
    images,
    defaultImageId,
    defaultImage,
    // LostFound
    lostPayValue,
    showHowMuchPay,
    enablePosition,
    offerPay,
    possibleSendMess,
    showPhoneNumber,
    showInfoOfferPay,
    showInfoSendMess,
    showInfoShowHowMuchPay,
    // Boxes
    shortId,
    content,
    contentType,
    location,
    room,
    category,
    boxState,
    priority,
    storageDate,
    fragility,
    boxColor,
    // Static
    requireUserRemovalApproval,
    requireBankID,
    rentable,
    itemType,
    returnDate,
    returnNumDays,
    rentMessToOwner,
    rentCostDescr,
    enableRenterChatRent,
    boxStateIconFilename,
    // Freezer
    foodType,
    persons,
    expirationDate,
    amount,
    amountType,
    // User
    ownerUserId,
    ownerUserName,
    ownerPhone,
    isMine,
    isBorrowed,
    isRegistered,
    isAwaitingOwnerReturnAccept,
    isBorrowedByMe,
    // Stores
    codeStores,
  ]);

  useEffect(() => {
    if (type && codeInfo && codeInfo.type !== type) {
      setCodeInfo({ ...codeInfo, type });
      _save();
    }
  }, [type, codeInfo, _save]);

  const _unregister = async () => {
    await Api.unregisterCode(code);
    if (onUnregistered) onUnregistered();
  };

  const _borrow = async () => {
    const borrower = Helpers.getCurrentUserId();
    const returnDate = Helpers.getDateWithDaysAdded(returnNumDays);
    await Api.registerBorrowing(code, borrower, returnDate);
    reload();
  };

  const reload = () => {
    // force refresh of code info
    setCode("");
    setCode(qrCode);
  };

  const _return = () => {
    Api.returnCode(code)
      .then(() => {
        reload();
      })
      .catch(() => {
        setError(strings.useObserveCode_ErroTryRefresh);
      });
  };

  const postMessage = (message: string) => {
    Api.postMessage({
      userId: ownerUserId,
      codeId: code,
      message: message,
      createdAt: new Date(),
    });
  };

  /** Adds an image to the code images */
  const addImage = () => {
    // Reload files for the code
    Api.getFiles(code).then(setImages);
  };

  const refreshImages = () => {
    // Reload files for the code
    Api.getFiles(code).then(setImages);
  };

  const canCopyCodeFromLocalSettings = useCallback(() => {
    const itemCopy = localStorage.getItem("itemCopy");
    if (!itemCopy) {
      return false;
    }

    const props = JSON.parse(itemCopy);
    if (props.type !== type) {
      return false;
    }

    return true;
  }, [type]);

  const copyCodeFromLocalSettings = () => {
    const text = localStorage.getItem("itemCopy");
    // eslint-disable-next-line no-restricted-globals
    if (text && confirm("Are you sure you want to overwrite the current item with the copied item?")) {
      // navigator.clipboard
      //   .readText()
      //   .then((text) => {
      const props = JSON.parse(text);
      setName(props.name);
      setDescription(props.description);
      setType(props.type);
      setImages(props.images);
      setDefaultImageId(props.defaultImageId);

      switch (props.type) {
        case "static":
          setRequireUserRemovalApproval(props.requireUserRemovalApproval);
          setRequireBankID(props.requireBankID);
          setRentable(props.rentable);
          setRentMessToOwner(props.rentMessToOwner);
          setRentCostDescr(props.rentCostDescr);
          setEnableRenterChatRent(props.enableRenterChatRent);
          setReturnDate(props.returnDate);
          setReturnNumDays(props.returnNumDays);
          break;
        case "boxes":
          setContent(props.content);
          setContentType(props.contentType);
          setLocation(props.location);
          setRoom(props.room);
          setCategory(props.category);
          setBoxState(props.boxState);
          setPriority(props.priority);
          setStorageDate(props.storageDate);
          setFragility(props.fragility);
          setBoxColor(props.boxColor);
          break;
        case "lostFound":
          setLostPayValue(props.lostPayValue);
          setShowHowMuchPay(props.showHowMuchPay);
          setEnablePosition(props.enablePosition);
          setOfferPay(props.offerPay);
          setPossibleSendMess(props.possibleSendMess);
          setShowPhoneNumber(props.showPhoneNumber);
          setShowInfoOfferPay(props.showInfoOfferPay);
          setShowInfoSendMess(props.showInfoSendMess);
          setShowInfoShowHowMuchPay(props.showInfoShowHowMuchPay);
          break;
        case "freezer":
          setFoodType(props.foodType);
          setPersons(props.persons);
          setExpirationDate(props.expirationDate);
          setAmount(props.amount);
          setAmountType(props.amountType);
          break;
        case "html":
          break;
      }
    }
  };

  const copyCodeToLocalSettings = () => {
    let props: any = {
      type,
      name,
      description,
      images,
      defaultImageId,
    };

    switch (type) {
      case "static":
        props = {
          ...props,
          requireUserRemovalApproval,
          requireBankID,
          rentable,
          rentMessToOwner,
          rentCostDescr,
          enableRenterChatRent,
          returnDate,
          returnNumDays,
        };
        break;
      case "boxes":
        props = {
          ...props,
          content,
          contentType,
          location,
          room,
          category,
          boxState,
          priority,
          storageDate,
          fragility,
          boxColor,
        };
        break;
      case "lostFound":
        props = {
          ...props,
          lostPayValue,
          showHowMuchPay,
          enablePosition,
          offerPay,
          possibleSendMess,
          showPhoneNumber,
          showInfoOfferPay,
          showInfoSendMess,
          showInfoShowHowMuchPay,
        };
        break;
      case "freezer":
        props = {
          ...props,
          foodType,
          persons,
          expirationDate,
          amount,
          amountType,
        };
        break;
      case "html":
        props = {
          ...props,
        };
        break;
    }

    const itemProperties = JSON.stringify(props);
    localStorage.setItem("itemCopy", itemProperties);
    // navigator.clipboard
    //   .writeText(itemProperties)
    //   .then(() => {
    //     console.log("Item properties copied to clipboard", props);
    //   })
    //   .catch((err) => {
    //     console.error("Error in copying item properties to clipboard: ", err);
    //   });
  };

  return {
    code: codeInfo /*, stores*/,
    busy,
    save: _save,
    unregister: _unregister,
    returnItem: _return,
    borrow: _borrow,
    postMessage,
    reload,

    // General
    name,
    setName,
    error,
    state,
    setState,
    type,
    setType,
    description,
    setDescription,
    changeDate,
    statusIconFilename,
    borrowerUserName,
    hasCodeEditAccess,

    // Files (images)
    images,
    // defaultImageIndex,
    // setDefaultImageIndex,
    addImage,
    refreshImages,
    defaultImageId,
    setDefaultImageId,
    defaultImage,

    // LostFound
    lostPayValue,
    setLostPayValue,
    showHowMuchPay,
    setShowHowMuchPay,
    enablePosition,
    setEnablePosition,
    offerPay,
    setOfferPay,
    possibleSendMess,
    setPossibleSendMess,
    showPhoneNumber,
    setShowPhoneNumber,
    showInfoOfferPay,
    setShowInfoOfferPay,
    showInfoSendMess,
    setShowInfoSendMess,
    showInfoShowHowMuchPay,
    setShowInfoShowHowMuchPay,

    // Boxes
    shortId,
    content,
    setContent,
    contentType,
    setContentType,
    location,
    setLocation,
    room,
    setRoom,
    category,
    setCategory,
    boxState,
    setBoxState,
    priority,
    setPriority,
    storageDate,
    setStorageDate,
    fragility,
    setFragility,
    boxColor,
    setBoxColor,

    //Static
    requireUserRemovalApproval,
    setRequireUserRemovalApproval,
    requireBankID,
    setRequireBankID,
    rentable,
    setRentable,
    itemType,
    setItemType,
    returnDate,
    setReturnDate,
    returnNumDays,
    setReturnNumDays,
    rentMessToOwner,
    setRentMessToOwner,
    rentCostDescr,
    setRentCostDescr,
    enableRenterChatRent,
    setEnableRenterChatRent,
    boxStateIconFilename,

    // Freezer
    foodType,
    setFoodType,
    persons,
    setPersons,
    expirationDate,
    setExpirationDate,
    amount,
    setAmount,
    amountType,
    setAmountType,

    // User
    ownerUserId,
    ownerUserName,
    ownerPhone,
    isMine,
    isBorrowed,
    isRegistered,
    isAwaitingOwnerReturnAccept,
    // borrowerUserName,
    isBorrowedByMe,

    // Stores
    setCodeStores,
    codeStores,

    // update: async (code: CodeInfo) => {
    //   setBusy(true);
    //   const {
    //     name,
    //     description,
    //     requireUserRemovalApproval,
    //     requireBankID,
    //     rentable,
    //   } = code;
    //   const response = await Api.registerCode(
    //     code.id,
    //     name.trim(),
    //     description.trim(),
    //     requireUserRemovalApproval,
    //     requireBankID,
    //     rentable,
    //     true /* TODO: lostFound*/,
    //     code.properties
    //   );
    //   // alert(JSON.stringify(response));
    //   setBusy(false);
    //   if (response.success) {
    //     onChanged();
    //   } else {
    //     setError(strings.registerItem_failedRegisterCode);
    //   }
    // },
    changed,
    copyCodeFromLocalSettings: canCopyCodeFromLocalSettings() ? copyCodeFromLocalSettings : undefined,
    copyCodeToLocalSettings,
  };
};
