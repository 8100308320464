import React from "react";
import { Language } from "./Strings";
import { EditBoxes, EditFreezer, EditHtml, EditLostFound, EditStatic, StackVert } from ".";
import { CodePropsType } from "../models";

const strings = Language.strings;

export const RegisterItem = ({
  code,
  type,
  onSaved,
  onChangeType,
}: {
  code: string;
  type: string;
  onSaved: () => void;
  onChangeType: (type: CodePropsType) => void;
}) => (
  <StackVert>
    {type === "freezer" && (
      <EditFreezer
        qrCode={code}
        header={strings.registerItem_freezerItemRegistration}
        onSaved={onSaved}
        onChangeType={onChangeType}
      />
    )}
    {type === "boxes" && (
      <EditBoxes
        qrCode={code}
        header={strings.registerItem_boxItemRegistration}
        onSaved={onSaved}
        onChangeType={onChangeType}
      />
    )}
    {type === "lostFound" && (
      <EditLostFound
        qrCode={code}
        header={strings.registerItem_lostFoundItemRegistration}
        onSaved={onSaved}
        onChangeType={onChangeType}
      />
    )}
    {type === "html" && (
      <EditHtml
        qrCode={code}
        header={strings.registerItem_messageTagItemRegistration}
        onSaved={onSaved}
        onChangeType={onChangeType}
      />
    )}
    {type === "static" && (
      <EditStatic
        qrCode={code}
        header={strings.registerItem_itemRegistration}
        onSaved={onSaved}
        onChangeType={onChangeType}
      />
    )}
  </StackVert>
);
