import { useEffect, useState } from "react";
import { CodeInfo, Store } from "../models";
import Api from "../api";
import { Language } from "../components/Strings";

const strings = Language.strings;

export const useObserveUser = () => {
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState<string>();
  const [userId, setUserId] = useState(localStorage.getItem("userId"));
  const [lock, setLock] = useState<boolean>();

  const [username, setUsername] = useState<string>();
  const [codes, setCodes] = useState<CodeInfo[]>();
  const [stores, setStores] = useState<Store[]>([]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  // const [purchases, setPurchases] = useState<Purchase[]>([]);

  const [tabChoices, setTabChoices] = useState<string[]>();
  const [contentTypeChoices, setContentTypeChoices] = useState(strings.EditBoxes_ContentTypeList);
  const [typeChoices, setTypeChoices] = useState(strings.EditStatic_ItemTypesList);
  const [contentChoices, setContentChoices] = useState(strings.EditBoxes_ContentList);
  const [locationChoices, setLocationChoices] = useState(strings.EditBoxes_SiteList);
  const [roomChoices, setRoomChoices] = useState(strings.EditBoxes_RoomList);
  const [categoryChoices, setCategoryChoices] = useState(strings.EditBoxes_CategoryList);

  const [enableSMRecievingOnIncommingMessages, setEnableSMRecievingOnIncommingMessages] = useState(true);
  const [disableShowReallyChangeImageToPrivate, setDisableShowReallyChangeImageToPrivate] = useState(true);

  // const [colorChoices, setColorChoices] = useState<string[]>(
  //   strings.EditBoxes_BoxColorChoices
  // );

  useEffect(() => {
    if (userId) {
      localStorage.setItem("userId", userId);
      (async () => {
        try {
          setLock(true);
          setBusy(true);
          const [codes, stores, props, info] = await Promise.all([
            Api.getCodesForUser(),
            Api.getStores(),
            Api.getPropertiesDict("user"),
            Api.getUserInfo(userId),
            // Api.getPurchases(userId)
          ]);
          setCodes(codes);
          setStores(stores);
          setUsername(info.username);
          setFirstName(info.firstName);
          setLastName(info.lastName);
          setAddress(info.address);
          setZipCode(info.zipCode);
          setCity(info.city);
          setCountry(info.country);
          setEmail(info.email);
          setPhone(info.phone);
          // setPurchases(purchases);

          if (props["tabChoicesJson"]) {
            setTabChoices(props["tabChoicesJson"]);
          } else {
            setTabChoices(["static", "boxes", "lostFound"]);
          }

          if (props["boxContentChoicesList"] && props["boxContentChoicesList"].length > 0) {
            setContentChoices(props["boxContentChoicesList"]);
          }

          if (props["boxContentTypeChoicesList"] && props["boxContentTypeChoicesList"].length > 0) {
            setContentTypeChoices(props["boxContentTypeChoicesList"]);
          }

          if (props["boxTypeChoicesList"] && props["boxTypeChoicesList"].length > 0) {
            setTypeChoices(props["boxTypeChoicesList"]);
          }

          if (props["boxRoomChoicesList"] && props["boxRoomChoicesList"].length > 0) {
            setRoomChoices(props["boxRoomChoicesList"]);
          }

          if (props["boxCategoryChoicesList"] && props["boxCategoryChoicesList"].length > 0) {
            setCategoryChoices(props["boxCategoryChoicesList"]);
          }

          // if (
          //   props["boxColorChoicesList"] &&
          //   props["boxColorChoicesList"].length > 0
          // ) {
          //   setColorChoices(props["boxColorChoicesList"]);
          // }

          if (props["boxLocationChoicesList"] && props["boxLocationChoicesList"].length > 0) {
            setLocationChoices(props["boxLocationChoicesList"]);
          }

          if (props["enableSMRecievingOnIncommingMessages"] !== undefined) {
            setEnableSMRecievingOnIncommingMessages(props["enableSMRecievingOnIncommingMessages"]);
          }

          if (props["disableShowReallyChangeImageToPrivate"] !== undefined) {
            setDisableShowReallyChangeImageToPrivate(props["disableShowReallyChangeImageToPrivate"]);
          }
        } catch (error) {
          setError(`Internal error: ${error}`);
        } finally {
          // try {
          //     setCodes(await Api.getCodesForUser());
          //   } catch {
          //     setError("Internal error: code retrieval failed");
          //   }

          //   try {
          //     setStores(await Api.getStores());
          //   } catch {
          //     setError("Internal error: store retrieval failed");
          //   }
          setBusy(false);
          setLock(false);
        }
      })();
    }
  }, [userId]);

  const onTabChoiceChange = async (tabChoices: string[]) => {
    await Api.setProperty("user", "tabChoicesJson", JSON.stringify(tabChoices));
    setTabChoices(tabChoices);
  };

  const onFirstNameChange = async (firstName: string) => {
    await Api.registerBuyerInfo(firstName, lastName, address, zipCode, city, country, email);
    setFirstName(firstName);
  };

  const onLastNameChange = async (lastName: string) => {
    await Api.registerBuyerInfo(firstName, lastName, address, zipCode, city, country, email);
    setLastName(lastName);
  };

  const onAddressChange = async (address: string) => {
    await Api.registerBuyerInfo(firstName, lastName, address, zipCode, city, country, email);
    setAddress(address);
  };

  const onZipCodeChange = async (zipCode: string) => {
    await Api.registerBuyerInfo(firstName, lastName, address, zipCode, city, country, email);
    setZipCode(zipCode);
  };

  const onCityChange = async (city: string) => {
    await Api.registerBuyerInfo(firstName, lastName, address, zipCode, city, country, email);
    setCity(city);
  };

  const onCountryChange = async (country: string) => {
    await Api.registerBuyerInfo(firstName, lastName, address, zipCode, city, country, email);
    setCountry(country);
  };

  const onEmailChange = async (email: string) => {
    await Api.registerBuyerInfo(firstName, lastName, address, zipCode, city, country, email);
    setEmail(email);
  };

  const onEnableSMRecievingOnIncommingMessagesChange = async (enableSMRecievingOnIncommingMessages: boolean) => {
    await Api.setProperty(
      "user",
      "enableSMRecievingOnIncommingMessages",
      JSON.stringify(enableSMRecievingOnIncommingMessages)
    );
    setEnableSMRecievingOnIncommingMessages(enableSMRecievingOnIncommingMessages);
  };

  useEffect(() => {
    if (lock === false) {
      setBusy(true);
      Api.setProperty(
        "user",
        "disableShowReallyChangeImageToPrivate",
        JSON.stringify(disableShowReallyChangeImageToPrivate)
      ).finally(() => setBusy(false));
    }
  }, [disableShowReallyChangeImageToPrivate, lock]);

  return {
    busy,
    error,
    codes,
    stores,
    setUserId,
    userId,
    typeChoices,
    contentTypeChoices,
    contentChoices,
    locationChoices,
    roomChoices,
    categoryChoices,
    setTypeChoices,
    setContentTypeChoices,
    setContentChoices,
    setLocationChoices,
    setRoomChoices,
    setCategoryChoices,
    tabChoices,
    onTabChoiceChange,
    username,
    enableSMRecievingOnIncommingMessages,
    onEnableSMRecievingOnIncommingMessagesChange,
    disableShowReallyChangeImageToPrivate,
    setDisableShowReallyChangeImageToPrivate,
    // purchases,

    firstName,
    lastName,
    address,
    city,
    zipCode,
    country,
    email,
    onFirstNameChange,
    onLastNameChange,
    onAddressChange,
    onCityChange,
    onZipCodeChange,
    onCountryChange,
    onEmailChange,
    phone,
  };
};
