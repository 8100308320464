import React, { useState } from "react";
import { Dialog, DialogContent, TextField, DialogFooter, PrimaryButton, DefaultButton } from "@fluentui/react";
import { strings } from "./Strings";

export const AccessAddUserDialog = ({
  hidden,
  onDismiss,
  onAddUser,
}: {
  hidden: boolean;
  onDismiss: () => void;
  onAddUser: (phone: string) => void;
}) => {
  const [phone, setPhone] = useState("");
  const close = () => {
    setPhone("");
    onDismiss();
  };

  return (
    <Dialog
      title={strings.accessAddUserDialog_addUser}
      dialogContentProps={{ subText: strings.accessAddUserDialog_subText }}
      hidden={hidden}
      onDismiss={close}
      modalProps={{ isBlocking: true }}
    >
      <DialogContent>
        <TextField
          label={strings.generic_phone}
          //styles={{ fieldGroup: { width: 100 } }}
          id="phone"
          type="tel"
          value={phone}
          onChange={(_e, newValue) => setPhone(newValue || "")}
          required={phone.trim().length === 0}
        />
      </DialogContent>
      <DialogFooter>
        <PrimaryButton
          onClick={() => {
            close();
            onAddUser(phone);
          }}
          text={strings.generic_add}
          disabled={phone.trim().length === 0}
        />
        <DefaultButton onClick={close} text={strings.generic_cancel} />
      </DialogFooter>
    </Dialog>
  );
};
