import React from 'react';
import { Header } from './Header';
import { StackVert } from './Controls';
import { Content } from './Content';
import { RegisterUser } from './RegisterUser';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

export const Register = withRouter((props: any) => {
    return <StackVert>
        <Header />
        <Content>
            <RegisterUser onRegister={(userId, token) => {
                localStorage.setItem('userId', userId); // TODO: remove, not needed since stored in token?
                localStorage.setItem('token', token);
                const { redirect } = queryString.parse(window.location.search);
                if (redirect) {
                    props.history.replace(redirect as string);
                } else {
                    props.history.replace('/');
                }
            }} />
        </Content>
    </StackVert>;
});