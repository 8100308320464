import React from 'react';
import { Route, Switch } from 'react-router';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { loadTheme } from '@fluentui/react';
import {
  Home,
  Layout,
  Admin,
  Privacy,
  About,
  Counter,
  Purchasing,
  ItemFound,
  Register,
  ConfigRent,
  ConfigLost,
  FetchData,
  RegisterItemManually,
  BuyTagsPage,
  ItemView,
  BuyPhysicalTags,
  BuyTagsPhysicalOnly,
  BuyDigitalTags,
  ListItemsHelp,
  Stores,
  EditStore,
  EditItem,
  UserSettings,
  ViewStore,
  Scan,
  JoinStore,
} from './components';
import './custom.css'
import { useTheme } from './hooks/useTheme';

initializeIcons(/* optional base url */);

export const App = () => {
  const { theme } = useTheme();

  loadTheme(theme);

  return (
    <Layout>
      <Switch>
        <Route exact path='/' component={Home} />
        <Route path='/admin' component={Admin} />
        <Route path='/privacy' component={Privacy} />
        <Route path='/about' component={About} />
        <Route path='/counter' component={Counter} />
        {/* <Route exact path='/buy' component={Purchasing} /> */}
        <Route path='/itemFound' component={ItemFound} />
        <Route path='/register' component={Register} />
        <Route path='/configRent' component={ConfigRent} />
        <Route path='/configLost' component={ConfigLost} />
        <Route path='/fetch-data' component={FetchData} />
        <Route path='/registerItemManually' component={RegisterItemManually} />
        <Route path='/buyTags' component={BuyTagsPage} />
        <Route path='/buy/1' component={BuyTagsPhysicalOnly} />
        <Route path='/buy/2' component={BuyPhysicalTags} />
        <Route path='/buy/3' component={BuyDigitalTags} />
        <Route path='/buy/4' component={Purchasing} />
        <Route path='/listItemsHelp' component={ListItemsHelp} />
        <Route path='/scan' component={Scan} />
        <Route path='/stores' component={Stores} />
        <Route path='/store/:id' component={ViewStore} />
        <Route path='/editstore/:id' component={EditStore} />
        <Route path='/joinstore/:id' component={JoinStore} />
        <Route path='/settings' component={UserSettings} />
        <Route path='/edit/:code' component={EditItem} />
        <Route path='/:code' component={ItemView} />
      </Switch>
    </Layout>
  );
}
