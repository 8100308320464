import React from "react";
import { Panel } from "@fluentui/react";
import { TabSettings } from "./TabSettings";
import { strings } from "./Strings";
import { ParagraphText } from "./Controls";

export const TabSettingsPanel = ({
  isOpen,
  onDismiss,
  tabChoices,
  onTabChoiceChange,
}: {
  isOpen: boolean;
  onDismiss: () => void;
  tabChoices: string[];
  onTabChoiceChange: (tabChoices: string[]) => Promise<void>;
}) => {
  return (
    <Panel isOpen={isOpen} isLightDismiss onDismiss={onDismiss} headerText={strings.userSettings_manageViewTabs}>
      <ParagraphText>{strings.userSettings_ChooseWhichTabsToShow}</ParagraphText>
      <TabSettings tabChoices={tabChoices} onTabChoiceChange={onTabChoiceChange} />
    </Panel>
  );
};
