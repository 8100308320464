import React, { useState } from "react";
import { ComboBox, DefaultButton, Dialog, DialogContent, DialogFooter, IComboBoxProps, PrimaryButton, Stack } from "@fluentui/react";
import {
  CodeShowAndLinkToQRImage,
  ItemInfoStacked,
  ParagraphLabel,
  ParagraphText,
  ShowList,
  StackHorizontal,
  StackVert,
} from "./Controls";
import { Language } from "./Strings";
import { useObserveCode } from "../hooks/useObserveCode";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { TitleWithInfo } from "./TitleWithInfo";
import { ListMessages } from "./ListMessages";
import { Helpers } from "../helpers";
import { ImagesPanel } from "./ImagesPanel";
import { ItemFooterEdit } from "./ItemFooterEdit";

const strings = Language.strings;

interface HandleItemBorrowedByMeProps extends RouteComponentProps {
  code: string;
  onReturnItem: () => void;
}

export const HandleItemBorrowedByMe = withRouter(({ history, code, onReturnItem }: HandleItemBorrowedByMeProps) => {
  const {
    name,
    description,
    itemType,
    returnNumDays,
    setReturnNumDays,
    returnDate,
    rentable,
    codeStores,
    rentCostDescr,
    statusIconFilename,
    state,
    defaultImageId,
    setDefaultImageId,
    images,
    hasCodeEditAccess,
    category,
  } = useObserveCode({ code: code, onSaved: () => history.goBack() });


  const [numDaysToAdd, setNumDaysToAdd] = useState(7);
  const [showExtendDialog, setShowExtendDialog] = useState(false);
  // const [numDaysToAdd, setNumDaysToAdd] = useState(7);
  const returnDateList = [
    { key: 1, text: strings.handleItemBorrowedByMe_oneDay },
    { key: 3, text: strings.handleItemBorrowedByMe_threDays },
    { key: 7, text: strings.handleItemBorrowedByMe_oneWeek },
    { key: 30, text: strings.handleItemBorrowedByMe_onemonth },
    { key: 90, text: strings.handleItemBorrowedByMe_threeMonth },
    { key: 365, text: strings.handleItemBorrowedByMe_oneYear },
    { key: 3650, text: strings.handleItemBorrowedByMe_noEndDate },
  ];


  const _onChange: IComboBoxProps["onChange"] = (event, option) => {
    if (option) {
      setNumDaysToAdd(+option.key);
    }
  };


  return (
    <StackVert>
      <TitleWithInfo
        title={name ? name : ""} //strings.handleItemBorrowedByMe_YouBorrowedThis +
        iconFileName={statusIconFilename}
        infoTitle={strings.handleItemBorrowedByMe_infoHeader}
        code={code}
      >
        <ul>
          <li>{strings.handleItemBorrowedByMe_Info1}</li>
          <li>{strings.handleItemBorrowedByMe_Info2}</li>
          <li>{strings.handleItemBorrowedByMe_Info3}</li>
        </ul>
      </TitleWithInfo>
      <ItemInfoStacked
        header={strings.handleBorrowingItem_TypeOfItem}
        content={itemType ? itemType : strings.generic_noTypeSet}
      ></ItemInfoStacked>
      <ItemInfoStacked header={strings.item_status} content={Helpers.stateToString(state, true)}></ItemInfoStacked>
      <ItemInfoStacked header={strings.handleBorrowingItem_ItemName} content={name}></ItemInfoStacked>
      <ItemInfoStacked header={strings.handleBorrowingItem_Category} content={category}></ItemInfoStacked>
      <ItemInfoStacked
        header={strings.handleItemBorrowedByMe_ShouldBeReturnedBy}
        content={returnDate ? returnDate.toLocaleDateString() : ""}
      ></ItemInfoStacked>
      <StackHorizontal>
        <ItemInfoStacked
          header={strings.handleItemBorrowedByMe_ReturnIn}
          content={returnNumDays + " " + strings.handleItemBorrowedByMe_Days}
        ></ItemInfoStacked>
        <Stack.Item grow>
          <PrimaryButton onClick={() => setShowExtendDialog(true)}>{strings.handleItemBorrowedByMe_extend}</PrimaryButton>
        </Stack.Item>
      </StackHorizontal>
      {description && description !== "-" && (
        <Stack.Item>
          <ParagraphLabel>{strings.generic_description}</ParagraphLabel>
          <ParagraphText>{description}</ParagraphText>
        </Stack.Item>
      )}
      {rentable && rentCostDescr && rentCostDescr !== "-" && (
        <Stack.Item>
          <ParagraphLabel>{strings.registerItem_rentInfo}</ParagraphLabel>
          <ParagraphText>{rentCostDescr}</ParagraphText>
        </Stack.Item>
      )}
      {/* {rentable && (<ItemInfoStacked header={strings.handleItemBorrowedByMe_RentCost} content={rentCostDescr} ></ItemInfoStacked>)} */}
      <Stack.Item>
        <ParagraphLabel>{strings.item_photo}</ParagraphLabel>
        <ImagesPanel
          viewMode
          defaultImageId={defaultImageId}
          setDefaultImageId={setDefaultImageId}
          code={code}
          images={images}
        />
      </Stack.Item>
      {/* <ItemInfoStacked header={strings.generic_QRCode} content={code} ></ItemInfoStacked> */}
      <CodeShowAndLinkToQRImage code={code}></CodeShowAndLinkToQRImage>
      <ShowList header={strings.generic_ListsShow} codesLists={codeStores}></ShowList>
      <ListMessages code={code} showSendMessage={true} />
      {/* TODO: Make separate ItemFooter that takes a function in? for HandleItems... */}
      <Stack.Item>
        <StackHorizontal>
          <Stack.Item grow>
            <PrimaryButton onClick={onReturnItem}>{strings.handleItemBorrowedByOther_ReportReturned}</PrimaryButton>
          </Stack.Item>
          {!hasCodeEditAccess && (
            <Stack.Item align="end">
              <PrimaryButton onClick={() => history.goBack()}>{strings.generic_close}</PrimaryButton>
            </Stack.Item>
          )}
          {hasCodeEditAccess && <ItemFooterEdit code={code} />}
        </StackHorizontal>
      </Stack.Item>
      <Dialog
        hidden={!showExtendDialog}
        title={strings.handleItemBorrowedByMe_extend}
        onDismiss={() => setShowExtendDialog(false)}
        modalProps={{ isBlocking: false }}
      >

        <DialogContent>
          <Stack.Item>
            <ComboBox
              style={{ maxWidth: 200 }}
              // TODO: Update props with the selection
              // TODO: Update the CodeLog with the ReturnDate, based on today and the choice here
              // defaultSelectedKey="3"
              // selectedKey={returnNumDays}
              // defaultSelectedKey={"7"}
              defaultValue={"7"}
              options={returnDateList}
              label={strings.handleItemBorrowedByMe_addNumDays}
              onChange={_onChange}
            // Possibly add a datum chooser?
            />
          </Stack.Item>
        </DialogContent>
        <DialogFooter>
          <PrimaryButton onClick={() => {
            const currentReturnDate = returnDate || new Date();
            currentReturnDate.setDate(currentReturnDate.getDate() + numDaysToAdd);
            setReturnNumDays(returnNumDays + numDaysToAdd)
            setShowExtendDialog(false)
            // @Johan: How do we make it save the extension? 
          }}
            text={strings.handleItemBorrowedByMe_extend} />
          <DefaultButton onClick={() => {
            setShowExtendDialog(false)
          }} text={strings.generic_close} />
        </DialogFooter>
      </Dialog>

    </StackVert>
  );

});
