import React from "react";
import { IColumn } from "@fluentui/react";
import { CodeInfo } from "../models";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { Helpers } from "../helpers";
import { ItemImage } from "./ItemImage";
import { useObserveStore, useObserveStrings } from "../hooks";
import { ItemsView } from "./ItemsView";

const classNames = mergeStyleSets({
  fileIconHeaderIcon: {
    padding: 0,
    fontSize: "16px",
  },
  fileIconCell: {
    textAlign: "center",
    selectors: {
      "&:before": {
        content: ".",
        display: "inline-block",
        verticalAlign: "middle",
        height: "100%",
        width: "0px",
        visibility: "hidden",
      },
    },
  },
  fileIconImg: {
    verticalAlign: "middle",
    maxHeight: "16px",
    maxWidth: "16px",
  },
  controlWrapper: {
    display: "flex",
    flexWrap: "wrap",
  },
  exampleToggle: {
    display: "inline-block",
    marginBottom: "10px",
    marginRight: "30px",
  },
  selectionDetails: {
    marginBottom: "20px",
  },
});

interface ListStoreItemsProps {
  storeId: string;
  onSelected: (code: string) => void;
  busy: boolean;
  viewDetailedList: boolean;
}

export const ListStoreItems = (props: ListStoreItemsProps) => {
  const { storeId, busy, viewDetailedList } = props;
  const strings = useObserveStrings();
  const { storeCodes } = useObserveStore({ storeId, onSaved: () => {}, onRemoved: () => {} });
  const onItemInvoked = (item?: CodeInfo) => {
    if (item) {
      props.onSelected(item.id);
    }
  };

  const columns: IColumn[] = [
    {
      key: "column1",
      name: "File Type",
      className: classNames.fileIconCell,
      iconClassName: classNames.fileIconHeaderIcon,
      ariaLabel: "Column operations for File type, Press to sort on File type",
      isIconOnly: true,
      fieldName: "name",
      minWidth: 24,
      maxWidth: 24,
      onRender: (item: CodeInfo) => {
        const statusIconFilename = Helpers.getStatusIconFilename(item);
        return <img src={statusIconFilename} className={classNames.fileIconImg} alt="State" />;
      },
    },
    {
      key: "column2",
      name: strings.generic_name,
      fieldName: "name",
      minWidth: 80,
      maxWidth: 110,
      isRowHeader: true,
      isResizable: true,
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      data: "string",
      isPadded: true,
    },
    {
      key: "column4",
      name: strings.generic_description,
      fieldName: "description",
      minWidth: 120,
      maxWidth: 200,
      isResizable: true,
      isCollapsible: true,
      data: "string",
      isPadded: true,
    },
    {
      key: "column5",
      name: strings.listItems_photo,
      fieldName: "photo",
      minWidth: 120,
      maxWidth: 200,
      onRender: (item: CodeInfo) => {
        //   if (item.properties.type !== "freezer") {
        const id = item.properties ? item.properties["defaultImageId"] || "" : "";
        return <ItemImage id={id} isSmall />;
        //   } else {
        //     return <></>;
        //   }
      },
      isPadded: true,
    },
  ];

  return (
    <ItemsView
      items={storeCodes}
      busy={busy}
      columns={columns}
      viewList={viewDetailedList}
      onItemInvoked={onItemInvoked}
    />
  );
};
