import React, { useEffect, useState } from "react";
import { Stack, MessageBar, MessageBarType } from "@fluentui/react";
import { StackVert, ParagraphText, ShowList, CodeShowAndLinkToQRImage } from "./Controls";
import { strings } from "./Strings";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ParagraphLabel } from ".";
import { TitleWithInfo } from "./TitleWithInfo";
import { ItemFooterEdit } from "./ItemFooterEdit";
import { useObserveCode } from "../hooks/useObserveCode";
import { ImagesPanel } from "./ImagesPanel";

interface HandleOwnHtmlProps extends RouteComponentProps {
  code: string;
}

export const HandleOwnHtml = withRouter(({ history, code }: HandleOwnHtmlProps) => {
  const { description, codeStores, images, defaultImageId, setDefaultImageId } = useObserveCode({
    code,
    onSaved: () => history.goBack(),
  });

  const [error, setError] = useState(undefined);
  const [isHtml, setIsHtml] = useState(false);

  useEffect(() => {
    const hasHtmlTags = description.includes("<") && description.includes(">") && description.includes("/");
    setIsHtml(hasHtmlTags);
  }, [description]);

  return (
    <StackVert>
      <Stack.Item>
        {error && (
          <MessageBar messageBarType={MessageBarType.error} onDismiss={() => setError(undefined)}>
            {error}
          </MessageBar>
        )}
      </Stack.Item>
      <TitleWithInfo
        title={strings.handleHtmlItem_PageHeader}
        iconFileName="FileHTML"
        infoTitle={strings.handleOwnHtml_infoHeader}
        code={code}
      >
        <ParagraphLabel>{strings.generic_PageInfo}</ParagraphLabel>
        <ul>
          <li>{strings.handleOwnHtml_info1}</li>
          <li>{strings.handleOwnHtml_info2}</li>
          <li>{strings.handleOwnHtml_info3}</li>
          <li>{strings.handleOwnHtml_info4}</li>
          <li>{strings.handleOwnHtml_info5}</li>
          <li>{strings.handleOwnHtml_info6}</li>
        </ul>
      </TitleWithInfo>
      <Stack.Item>
        {/* <ParagraphLabel>{strings.handleOwnHtml_TextOrHTML}</ParagraphLabel> */}
        <ParagraphText>
          {isHtml && <div dangerouslySetInnerHTML={{ __html: description }}></div>}
          {!isHtml && <span>{description}</span>}
        </ParagraphText>
        {images.length > 0 && (
          <Stack.Item>
            {/* <ParagraphLabel>{strings.item_photo}</ParagraphLabel> */}
            <ImagesPanel
              viewMode
              defaultImageId={defaultImageId}
              setDefaultImageId={setDefaultImageId}
              code={code}
              images={images}
            />
          </Stack.Item>
        )}
      </Stack.Item>
      <CodeShowAndLinkToQRImage code={code}></CodeShowAndLinkToQRImage>
      <ShowList header={strings.generic_ListsShow} codesLists={codeStores}></ShowList>
      <ItemFooterEdit code={code} />
    </StackVert>
  );
});
