import {
  DefaultButton,
  Dialog,
  DialogFooter,
  IconButton,
  Modal,
  PrimaryButton,
  Stack,
  TextField,
} from "@fluentui/react";
import React, { useState } from "react";
import { ParagraphTitle } from "./Controls";
import { strings } from "./Strings";

export const EditTypeModal = ({
  title,
  show,
  onDismiss,
  value,
  onChange,
}: {
  title: string;
  show: boolean;
  onDismiss: () => void;
  value: string[];
  onChange: (value: string[]) => void;
}) => {
  const [showAdd, setShowAdd] = useState(false);
  const [name, setName] = useState("");

  const add = () => {
    setShowAdd(false);
    onChange([...value, name]);
    setName("");
  };

  const dismiss = () => {
    setName("");
    setShowAdd(false);
  };

  const removeItem = (item: string) => {
    //  if (window.confirm("Remove " + item + "?")) {
    onChange(value.filter((x) => x !== item));
    //  }
  };

  const valid = !value.some((v) => v === name.trim()) && name.trim().length > 0;

  return (
    <Modal
      titleAriaId={title}
      isOpen={show}
      onDismiss={onDismiss}
      isBlocking={false}
      styles={{ main: { padding: 16 } }}
    >
      <ParagraphTitle>{title}</ParagraphTitle>
      <ul>
        {value.map((v, i) => (
          <li key={`value${i}`}>
            {v}{" "}
            <IconButton
              onClick={() => removeItem(v)}
              iconProps={{ iconName: "Delete" }}
              title={strings.editTypeModal_Delete}
              ariaLabel={strings.editTypeModal_Delete}
            />
          </li>
        ))}
      </ul>
      <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 10 }}>
        <PrimaryButton onClick={() => setShowAdd(true)}>{strings.editTypeModal_Add}</PrimaryButton>
        <DefaultButton onClick={onDismiss} text={strings.generic_cancel} />
      </Stack>
      <Dialog hidden={!showAdd} onDismiss={dismiss} modalProps={{ isBlocking: true }}>
        <TextField
          value={name}
          styles={{ fieldGroup: { width: 100 } }}
          onChange={(_e, newValue) => setName(newValue || "")}
          autoComplete="on"
          onFocus={({ target }) => target.select()}
          autoFocus
          required={!valid}
        />
        <DialogFooter>
          <PrimaryButton onClick={add} disabled={!valid} text={strings.editTypeModal_Add} />
          <DefaultButton onClick={dismiss} text={strings.generic_close} />
        </DialogFooter>
      </Dialog>
    </Modal>
  );
};
