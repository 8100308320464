import React, { useState } from "react";
import { Stack, Panel, IconButton } from "@fluentui/react";
import { FeedbackForm } from "./FeedbackForm";

export const ShowInfoOnly = ({ infoTitle, children }: { infoTitle: string; children: any }) => {
  const [showHelp, setShowHelp] = useState(false);

  return (
    <Stack.Item>
      <IconButton iconProps={{ iconName: "Info" }} onClick={() => setShowHelp(true)} />
      <Panel headerText={infoTitle} isOpen={showHelp} onDismiss={() => setShowHelp(false)} closeButtonAriaLabel="Close">
        {children}
        <FeedbackForm extra="" />
      </Panel>
    </Stack.Item>
  );
};
