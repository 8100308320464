import { Stack } from "@fluentui/react";
import {
  StackVert,
  ParagraphText,
  ItemInfoStacked,
  ParagraphLabel,
  ShowList,
  CodeShowAndLinkToQRImage,
} from "./Controls";
import { Language } from "./Strings";
import { RouteComponentProps, withRouter } from "react-router-dom";
import React from "react";
import { ItemFooterEdit } from "./ItemFooterEdit";
import { useObserveCode } from "../hooks/useObserveCode";
import { TitleWithInfo } from "./TitleWithInfo";
import { ListMessages } from "./ListMessages";
import { ShowWhatBoxIconsMean } from "./ShowWhatBoxIconsMean";

const strings = Language.strings;

interface HandleOthersBoxesProps extends RouteComponentProps {
  code: string;
}

export const HandleOthersBoxes = withRouter(({ history, code }: HandleOthersBoxesProps) => {
  const {
    ownerUserName,
    contentType,
    location,
    content,
    description,
    room,
    fragility,
    priority,
    boxColor,
    boxState,
    codeStores,
    storageDate,
    shortId,
    boxStateIconFilename,
    hasCodeEditAccess,
  } = useObserveCode({ code, onSaved: () => history.goBack() });

  return (
    <StackVert>
      <TitleWithInfo
        title={(shortId ? shortId : "")}
        iconFileName={boxStateIconFilename}
        infoTitle={strings.handleOthersBoxes}
        code={code}
      >
        <ul>
          <li>{strings.handleOthersBoxes_Info1}</li>
          <li>{strings.handleOthersBoxes_Info2}</li>
          <li>{strings.handleOthersBoxes_Info3}</li>
        </ul>
        <Stack.Item>
          <ShowWhatBoxIconsMean />
        </Stack.Item>
      </TitleWithInfo>
      {/* {name && <ItemInfoStacked header={strings.EditBoxes_BoxName} content={name}></ItemInfoStacked>}
                <ItemInfoStacked header={strings.handleBoxes_ShortID} content={shortId}></ItemInfoStacked> */}
      <ItemInfoStacked header={strings.handleBoxes_Owner} content={ownerUserName}></ItemInfoStacked>
      <Stack.Item>
        <StackVert tokens={{ childrenGap: 10 }}>
          <Stack.Item>
            <ItemInfoStacked header={strings.handleBoxes_ContentType} content={contentType}></ItemInfoStacked>
            <ItemInfoStacked header={strings.handleBoxes_Content} content={content}></ItemInfoStacked>
            {description && (
              <Stack.Item>
                <Stack.Item>
                  <ParagraphLabel> {strings.handleBoxes_Description}</ParagraphLabel>
                </Stack.Item>
                <Stack.Item>
                  <ParagraphText>{description} </ParagraphText>
                </Stack.Item>
              </Stack.Item>
            )}
            <ItemInfoStacked header={strings.handleBoxes_Location} content={location}></ItemInfoStacked>
            <ItemInfoStacked header={strings.handleBoxes_Room} content={room}></ItemInfoStacked>
            <ItemInfoStacked
              header={strings.handleBoxes_Fragility + ": "}
              content={fragility ? strings.generic_no : strings.generic_yes}
            ></ItemInfoStacked>
            <ItemInfoStacked header={strings.handleBoxes_Priority} content={priority}></ItemInfoStacked>
            <ItemInfoStacked header={strings.handleBoxes_BoxColor} content={boxColor}></ItemInfoStacked>
            <ItemInfoStacked header={strings.handleBoxes_BoxState} content={boxState}></ItemInfoStacked>
            <ItemInfoStacked
              header={strings.handleBoxes_StorageDate}
              content={storageDate ? storageDate.toLocaleDateString() : ""}
            ></ItemInfoStacked>
            <CodeShowAndLinkToQRImage code={code}></CodeShowAndLinkToQRImage>
            <ShowList header={strings.generic_ListsShow} codesLists={codeStores}></ShowList>
            <ListMessages code={code} showSendMessage={true} />
          </Stack.Item>
          {hasCodeEditAccess && <ItemFooterEdit code={code} />}
        </StackVert>
      </Stack.Item>
    </StackVert>
  );
});
