import React from "react";
import { EditFreezer, EditBoxes, EditLostFound, EditHtml, EditStatic, Content, Header, StackVert } from ".";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import { useObserveCode } from "../hooks/useObserveCode";
import { Language } from "./Strings";
import { CodePropsType } from "../models";

const strings = Language.strings;

interface EditItemProps extends RouteComponentProps {
  code: string;
}

export const EditItem = withRouter(({ code, match }: EditItemProps) => {
  //@ts-ignore
  const qrCode = match.params.code || code;
  const { type, setType, hasCodeEditAccess } = useObserveCode({ code: qrCode, onSaved: () => {} });
  const history = useHistory();

  const onSaved = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.replace("/");
    }
  };

  const onChangeType = (type: CodePropsType) => {
    setType(type);
    history.replace("/edit/" + qrCode);
  };

  return (
    <>
      {hasCodeEditAccess && (
        <StackVert>
          <Header />
          <Content>
            {qrCode && (
              <>
                {type === "freezer" && (
                  <EditFreezer
                    qrCode={qrCode}
                    header={strings.freezer_PageHeaderEdit}
                    onSaved={onSaved}
                    onChangeType={onChangeType}
                  />
                )}
                {type === "boxes" && (
                  <EditBoxes
                    qrCode={qrCode}
                    header={strings.handleOwnBoxes_PageHeaderEdit}
                    onSaved={onSaved}
                    onChangeType={onChangeType}
                  />
                )}
                {type === "lostFound" && (
                  <EditLostFound
                    qrCode={qrCode}
                    header={strings.editLostFound_settingsLost}
                    onSaved={onSaved}
                    onChangeType={onChangeType}
                  />
                )}
                {type === "html" && (
                  <EditHtml
                    qrCode={qrCode}
                    header={strings.editHtml_PageHeaderEdit}
                    onSaved={onSaved}
                    onChangeType={onChangeType}
                  />
                )}
                {type === "static" && (
                  <EditStatic
                    qrCode={qrCode}
                    header={strings.editItem_PageHeaderEdit}
                    onSaved={onSaved}
                    onChangeType={onChangeType}
                  />
                )}
              </>
            )}
          </Content>
        </StackVert>
      )}
    </>
  );
});
