import React from "react";
import {
  Stack,
  FocusZone,
  IColumn,
  SelectionMode,
  DetailsListLayoutMode,
  ShimmeredDetailsList,
  mergeStyleSets,
} from "@fluentui/react";
import { CodeInfo, Store } from "../models";
import { Language } from "./Strings";
import { Helpers } from "../helpers";
import { StoreContextualMenu } from "./StoreContextualMenu";

const strings = Language.strings;

const classNames = mergeStyleSets({
  fileIconHeaderIcon: {
    padding: 0,
    fontSize: "16px",
  },
  fileIconCell: {
    textAlign: "center",
    selectors: {
      "&:before": {
        content: ".",
        display: "inline-block",
        verticalAlign: "middle",
        height: "100%",
        width: "0px",
        visibility: "hidden",
      },
    },
  },
  fileIconImg: {
    verticalAlign: "middle",
    maxHeight: "16px",
    maxWidth: "16px",
  },
});

interface ListStoresProps {
  items: Store[];
  onSelected: (store: Store) => void;
  busy: boolean;
}

export const ListStores = (props: ListStoresProps) => {
  const columns: IColumn[] = [
    {
      key: "column1",
      name: "File Type",
      className: classNames.fileIconCell,
      iconClassName: classNames.fileIconHeaderIcon,
      ariaLabel: "Column operations for File type, Press to sort on File type",
      isIconOnly: true,
      fieldName: "name",
      minWidth: 24,
      maxWidth: 24,
      onRender: (item: CodeInfo) => {
        const statusIconFilename = Helpers.getStatusIconFilename(item);
        return <img src={statusIconFilename} className={classNames.fileIconImg} alt="State" />;
      },
    },
    {
      key: "column2",
      name: strings.generic_name,
      fieldName: "name",
      minWidth: 80,
      maxWidth: 110,
      isRowHeader: true,
      isResizable: true,
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      data: "string",
      isPadded: true,
      onRender: (item: Store) => {
        return <span onClick={() => props.onSelected(item)}>{item.name}</span>;
      },
    },
    {
      key: "column4",
      name: strings.generic_description,
      fieldName: "description",
      minWidth: 120,
      maxWidth: 200,
      isResizable: true,
      isCollapsible: true,
      data: "string",
      isPadded: true,
      onRender: (item: Store) => {
        return <span onClick={() => props.onSelected(item)}>{item.name}</span>;
      },
    },
    {
      key: "column6",
      name: "share",
      className: classNames.fileIconCell,
      iconClassName: classNames.fileIconHeaderIcon,
      ariaLabel: "Column operations for share list",
      // iconName: 'Page',
      isIconOnly: true,
      fieldName: "share",
      minWidth: 24,
      maxWidth: 24,
      onRender: (item: Store) => {
        return <StoreContextualMenu store={item} />;
      },
    },
  ];

  const { items, busy } = props;

  return (
    <Stack tokens={{ childrenGap: 15 }}>
      <Stack>
        <FocusZone>
          <ShimmeredDetailsList
            items={items}
            enableShimmer={busy}
            ariaLabelForShimmer={"strings.ListStores_loading"}
            compact={false}
            columns={columns}
            selectionMode={SelectionMode.none}
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
          />
        </FocusZone>
      </Stack>
    </Stack>
  );
};
