import React from "react";
import { IColumn } from "@fluentui/react";
import { CodeInfo } from "../models";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { Helpers } from "../helpers";
import { Language } from "./Strings";
import { ItemImage } from "./ItemImage";
import { ItemsView } from "./ItemsView";
import { ParagraphText } from "./Controls";

const strings = Language.strings;

const classNames = mergeStyleSets({
  fileIconHeaderIcon: {
    padding: 0,
    fontSize: "16px",
  },
  fileIconCell: {
    textAlign: "center",
    selectors: {
      "&:before": {
        content: ".",
        display: "inline-block",
        verticalAlign: "middle",
        height: "100%",
        width: "0px",
        visibility: "hidden",
      },
    },
  },
  fileIconImg: {
    verticalAlign: "middle",
    maxHeight: "16px",
    maxWidth: "16px",
  },
  controlWrapper: {
    display: "flex",
    flexWrap: "wrap",
  },
  exampleToggle: {
    display: "inline-block",
    marginBottom: "10px",
    marginRight: "30px",
  },
  selectionDetails: {
    marginBottom: "20px",
  },
});

interface ListBoxesProps {
  items: CodeInfo[];
  onSelected: (code: string) => void;
  busy: boolean;
  viewDetailedList: boolean;
}

interface ListBoxesState {
  columns: IColumn[];
}

export class ListBoxes extends React.Component<ListBoxesProps, ListBoxesState> {
  constructor(props: ListBoxesProps) {
    super(props);

    const columns: IColumn[] = [
      {
        key: "column1",
        name: "File Type",
        className: classNames.fileIconCell,
        iconClassName: classNames.fileIconHeaderIcon,
        ariaLabel: "",
        // iconName: 'Page',
        isIconOnly: true,
        fieldName: "file",
        minWidth: 24,
        maxWidth: 24,
        onRender: (item: CodeInfo) => {
          const statusIconFilename = Helpers.getBoxStatusIconFilename(item.properties.boxState);
          return <img src={statusIconFilename} className={classNames.fileIconImg} alt="State" />;
        },
      },
      {
        key: "column2",
        name: strings.listBoxes_TagID,
        fieldName: "short",
        minWidth: 17,
        maxWidth: 17,
        isRowHeader: true,
        isResizable: true,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        data: "string",
        isPadded: true,
        onRender: (item: CodeInfo) => {
          return <ParagraphText>{item.shortID ? item.shortID : ""}</ParagraphText>;
        },
      },
      {
        key: "column3",
        name: strings.listBoxes_Content,
        fieldName: "Content",
        minWidth: 30,
        maxWidth: 50,
        isRowHeader: true,
        isResizable: true,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        data: "string",
        isPadded: true,
        onRender: (item: CodeInfo) => {
          return <ParagraphText>{item.properties.content ? item.properties.content : ""}</ParagraphText>;
        },
      },
      // {
      //   key: "column4",
      //   name: strings.listBoxes_Location,
      //   fieldName: "location",
      //   minWidth: 60,
      //   maxWidth: 100,
      //   isResizable: true,
      //   isCollapsible: true,
      //   data: "string",
      //   isPadded: true,
      //   onRender: (item: CodeInfo) => {
      //     return <ParagraphText>{item.properties.location ? item.properties.location : ""}</ParagraphText>;
      //   },
      // },
      // {
      //   key: "column5",
      //   name: strings.listBoxes_Stored,
      //   fieldName: "storedAt",
      //   minWidth: 70,
      //   maxWidth: 90,
      //   isResizable: true,
      //   isCollapsible: true,
      //   data: "number",
      //   isPadded: true,
      //   onRender: (item: CodeInfo) => <span>{new Date(Date.parse(item.createdAt)).toLocaleDateString()}</span>,
      // },
      {
        key: "column6",
        name: strings.listBoxes_Photo,
        fieldName: "photo",
        minWidth: 120,
        maxWidth: 200,
        // isResizable: true,
        // isCollapsible: true,
        // data: 'string',
        // onColumnClick: this._onColumnClick,
        onRender: (item: CodeInfo) => {
          if (item.properties["defaultImageId"]) {
            return <ItemImage id={item.properties["defaultImageId"]} isSmall />;
          } else {
            return <ParagraphText>{item.description ? item.description : strings.itemImage_NoImage}</ParagraphText>;
          }
        },
        isPadded: true,
      },
    ];

    this.state = {
      columns,
    };
  }

  render() {
    const { items, busy, viewDetailedList } = this.props;
    const { columns } = this.state;
    return (
      <ItemsView
        items={items}
        busy={busy}
        columns={columns}
        viewList={viewDetailedList}
        onItemInvoked={this._onItemInvoked}
      />
    );
  }

  private _onItemInvoked = (item?: CodeInfo) => {
    if (item) {
      this.props.onSelected(item.id);
    }
  };
}
