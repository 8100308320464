import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Dropdown,
  IComboBoxOption,
  Panel,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  TextField,
} from "@fluentui/react";
import { OnRenderOptionDropdown, ParagraphLabel, StackHorizontal, StackVert } from "./Controls";
import { Language } from "./Strings";
import { EditTypeModal } from "./EditTypeModal";
import Api from "../api";
import { TitleWithInfo } from "./TitleWithInfo";
import { useObserveCode } from "../hooks/useObserveCode";
import { useHistory } from "react-router-dom";
import { ItemFooterSave } from "./ItemFooterSave";
import { useObserveUser } from "../hooks/useObserveUser";
import { ImagesPanel } from "./ImagesPanel";
import { UploadDialog } from "./UploadDialog";
import { ListsDropdown } from "./ListsDropdown";
import { CodePropsType } from "../models";
import { Helpers } from "../helpers";

const strings = Language.strings;

interface EditBoxesProps {
  qrCode: string;
  header: string;
  onSaved: () => void;
  onChangeType: (type: CodePropsType) => void;
}

export const EditBoxes = ({ qrCode, header, onSaved, onChangeType }: EditBoxesProps) => {
  const [isValid, setIsValid] = useState(false);
  const history = useHistory();
  const [, setInfo] = useState(""); // TODO: display error and info messages!
  const [, setError] = useState("");

  const {
    busy,
    code,
    save,
    content,
    setContent,
    contentType,
    setContentType,
    location,
    setLocation,
    room,
    setRoom,
    boxState,
    setBoxState,
    priority,
    setPriority,
    description,
    setDescription,
    fragility,
    setFragility,
    boxColor,
    setBoxColor,
    state,
    shortId,
    unregister,
    images,
    defaultImageId,
    setDefaultImageId,
    addImage,
    refreshImages,
    setCodeStores,
    codeStores,
    ownerUserId,
    boxStateIconFilename,
    changed,
    copyCodeFromLocalSettings,
  } = useObserveCode({
    code: qrCode,
    onSaved,
    onUnregistered: () => history.replace("/"),
  });

  const {
    contentChoices,
    contentTypeChoices,
    locationChoices,
    roomChoices,
    setContentChoices,
    setContentTypeChoices,
    setLocationChoices,
    setRoomChoices,
    stores,
  } = useObserveUser();

  useEffect(() => {
    setIsValid(content !== "" || contentType !== "" || description !== "");
  }, [content, contentType, description]);

  // const userId = Helpers.getCurrentUserId();
  // const [error, setError] = useState(undefined);

  // TODO: Add https://medium.com/geekculture/creating-multi-select-dropdown-with-checkbox-in-react-792ff2464ef3 for store choices

  const [showEditContentChoices, setShowEditContentChoices] = useState(false);
  const [showEditContentTypeChoices, setShowEditContentTypeChoices] = useState(false);
  const [showEditLocationChoices, setShowEditLocationChoices] = useState(false);
  const [showEditRoomChoices, setShowEditRoomChoices] = useState(false);
  // const [showEditPriorityChoices, setShowEditPriorityChoices] = useState(false);
  // const [showEditBoxStateChoices, setShowEditBoxStateChoices] = useState(false);
  // const [showEditBoxColorChoices, setShowEditBoxColorChoices] = useState(false);

  // const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false);
  const [showEditImagesPanel, setShowEditImagesPanel] = useState(false);
  const [showUploadDialog, setShowUploadDialog] = useState(false);

  const updateChoices = (value: string[] | undefined, listName: string, setFunction: (choices: string[]) => void) => {
    if (value) {
      Api.setProperty("user", `box${listName}ChoicesList`, JSON.stringify(value));
      setFunction(value);
    }
  };

  const mapChoices = (items: string[], onAdd: () => void): IComboBoxOption[] => {
    return Helpers.prepDropdownOptionsWithAddItem(
      Helpers.insertNoChoiceOption(items.map((item) => ({ key: item, text: item }))),
      onAdd
    );
  };

  const optionsContentChoices = mapChoices(contentChoices, () => setShowEditContentChoices(true));

  const optionsContentTypeChoices = mapChoices(contentTypeChoices, () => setShowEditContentTypeChoices(true));
  const optionsLocationChoices = mapChoices(locationChoices, () => setShowEditLocationChoices(true));
  const optionsRoomChoices = mapChoices(roomChoices, () => setShowEditRoomChoices(true));

  const optionsBoxStateChoices: IComboBoxOption[] = Helpers.insertNoChoiceOption(
    Object.keys(strings.EditBoxes_BoxStateList2).map((k) => ({
      key: k,
      text: strings.EditBoxes_BoxStateList2[k],
    }))
  );
  const optionsPrioryChoices: IComboBoxOption[] = Helpers.insertNoChoiceOption(
    Object.keys(strings.EditBoxes_PriorityList2).map((k) => ({
      key: k,
      text: strings.EditBoxes_PriorityList2[k],
    }))
  );
  const optionsBoxColorChoices: IComboBoxOption[] = Helpers.insertNoChoiceOption(
    Object.keys(strings.EditBoxes_BoxColorChoices).map((k) => ({
      key: k,
      text: strings.EditBoxes_BoxColorChoices[k],
    }))
  );

  return (
    <>
      <EditTypeModal
        title={strings.generic_edit}
        value={contentTypeChoices}
        onChange={(values) => updateChoices(values, "ContentType", setContentTypeChoices)}
        show={showEditContentTypeChoices}
        onDismiss={() => setShowEditContentTypeChoices(false)}
      />
      <EditTypeModal
        title={strings.generic_edit}
        value={contentChoices}
        onChange={(values) => updateChoices(values, "Content", setContentChoices)}
        show={showEditContentChoices}
        onDismiss={() => setShowEditContentChoices(false)}
      />
      <EditTypeModal
        title={strings.generic_edit}
        value={locationChoices}
        onChange={(values) => updateChoices(values, "Location", setLocationChoices)}
        show={showEditLocationChoices}
        onDismiss={() => setShowEditLocationChoices(false)}
      />
      <EditTypeModal
        title={strings.generic_edit}
        value={roomChoices}
        onChange={(values) => updateChoices(values, "Room", setRoomChoices)}
        show={showEditRoomChoices}
        onDismiss={() => setShowEditRoomChoices(false)}
      />
      <StackVert>
        <Stack.Item>
          <TitleWithInfo
            title={header + (shortId ? " - " + shortId : "")}
            iconFileName={state === "unregistered" ? undefined : boxStateIconFilename}
            infoTitle={strings.EditBoxes_InfoHeader}
            code={qrCode}
          >
            <ParagraphLabel>{strings.EditBoxInfo_BoxInfoHeader}</ParagraphLabel>
            <ul>
              <li>{strings.EditBoxInfo_PlaceQR}</li>
              <li>{strings.EditBoxInfo_ScanQR}</li>
              <li>{strings.EditBoxInfo_Packing}</li>
              <li>{strings.EditBoxInfo_AddingCatDetails}</li>
              <li>{strings.EditBoxInfo_BackLater}</li>
              <li>{strings.EditBoxInfo_FinishedPacking}</li>
              <li>{strings.EditBoxInfo_AddingPrioFrag}</li>
              <li>{strings.EditBoxInfo_PutInStorage}</li>
            </ul>
          </TitleWithInfo>
          {/* <ItemInfoStacked header={strings.EditBoxes_BoxID + ":"} content={shortId ? shortId : ""}></ItemInfoStacked> */}
          {/* <Stack.Item>
            <TextField
              id="prylnamn"
              label={strings.EditBoxes_BoxName}
              value={name ? name : shortId}
              required={false}
              onChange={(_e, value) => setName(value ? value : shortId)}
            />
          </Stack.Item> */}

          <ParagraphLabel>{strings.EditBoxes_ContentTypeHeader}</ParagraphLabel>
          <StackHorizontal>
            <Stack.Item grow>
              <Dropdown
                options={optionsContentTypeChoices}
                selectedKey={contentType}
                onRenderOption={OnRenderOptionDropdown}
                required={false}
                onChange={(_ev, option) => {
                  if (option && option.key) setContentType(option.key as string);
                }}
              />
            </Stack.Item>
          </StackHorizontal>
        </Stack.Item>
        <Stack.Item>
          <ParagraphLabel>{strings.EditBoxes_ContentHeader}</ParagraphLabel>
          <StackHorizontal>
            <Stack.Item grow>
              <Dropdown
                options={optionsContentChoices}
                selectedKey={content}
                onRenderOption={OnRenderOptionDropdown}
                required={false}
                onChange={(_ev, option) => {
                  if (option && option.key) setContent(option.key as string);
                }}
              />
            </Stack.Item>
          </StackHorizontal>
        </Stack.Item>
        <Stack.Item>
          <TextField
            multiline
            autoAdjustHeight
            label={strings.generic_description}
            required={false}
            value={description}
            onChange={(_e, value) => setDescription(value || "")}
          />
        </Stack.Item>

        <Stack.Item>
          <ParagraphLabel>{strings.editStatic_photo}</ParagraphLabel>
        </Stack.Item>
        <Stack.Item>
          {!showUploadDialog && images.length > 0 && (
            <ImagesPanel
              viewMode
              defaultImageId={defaultImageId}
              setDefaultImageId={setDefaultImageId}
              code={qrCode}
              images={images}
            />
          )}
        </Stack.Item>
        {/* TODO: Fix the code! issue.... */}
        {/* <PrimaryButton
            disabled={!code}
            onClick={() => {
              setShowUploadDialog(true);
            }}
          >
            {strings.editStatic_photoChoice}
          </PrimaryButton> */}
        <Stack.Item>
          <PrimaryButton disabled={!code} onClick={() => setShowEditImagesPanel(true)}>
            {strings.generic_manageImages}
          </PrimaryButton>
        </Stack.Item>
        {code && (
          <Panel
            headerText={strings.generic_manageImages}
            isOpen={showEditImagesPanel}
            isLightDismiss
            onDismiss={() => setShowEditImagesPanel(false)}
            closeButtonAriaLabel={strings.generic_close}
          >
            <ImagesPanel
              code={code.id}
              images={images}
              defaultImageId={defaultImageId}
              setDefaultImageId={setDefaultImageId}
              onImageAdded={() => addImage()}
              onImageDeleted={refreshImages}
            />
            <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign="end">
              <PrimaryButton onClick={() => setShowEditImagesPanel(false)}>{strings.generic_close}</PrimaryButton>
            </Stack>
          </Panel>
        )}
        {code && (
          <UploadDialog
            hidden={!showUploadDialog}
            filename={code!.id}
            onDismiss={() => {
              setShowUploadDialog(false);
            }}
            code={qrCode}
          />
        )}
        <Stack.Item>
          <ParagraphLabel>{strings.EditBoxes_BoxColorHeader}</ParagraphLabel>
          <StackHorizontal>
            <Stack.Item grow>
              <Dropdown
                options={optionsBoxColorChoices}
                required={false}
                selectedKey={boxColor}
                onRenderOption={OnRenderOptionDropdown}
                onChange={(_ev, option) => {
                  if (option && option.key) setBoxColor(option.key as string);
                }}
              />
            </Stack.Item>
          </StackHorizontal>
        </Stack.Item>
        <Stack.Item>
          <ParagraphLabel>{strings.EditBoxes_LocationHeader}</ParagraphLabel>
          <StackHorizontal>
            <Stack.Item grow>
              <Dropdown
                options={optionsLocationChoices}
                selectedKey={location}
                onRenderOption={OnRenderOptionDropdown}
                required={false}
                onChange={(_ev, option) => {
                  if (option && option.key) setLocation(option.key as string);
                }}
              />
            </Stack.Item>
          </StackHorizontal>
        </Stack.Item>
        <Stack.Item>
          <ParagraphLabel>{strings.EditBoxes_RoomHeader}</ParagraphLabel>
          <StackHorizontal>
            <Stack.Item grow>
              <Dropdown
                options={optionsRoomChoices}
                selectedKey={room}
                onRenderOption={OnRenderOptionDropdown}
                required={false}
                onChange={(_ev, option) => {
                  if (option && option.key) setRoom(option.key as string);
                }}
              />
            </Stack.Item>
          </StackHorizontal>
        </Stack.Item>
        <Stack.Item>
          <ParagraphLabel>{strings.EditBoxes_BoxStateHeader}</ParagraphLabel>
          <StackHorizontal>
            <Stack.Item grow>
              <Dropdown
                options={optionsBoxStateChoices}
                selectedKey={boxState}
                required={false}
                onChange={(_ev, option) => {
                  if (option && option.key) setBoxState(option.key as string);
                }}
              />
            </Stack.Item>
          </StackHorizontal>
        </Stack.Item>
        <Stack.Item>
          <ParagraphLabel>{strings.EditBoxes_BoxPriorityHeader}</ParagraphLabel>
          <StackHorizontal>
            <Stack.Item grow>
              <Dropdown
                options={optionsPrioryChoices}
                selectedKey={priority}
                required={false}
                onChange={(_ev, option) => {
                  if (option && option.key) setPriority(option.key as string);
                }}
              />
            </Stack.Item>
          </StackHorizontal>
        </Stack.Item>
        <Stack.Item>
          <Checkbox
            label={strings.handleBoxes_Fragility}
            checked={fragility}
            onChange={(_ev, checked) => setFragility(!!checked)}
          />
        </Stack.Item>
        <Stack.Item>
          <ListsDropdown
            setCodeStores={setCodeStores}
            label={strings.editStatic_ListsHeader}
            selectedLists={codeStores.map((s) => s.id)}
            lists={stores}
            ownerUserId={ownerUserId}
            setError={setError}
            setInfo={setInfo}
          />
        </Stack.Item>
        <Stack.Item>{busy && <Spinner size={SpinnerSize.large} />}</Stack.Item>
        <ItemFooterSave
          code={qrCode}
          onSave={save}
          isValid={isValid}
          onUnregister={unregister}
          onChangeType={onChangeType}
          canSave={changed && !busy}
          onPasteCode={copyCodeFromLocalSettings}
        />
      </StackVert>
    </>
  );
};
