import React from "react";
import { Stack, PrimaryButton } from "@fluentui/react";
import {
  StackVert,
  ParagraphText,
  ItemInfoStacked,
  ParagraphLabel,
  StackHorizontal,
  ShowList,
  CodeShowAndLinkToQRImage,
} from "./Controls";
import { Language } from "./Strings";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useObserveCode } from "../hooks/useObserveCode";
import { TitleWithInfo } from "./TitleWithInfo";
import { ListMessages } from "./ListMessages";
import { Helpers } from "../helpers";
import { ImagesPanel } from "./ImagesPanel";
import { ItemFooterEdit } from "./ItemFooterEdit";

const strings = Language.strings;

interface HandleItemBorrowedByOtherProps extends RouteComponentProps {
  code: string;
  returnItem: () => void;
}

// interface HandleItemBorrowedByOtherState {
//     error?: string;
// }

export const HandleItemBorrowedByOther = withRouter(({ history, code, returnItem }: HandleItemBorrowedByOtherProps) => {
  const {
    name,
    description,
    itemType,
    returnNumDays,
    returnDate,
    rentable,
    rentCostDescr,
    codeStores,
    statusIconFilename,
    borrowerUserName,
    state,
    defaultImageId,
    setDefaultImageId,
    images,
    hasCodeEditAccess,
    category,
  } = useObserveCode({ code, onSaved: () => history.goBack() });

  // TODO: Which handleItemReturn to use?
  // const _handleItemReturn = async () => {
  //     const value = this.props.value;
  //     if (value.state === 'pendRetAck') {
  //         await Api.acceptPendingReturn(value.id);
  //     }
  //     else {
  //         await Api.returnCode(value.id); // TODO: change API call when confirm return required is set for the item
  //         if (value.state === 'pendRetAck') {
  //             await Api.acceptPendingReturn(value.id);
  //         }
  //     }

  //     this.props.onChanged(value);
  // };

  // const { error } = state;
  return (
    <StackVert>
      <TitleWithInfo
        title={name ? name : ""} //strings.handleItemBorrowedByOther_BorrowedItem +
        iconFileName={statusIconFilename}
        infoTitle="Borrowed by other info"
        code={code}
      >
        <ul>
          <li>{strings.handleItemBorrowedByOther_info1}</li>
          <li>{strings.handleItemBorrowedByOther_info2}</li>
          <li>{strings.handleItemBorrowedByOther_info3}</li>
        </ul>
      </TitleWithInfo>
      <ItemInfoStacked
        header={strings.handleBorrowingItem_TypeOfItem}
        content={itemType ? itemType : strings.generic_noTypeSet}
      ></ItemInfoStacked>
      <ItemInfoStacked header={strings.item_status} content={Helpers.stateToString(state, false)}></ItemInfoStacked>
      <ItemInfoStacked header={strings.handleBorrowingItem_ItemName} content={name}></ItemInfoStacked>
      <ItemInfoStacked header={strings.handleBorrowingItem_Category} content={category}></ItemInfoStacked>
      <ItemInfoStacked
        header={strings.handleItemBorrowedByOther_BorrowedBy}
        content={borrowerUserName}
      ></ItemInfoStacked>
      <ItemInfoStacked
        header={strings.handleItemBorrowedByOther_ShouldBeReturnedBy}
        content={returnDate ? returnDate.toLocaleDateString() : ""}
      ></ItemInfoStacked>
      <ItemInfoStacked
        header={strings.handleItemBorrowedByOther_ReturnIn}
        content={returnNumDays + " " + strings.handleItemBorrowedByOther_Days}
      ></ItemInfoStacked>
      <Stack.Item>
        <ParagraphLabel>{strings.generic_description}</ParagraphLabel>
        <ParagraphText>{description}</ParagraphText>
      </Stack.Item>
      {rentable && (
        <Stack.Item>
          <ParagraphLabel>{strings.handleItemBorrowedByOther_rentCost}</ParagraphLabel>
          <ParagraphText>{rentCostDescr}</ParagraphText>
        </Stack.Item>
      )}
      <Stack.Item>
        <ParagraphLabel>{strings.item_photo}</ParagraphLabel>
        <ImagesPanel
          viewMode
          defaultImageId={defaultImageId}
          setDefaultImageId={setDefaultImageId}
          code={code}
          images={images}
        />
      </Stack.Item>
      <CodeShowAndLinkToQRImage code={code}></CodeShowAndLinkToQRImage>
      {/* <ItemInfoStacked header={strings.generic_QRCode} content={code} ></ItemInfoStacked> */}
      <ShowList header={strings.generic_ListsShow} codesLists={codeStores}></ShowList>
      <ListMessages code={code} showSendMessage={false} />
      <Stack.Item>
        <StackHorizontal>
          <Stack.Item grow>
            <PrimaryButton onClick={returnItem}>{strings.handleItemBorrowedByOther_ReportReturned}</PrimaryButton>
          </Stack.Item>
          {/* <Stack.Item>
                        <PrimaryButton disabled={!Helpers.canShare()}
                            onClick={async () => {
                                try {
                                    await Helpers.share(this.props.value.id);
                                }
                                catch (error) {
                                    this.setState({ error: error.message });
                                }
                            }}>{strings.generic_share}</PrimaryButton>
                    </Stack.Item> */}

          <Stack.Item align="end">
            {!hasCodeEditAccess && (
              <PrimaryButton onClick={() => history.goBack()}>{strings.generic_close}</PrimaryButton>
            )}
            {hasCodeEditAccess && <ItemFooterEdit code={code} />}
          </Stack.Item>
        </StackHorizontal>
      </Stack.Item>
    </StackVert>
  );
});
