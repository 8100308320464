import React from "react";
import { IColumn, Icon, mergeStyleSets } from "@fluentui/react";
import { CodeInfo } from "../models";
import { Language } from "./Strings";
import { ItemsView } from "./ItemsView";

const strings = Language.strings;

interface ListHtmlItemsProps {
  items: CodeInfo[];
  onSelected: (code: string) => void;
  busy: boolean;
  viewDetailedList: boolean;
}

interface ListHtmlItemsState {
  columns: IColumn[];
}

export class ListHtmlItems extends React.Component<ListHtmlItemsProps, ListHtmlItemsState> {
  constructor(props: ListHtmlItemsProps) {
    super(props);
    const classNames = mergeStyleSets({
      fileIconHeaderIcon: {
        padding: 0,
        fontSize: "16px",
      },
      fileIconCell: {
        textAlign: "center",
        selectors: {
          "&:before": {
            content: ".",
            display: "inline-block",
            verticalAlign: "middle",
            height: "100%",
            width: "0px",
            visibility: "hidden",
          },
        },
      },
      fileIconImg: {
        verticalAlign: "middle",
        maxHeight: "16px",
        maxWidth: "16px",
      },
    });

    const columns: IColumn[] = [
      {
        key: "column1",
        name: "File Type",
        className: classNames.fileIconCell,
        iconClassName: classNames.fileIconHeaderIcon,
        ariaLabel: "Column operations for File type, Press to sort on File type",
        isIconOnly: true,
        fieldName: "file",
        minWidth: 24,
        maxWidth: 24,
        onRender: (item: CodeInfo) => {
          // const statusIconFilename = Helpers.getStatusIconFilename(item);
          return <Icon iconName="FileHTML" />
        },
      },
      {
        key: "column2",
        name: strings.generic_name,
        fieldName: "name",
        minWidth: 80,
        maxWidth: 110,
        isRowHeader: true,
        isResizable: true,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        data: "string",
        isPadded: true,
      },
      {
        key: "column3",
        name: strings.listHtmlItems_created,
        fieldName: "createdAt",
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        data: "number",
        isPadded: true,
        onRender: (item: CodeInfo) => <span>{new Date(Date.parse(item.createdAt)).toLocaleDateString()}</span>,
      },
      {
        key: "column4",
        name: strings.listHtmlItems_description,
        fieldName: "description",
        minWidth: 120,
        maxWidth: 300,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        isPadded: true,
      },
    ];

    this.state = {
      columns,
    };
  }

  render() {
    const { items, busy, viewDetailedList } = this.props;
    const { columns } = this.state;

    return (
      <ItemsView
        items={items}
        busy={busy}
        columns={columns}
        viewList={viewDetailedList}
        onItemInvoked={this._onItemInvoked}
      />
    );
  }

  private _onItemInvoked = (item?: CodeInfo) => {
    if (item) {
      this.props.onSelected(item.id);
    }
  };
}
