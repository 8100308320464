import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Dropdown,
  IComboBoxOption,
  Panel,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  TextField,
  IconButton,
} from "@fluentui/react";
import { OnRenderOptionDropdown, ParagraphLabel, StackHorizontal, StackVert } from "./Controls";
import { Language } from "./Strings";
import Api from "../api";
import { useObserveCode } from "../hooks/useObserveCode";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ItemFooterSave } from ".";
import { TitleWithInfo } from "./TitleWithInfo";
import { Helpers } from "../helpers";
import { UploadDialog } from "./UploadDialog";
import { EditTypeModal } from "./EditTypeModal";
import { useObserveUser } from "../hooks/useObserveUser";
import { ListsDropdown } from "./ListsDropdown";
import { InfoBar } from "./InfoBar";
import { ErrorBar } from "./ErrorBar";
import { ImagesPanel } from "./ImagesPanel";
import { CodePropsType } from "../models";
import { ScanDialog } from "./ScanDialog";

const strings = Language.strings;

interface StaticProps extends RouteComponentProps {
  qrCode: string;
  header: string;
  onSaved: () => void;
  onChangeType: (type: CodePropsType) => void;
}

export const EditStatic = withRouter(({ qrCode, header, history, onSaved, onChangeType }: StaticProps) => {
  const [isValid, setIsValid] = useState(false);
  const [info, setInfo] = useState("");
  const [error, setError] = useState("");
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [showEditImagesPanel, setShowEditImagesPanel] = useState(false);
  const [quickAdd, setQuickAdd] = useState(false);
  const [showScanner, setShowScanner] = useState(false);
  // const [stores, setStores] = useState<Store[]>([]);
  const {
    code,
    busy,
    save,
    name,
    setName,
    description,
    setDescription,
    requireUserRemovalApproval,
    setRequireUserRemovalApproval,
    requireBankID,
    setRequireBankID,
    rentable,
    setRentable,
    rentCostDescr,
    setRentCostDescr,
    itemType,
    setItemType,
    codeStores,
    setCodeStores,
    statusIconFilename,
    ownerUserId,
    unregister,
    images,
    defaultImageId,
    setDefaultImageId,
    addImage,
    refreshImages,
    location,
    setLocation,
    room,
    setRoom,
    category,
    setCategory,
    changed,
    copyCodeFromLocalSettings,
  } = useObserveCode({
    code: qrCode,
    onSaved,
    onUnregistered: () => history.replace("/"),
  });

  const {
    stores,
    locationChoices,
    roomChoices,
    categoryChoices,
    typeChoices,
    setLocationChoices,
    setRoomChoices,
    setCategoryChoices,
    setTypeChoices,
  } = useObserveUser();

  useEffect(() => {
    if (itemType && typeChoices.indexOf(itemType) === -1) {
      setTypeChoices([itemType, ...typeChoices]);
    }
  }, [itemType, typeChoices, setTypeChoices]);

  useEffect(() => {
    if (category && categoryChoices.indexOf(category) === -1) {
      setCategoryChoices([category, ...categoryChoices]);
    }
  }, [category, categoryChoices, setCategoryChoices]);

  useEffect(() => {
    if (room && roomChoices.indexOf(room) === -1) {
      setRoomChoices([room, ...roomChoices]);
    }
  }, [room, roomChoices, setRoomChoices]);

  useEffect(() => {
    if (location && locationChoices.indexOf(location) === -1) {
      setLocationChoices([location, ...locationChoices]);
    }
  }, [location, locationChoices, setLocationChoices]);

  const [showEditItemTypeChoices, setshowEditItemTypeChoices] = useState(false);
  const [showEditLocationChoices, setShowEditLocationChoices] = useState(false);
  const [showEditRoomChoices, setShowEditRoomChoices] = useState(false);
  const [showEditCategoryChoices, setShowEditCategoryChoices] = useState(false);
  const [optionsRoomChoices, setOptionsRoomChoices] = useState<IComboBoxOption[]>([]);

  const mapChoices = (items: string[], onAdd: () => void): IComboBoxOption[] => {
    return Helpers.prepDropdownOptionsWithAddItem(
      Helpers.insertNoChoiceOption(items.map((item) => ({ key: item, text: item }))),
      onAdd
    );
  };

  const optionsItemTypeChoices = mapChoices(typeChoices, () => setshowEditItemTypeChoices(true));
  const optionsLocationChoices = mapChoices(locationChoices, () => setShowEditLocationChoices(true));
  useEffect(() => {
    // console.log("roomChoices", roomChoices);
    setOptionsRoomChoices(mapChoices(roomChoices, () => setShowEditRoomChoices(true)));
  }, [roomChoices]);
  const optionsCategoryChoices = mapChoices(categoryChoices, () => setShowEditCategoryChoices(true));

  // const optionsItemTypeChoices: IComboBoxOption[] = itemTypeChoices.map((title) => {
  //   return { key: title, text: title };
  // });
  // const optionsLocationChoices: IComboBoxOption[] = locationChoices.map((title) => {
  //   return { key: title, text: title };
  // });
  // const optionsRoomChoices: IComboBoxOption[] = roomChoices.map((title) => {
  //   return { key: title, text: title };
  // });

  useEffect(() => {
    const valid = name.trim().length > 0 && error.trim().length === 0;
    setIsValid(valid);
  }, [name, error]);

  useEffect(() => {
    Api.getProperties("user").then((properties) => {
      // TODO: console.warn(properties);
    });
  }, []);

  const updateChoices = (value: string[] | undefined, listName: string, setFunction: (choices: string[]) => void) => {
    if (value) {
      Api.setProperty("user", `box${listName}ChoicesList`, JSON.stringify(value));
      setFunction(value);
    }
  };

  return (
    <>
      <EditTypeModal
        title={strings.EditStatic_ItemTypesListHeader}
        value={typeChoices}
        onChange={(values) => updateChoices(values, "Type", setTypeChoices)}
        show={showEditItemTypeChoices}
        onDismiss={() => setshowEditItemTypeChoices(false)}
      />
      <EditTypeModal
        title={strings.generic_edit}
        value={locationChoices}
        onChange={(values) => updateChoices(values, "Location", setLocationChoices)}
        show={showEditLocationChoices}
        onDismiss={() => setShowEditLocationChoices(false)}
      />
      <EditTypeModal
        title={strings.generic_edit}
        value={roomChoices}
        onChange={(values) => updateChoices(values, "Room", setRoomChoices)}
        show={showEditRoomChoices}
        onDismiss={() => setShowEditRoomChoices(false)}
      />
      <EditTypeModal
        title={strings.generic_edit}
        value={categoryChoices}
        onChange={(values) => updateChoices(values, "Category", setCategoryChoices)}
        show={showEditCategoryChoices}
        onDismiss={() => setShowEditCategoryChoices(false)}
      />
      <ScanDialog
        onDismiss={() => setShowScanner(false)}
        onScanned={(data) => {
          setShowScanner(false);
          console.log("Scanned: " + data);
          Api.getBookDetails(data)
            .then((book) => {
              try {
                setName(book.items[0].volumeInfo.title);
                setDescription(
                  "Author(s): " +
                    _authorsToString(book.items[0].volumeInfo.authors) +
                    "\n" +
                    "Description: " +
                    book.items[0].volumeInfo.description +
                    "\n" +
                    "Publisher: " +
                    book.items[0].volumeInfo.publisher +
                    "\n" +
                    "Publication date: " +
                    book.items[0].volumeInfo.publishedDate +
                    "\n"
                );
                setItemType(strings.EditStatic_ItemTypesList[0]);

                // setDescription(description + "\n" + JSON.stringify(book.items[0].volumeInfo));
              } catch (error) {
                alert(strings.editStatic_ISBNScanningError);
                console.log(error, book);
              }
            })
            .catch((error) => {
              alert(strings.editStatic_ISBNScanningError);
              console.log(error);
            });
        }}
        hidden={!showScanner}
      />
      <StackVert>
        <TitleWithInfo
          title={header + (name ? " - " + name : "")}
          iconFileName={statusIconFilename}
          infoTitle={strings.editStatic_infoHeader}
          code={qrCode}
        >
          <ul>
            {/* TODO: Add the information and add in strings */}
            <li>{strings.editStatic_1}</li>
            <li>{strings.editStatic_2}</li>
            <li>{strings.editStatic_3}</li>
            <li>{strings.editStatic_3_1}</li>
            <li>{strings.editStatic_3_2}</li>
            <li>{strings.editStatic_4}</li>
            <li>{strings.editStatic_5}</li>
            {Helpers.hasFeature("rent") && <li>{strings.editStatic_6}</li>}
            <li>{strings.editStatic_7}</li>
          </ul>
        </TitleWithInfo>
        <Stack.Item>
          <ParagraphLabel>{strings.EditStatic_ItemTypesHeader}</ParagraphLabel>
          <StackHorizontal>
            <Stack.Item grow>
              <Dropdown
                options={optionsItemTypeChoices}
                selectedKey={itemType}
                onRenderOption={OnRenderOptionDropdown}
                onChange={(_ev, option) => {
                  if (option && option.key) setItemType(option.key as string);
                }}
              />
            </Stack.Item>
          </StackHorizontal>
        </Stack.Item>
        {/* <PageTitle>{strings.editItem_PageHeaderEdit}</PageTitle> */}
        <Stack.Item>
          <TextField
            id="prylnamn"
            label={strings.generic_name}
            disabled={!code}
            value={name}
            onChange={(_e, value) => setName(value || "")}
          />
        </Stack.Item>
        <Stack.Item>
          <TextField
            multiline
            autoAdjustHeight
            label={strings.generic_description}
            disabled={!code}
            value={description}
            onChange={(_e, value) => setDescription(value || "")}
          />
          <StackHorizontal>
            <IconButton iconProps={{ iconName: "GenericScan" }} onClick={() => setShowScanner(true)} />
            {/* <ParagraphText>{strings.editStatic_ISBNScanningInfo}</ParagraphText> */}
          </StackHorizontal>
        </Stack.Item>
        <Stack.Item>
          <Checkbox
            label={strings.editStatic_putItemForRent}
            disabled={!code}
            checked={rentable}
            onChange={(_ev, checked) => setRentable(!!checked)}
          />
        </Stack.Item>
        {rentable && rentCostDescr && rentCostDescr !== "-" && (
          <Stack.Item>
            <TextField
              multiline
              autoAdjustHeight
              label={strings.registerItem_rentInfo}
              disabled={!code}
              value={rentCostDescr}
              onChange={(_e, value) => setRentCostDescr(value || "")}
            />
          </Stack.Item>
        )}
        <Stack.Item>
          <ParagraphLabel>{strings.editStatic_photo}</ParagraphLabel>
        </Stack.Item>
        <Stack.Item>
          {!showUploadDialog && images.length > 0 && (
            <ImagesPanel
              viewMode
              defaultImageId={defaultImageId}
              setDefaultImageId={setDefaultImageId}
              code={qrCode}
              images={images}
            />
          )}
        </Stack.Item>
        {/* TODO: Fix the code! issue.... */}
        {/* <PrimaryButton
            disabled={!code}
            onClick={() => {
              setShowUploadDialog(true);
            }}
          >
            {strings.editStatic_photoChoice}
          </PrimaryButton> */}
        <Stack.Item>
          <StackHorizontal>
            <PrimaryButton
              disabled={!code}
              onClick={() => {
                setQuickAdd(false);
                setShowEditImagesPanel(true);
              }}
            >
              {strings.generic_manageImages}
            </PrimaryButton>
            <PrimaryButton
              disabled={!code}
              onClick={() => {
                setQuickAdd(true);
                setShowEditImagesPanel(true);
              }}
            >
              {strings.generic_quickAddImage}
            </PrimaryButton>
          </StackHorizontal>
        </Stack.Item>
        <Stack.Item>
          {code && (
            <Panel
              headerText={strings.generic_manageImages}
              isOpen={showEditImagesPanel}
              isLightDismiss
              onDismiss={() => setShowEditImagesPanel(false)}
              closeButtonAriaLabel={strings.generic_close}
            >
              <ImagesPanel
                code={code.id}
                images={images}
                defaultImageId={defaultImageId}
                setDefaultImageId={setDefaultImageId}
                onImageAdded={() => addImage()}
                onImageDeleted={refreshImages}
                quickAdd={quickAdd}
              />
              <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign="end">
                <PrimaryButton onClick={() => setShowEditImagesPanel(false)}>{strings.generic_close}</PrimaryButton>
              </Stack>
            </Panel>
          )}
        </Stack.Item>
        <Stack.Item>
          {code && (
            <UploadDialog
              hidden={!showUploadDialog}
              filename={code!.id} // TODO: Fix...
              onDismiss={() => {
                setShowUploadDialog(false);
              }}
              code={qrCode}
            />
          )}
        </Stack.Item>
        {/* <Stack.Item>{error && <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>}</Stack.Item> */}
        <Stack.Item>
          <Checkbox
            label={strings.editStatic_alwaysConfirmReturn}
            disabled={!code}
            checked={requireUserRemovalApproval}
            onChange={(_ev, checked) => setRequireUserRemovalApproval(!!checked)}
          />
        </Stack.Item>
        <Stack.Item>
          <ParagraphLabel>{strings.EditBoxes_LocationHeader}</ParagraphLabel>
          <StackHorizontal>
            <Stack.Item grow>
              <Dropdown
                options={optionsLocationChoices}
                selectedKey={location}
                onRenderOption={OnRenderOptionDropdown}
                required={false}
                onChange={(_ev, option) => {
                  if (option && option.key) setLocation(option.key as string);
                }}
              />
            </Stack.Item>
          </StackHorizontal>
        </Stack.Item>
        <Stack.Item>
          <ParagraphLabel>{strings.EditBoxes_RoomHeader}</ParagraphLabel>
          <StackHorizontal>
            <Stack.Item grow>
              <Dropdown
                options={optionsRoomChoices}
                selectedKey={room}
                onRenderOption={OnRenderOptionDropdown}
                required={false}
                onChange={(_ev, option) => {
                  if (option && option.key) setRoom(option.key as string);
                }}
              />
            </Stack.Item>
          </StackHorizontal>
        </Stack.Item>
        <Stack.Item>
          <ParagraphLabel>{strings.EditBoxes_CategoryHeader}</ParagraphLabel>
          <StackHorizontal>
            <Stack.Item grow>
              <Dropdown
                options={optionsCategoryChoices}
                selectedKey={category}
                onRenderOption={OnRenderOptionDropdown}
                required={false}
                onChange={(_ev, option) => {
                  if (option && option.key) setCategory(option.key as string);
                }}
              />
            </Stack.Item>
          </StackHorizontal>
        </Stack.Item>
        <Stack.Item>
          <ListsDropdown
            setCodeStores={setCodeStores}
            label={strings.editStatic_ListsHeader}
            selectedLists={codeStores.map((s) => s.id)}
            lists={stores}
            ownerUserId={ownerUserId}
            setError={setError}
            setInfo={setInfo}
          />
        </Stack.Item>
        {Helpers.hasFeature("bankID") && (
          <Stack.Item>
            <Checkbox
              label={strings.editStatic_registrerMustHaveBankID}
              disabled={!code}
              checked={requireBankID}
              onChange={(_ev, checked) => setRequireBankID(!!checked)}
            />
          </Stack.Item>
        )}
        {/* <Stack.Item>
                    <ParagraphLabel>STR: Listor</ParagraphLabel>
                    <ul>
                        {codeStores.map((store) => (
                            <li key={store.name}>{store.name}</li>
                        ))}
                    </ul>
                    <MultiItemSelect
                        selectedItems={codeStores.map((s) => s.id)}
                        items={stores.map((s) => ({
                            key: s.ID,
                            text: s.Name,
                            item: s,
                        }))}
                        renderItem={(i) => {
                            console.log(i);
                            return <p>AA</p>;
                        }}
                        onSelected={onStoresSelected}
                    />
                </Stack.Item> */}
        <Stack.Item>{busy && <Spinner size={SpinnerSize.large} />}</Stack.Item>
        <Stack.Item>
          <InfoBar info={info} />
        </Stack.Item>
        <Stack.Item>
          <ErrorBar error={error} />
        </Stack.Item>
        <Stack.Item>
          <ItemFooterSave
            code={qrCode}
            onSave={save}
            isValid={isValid}
            onUnregister={unregister}
            onChangeType={onChangeType}
            canSave={changed && !busy}
            onPasteCode={copyCodeFromLocalSettings}
          />
        </Stack.Item>
      </StackVert>
    </>
  );
});

// function insertNoChoiceOption(arg0: { key: string; text: string }[]): IComboBoxOption[] {
//   throw new Error("Function not implemented.");
// }

function _authorsToString(authors: string[] | undefined) {
  if (!authors) {
    return "";
  }
  if (authors.length === 1) {
    return authors[0];
  }
  if (authors.length === 2) {
    return authors.join(" and ");
  }
  return `${authors.slice(0, -1).join(", ")}, and ${authors[authors.length - 1]}`;
}
