import React from "react";
import { IColumn, mergeStyleSets } from "@fluentui/react";
import { CodeInfo } from "../models";
import { ParagraphText } from "./Controls";
import { Language } from "./Strings";
import { ItemsView } from "./ItemsView";
import { Helpers } from "../helpers";

const strings = Language.strings;

interface ListFreezerItemsProps {
  items: CodeInfo[];
  onSelected: (code: string) => void;
  busy: boolean;
  viewDetailedList: boolean;
}

export const ListFreezerItems = (props: ListFreezerItemsProps) => {
  const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
      padding: 0,
      fontSize: "16px",
    },
    fileIconCell: {
      textAlign: "center",
      selectors: {
        "&:before": {
          content: ".",
          display: "inline-block",
          verticalAlign: "middle",
          height: "100%",
          width: "0px",
          visibility: "hidden",
        },
      },
    },
    fileIconImg: {
      verticalAlign: "middle",
      maxHeight: "16px",
      maxWidth: "16px",
    },
  });

  const { items, busy, viewDetailedList } = props;
  const columns: IColumn[] = [
    {
      key: "column1",
      name: "File Type",
      className: classNames.fileIconCell,
      iconClassName: classNames.fileIconHeaderIcon,
      ariaLabel: "Column operations for File type, Press to sort on File type",
      isIconOnly: true,
      fieldName: "name",
      minWidth: 20,
      maxWidth: 20,
      onRender: (item: CodeInfo) => {
        const statusIconFilename = Helpers.getStatusIconFilename(item);
        return <img src={statusIconFilename} className={classNames.fileIconImg} alt="State" />;
      },
    },
    {
      key: "column2",
      name: strings.freezer_FoodTypeHeader,
      fieldName: "name",
      minWidth: 60,
      maxWidth: 80,
      isRowHeader: true,
      isResizable: true,
      data: "string",
      isPadded: true,
      onRender: (item: CodeInfo) => {
        return <ParagraphText>{item.properties ? item.properties.foodType : ""}</ParagraphText>;
      },
    },
    {
      key: "column3",
      name: strings.freezer_ContentTypeHeader,
      fieldName: "name",
      minWidth: 60,
      maxWidth: 80,
      isRowHeader: true,
      isResizable: true,
      data: "string",
      isPadded: true,
      onRender: (item: CodeInfo) => {
        return <ParagraphText>{item.properties ? item.properties.contentType : ""}</ParagraphText>;
      },
    },
    {
      key: "column4",
      name: strings.listFreezerItems_Frozen,
      fieldName: "frozen",
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      isCollapsible: false,
      data: "string",
      onRender: (item: CodeInfo) => {
        if (item.properties.storageDate) {
          return <ParagraphText>{'item.properties.storageDate.toLocaleDateString()'}</ParagraphText>
          // return <ParagraphText>{item.properties.storageDate.toLocaleDateString()}</ParagraphText>;
        } else {
          return <ParagraphText>-</ParagraphText>;
        }
      },
      isPadded: true,
    },
    {
      key: "column5",
      name: strings.listFreezerItems_Expiration,
      fieldName: "expiration",
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      isCollapsible: false,
      data: "string",
      onRender: (item: CodeInfo) => {
        if (item.properties.expirationDate) {
          return <ParagraphText>{item.properties.expirationDate.toLocaleDateString()}</ParagraphText>;
        } else {
          return <ParagraphText>-</ParagraphText>;
        }
      },
      isPadded: true,
    },
  ];

  const onItemInvoked = (item?: CodeInfo) => {
    if (item) {
      props.onSelected(item.id);
    }
  };

  return (
    <ItemsView items={items} busy={busy} columns={columns} viewList={viewDetailedList} onItemInvoked={onItemInvoked} />
  );
};
