import * as React from "react";
import { PrimaryButton, Stack } from "@fluentui/react";
import { CodeInfo, UserInfo, Purchase } from "../models";
import queryString from "query-string";
import Api from "../api";
import { Content } from "./Content";
import { ParagraphTitle, ParagraphText } from "./Controls";

interface AdminProps {}
interface AdminState {
  userId: string | null;
  userInfo?: UserInfo;
  userIsAdmin: boolean;
  state: "address_scanned" | "waiting_for_batch_scan" | undefined;

  // Buyer
  buyerInfo?: UserInfo;
  buyerGUID?: string;
  purchases: Purchase[];

  // QR code (from ?s=xxxxxx)
  qrCode?: string;
  codeInfo?: CodeInfo; // to get the BatchID

  // Tag purchase info
  numTags: number;
  buyDigital: boolean;
}

export class Admin extends React.Component<AdminProps, AdminState> {
  constructor(props: AdminProps) {
    super(props);

    // Take qr code from query string (format: ?s=xxxxxx)
    // TODO: How to parse with /admin in the URL instead
    const { s } = queryString.parse(window.location.search);
    const buyerGUID = s ? (s as string) : undefined;

    this.state = {
      userId: localStorage.getItem("userId"),
      state: "address_scanned",
      numTags: 0,
      userIsAdmin: false,
      buyerGUID,
      buyDigital: false,
      purchases: [],
    };
  }

  async componentDidMount() {
    if (this.state.userId && this.state.buyerGUID) {
      await this._getBuyerInfo();
    }
  }

  async componentDidUpdate(prevProps: AdminProps, prevState: AdminState) {
    if (prevState.buyerGUID === null && this.state.buyerGUID !== null) {
      await this._getBuyerInfo();
    }
  }

  render() {
    return (
      <Stack>
        <div
          id="header"
          style={{
            backgroundColor: "rgb(30, 89, 92)",
            color: "white",
            position: "absolute",
            top: "0px",
            left: "0px",
            height: "40px",
            right: "0px",
            overflow: "hidden",
          }}
        >
          <Content>
            {/* UserID and scanned address */}
            {!this.state.userId && (
              <>
                {this.state.state !== "address_scanned" && this._getBuyerInfo() && (
                  <>
                    {
                      <>
                        {/* TODO 1: Get userInfo. Specifically check if userIsAdmin
                                    TODO 2: Get the buyers Info (address etc)
                                    TODO 3: Get the information about the buyers purchases */}
                        <ParagraphTitle>Du har skannat en address med buyerGUID {this.state.buyerGUID}.</ParagraphTitle>
                        <ParagraphText>
                          Skanna en ny batch inom 2 minuter, eller välj att återställa (för att skanna en annan).
                        </ParagraphText>
                      </>
                    }
                    <PrimaryButton onClick={() => this.setState({ state: undefined })}>
                      Avbryt denna addresskanning
                    </PrimaryButton>
                  </>
                )}

                {
                  this.state.state === "waiting_for_batch_scan" // what to do?
                }
              </>
            )}
          </Content>
        </div>
      </Stack>
    );
  }

  // private _registerNewCode = async (title: string, description: string) => {
  //     const response = await Api.registerCode(this.state.qrCode!, this.state.userId!, title, description);
  //     alert(JSON.stringify(response));
  //     if (response.success) {
  //         const codes = await Api.getCodesForUser(this.state.userId!);
  //         console.log('new codes', codes);
  //         this.setState({ qrCode: undefined, codeInfo: undefined, items: codes });
  //     }
  // }

  private _getBuyerInfo = async () => {
    if (this.state.userId) {
      try {
        const userInfo = await Api.getUserInfo(this.state.userId!);
        console.log("userInfo = ", userInfo);

        this.setState({ userInfo: userInfo });
        this.setState({ userIsAdmin: userInfo.isAdmin });
      } catch {
        alert("Misslyckades att hämta mer userIsAdmin. Försök igen senare!");
      }

      if (this.state.buyerGUID && this.state.userInfo && this.state.userInfo.isAdmin) {
        try {
          const buyerInfo = await Api.getUserInfo(this.state.buyerGUID!);
          console.log("buyerInfo = ", buyerInfo);

          this.setState({ buyerInfo });
        } catch {
          alert("Misslyckades att hämta BuyerInfo. Försök igen senare!");
        }
        // try {
        //     const purchaseInfo = await Api.getPurchases();  // TODO: we can only get this user's purchases via the API
        //     console.log('purchaseInfo = ', purchaseInfo);

        //     this.setState({ purchases: purchaseInfo });
        // }
        // catch {
        //     alert('Misslyckades att hämta BuyerInfo. Försök igen senare!');
        // }
      }
    }
  };

  // private _updateBuyer = async () => {
  //     const codes = await Api.getContactInfoForBuyer(this.buyerGUID!);
  //     //this.setState({ items: codes });
  // }
}
