import React, { useState } from "react";
import { Stack, TextField, PrimaryButton } from "@fluentui/react";
import { StackHorizontal } from "./Controls";
import Api from "../api";
import { Helpers } from "../helpers";
import { useObserveStrings } from "../hooks/useObserveStrings";

export const SendMessage = ({
  sendButtonText,
  code,
  onMessageSent,
}: {
  sendButtonText: string;
  code: string;
  onMessageSent: () => void;
}) => {
  const strings = useObserveStrings();

  const sendMessage = async () => {
    await Api.postMessage({
      userId: Helpers.getCurrentUserId(),
      codeId: code,
      message: message,
      createdAt: new Date(),
    });
    setMessage("");
    onMessageSent();
  };

  const [message, setMessage] = useState("");

  return (
    <StackHorizontal>
      <Stack.Item grow>
        <TextField
          multiline
          id="mess"
          label={strings.messages_messageTitle}
          value={message}
          onChange={(_e, value) => setMessage(value || "")}
        />
      </Stack.Item>
      <Stack.Item align="end">
        <PrimaryButton disabled={message.trim().length === 0} onClick={sendMessage}>
          {sendButtonText}
        </PrimaryButton>
      </Stack.Item>
    </StackHorizontal>
  );
};
