import { Stack, FocusZone, IColumn } from "@fluentui/react";
import React from "react";
import { CodeInfo } from "../models";
import { ItemGridView } from "./ItemGridView";
import { ItemListView } from "./ItemListView";

interface ItemsViewProps {
  busy: boolean;
  viewList: boolean;
  items: CodeInfo[];
  columns: IColumn[];
  onItemInvoked: (item?: CodeInfo) => void;
}

export const ItemsView = ({ busy, items, columns, viewList, onItemInvoked }: ItemsViewProps) => (
  <Stack tokens={{ childrenGap: 15 }}>
    <Stack>
      <FocusZone>
        {viewList && <ItemListView items={items} busy={busy} columns={columns} onItemInvoked={onItemInvoked} />}
        {!viewList && <ItemGridView items={items} onItemInvoked={onItemInvoked} />}
      </FocusZone>
    </Stack>
  </Stack>
);
