import { CodeInfo, CodeProps, UserProps, UserInfo, CodePropsType, Roles } from "./models";
import Api from "./api";
import { strings } from "./components/Strings";
import { IComboBoxOption } from "@fluentui/react";

export class Helpers {
  static logout() {
    localStorage.removeItem("userId");
    localStorage.removeItem("token");
  }

  static isLoggedIn() {
    const userId = localStorage.getItem("userId");
    return userId ? true : false;
  }

  static getCurrentUserId() {
    let userId = localStorage.getItem("userId");
    if (!userId) {
      userId = "00000000-0000-0000-0000-000000000000";
    }

    return userId;
  }

  static stateToString(state: string, isMine: boolean) {
    switch (state) {
      case "address_scanned":
        return strings.Helpers_Scanned;
      case "waiting_for_batch_scan":
        return strings.Helpers_Unregistered;
      case "registered":
        return strings.Helpers_Home;
      case "borrowed":
        if (isMine) {
          return strings.Helpers_BorrowedByOther;
        } else {
          // TODO:           if (this.isBorrowedByMe)
          return strings.Helpers_BorrowedByMe;
        }
      case "pendRetAck":
        return strings.Helpers_AwaitingReturnAccept;
      case "found":
        return strings.Helpers_Found;
      default:
        return state;
    }
  }

  static isBorrowed(item: CodeInfo) {
    return item.state === "borrowed";
  }

  static isHtml(item: CodeInfo) {
    return item.state === "html";
  }

  static isRegistered(item: CodeInfo) {
    return !this.isUnregistered(item);
  }

  static isMine(item: CodeInfo) {
    return item.userId === this.getCurrentUserId();
  }

  static isAwaitingOwnerReturnAccept(item: CodeInfo) {
    return item.state === "pendRetAck";
  }

  static isUnregistered(item: CodeInfo) {
    return item.state === "unregistered";
  }

  static isBorrowedByMe(item: CodeInfo) {
    return Helpers.isBorrowed(item) && item.borrowerUserId === this.getCurrentUserId();
  }

  // static isStaticCodeType(item: Partial<CodeProps>) {
  //    let isXType = true;
  //    if (item) {
  //       isXType = item.type == 'static';
  //    }
  //    return isXType;
  // }

  // static isFreezerCodeType(item: Partial<CodeProps>) {
  //    let isXType = true;
  //    if (item) {
  //       isXType = item.type == 'freezer';
  //    }
  //    return isXType;
  // }

  // static isLostFoundCodeType(item: Partial<CodeProps>) {
  //    let isXType = true;
  //    if (item) {
  //       isXType = item.type == 'lostFound';
  //    }
  //    return isXType;
  // }

  // static isHtmlCodeType(item: Partial<CodeProps>) {
  //    let isXType = true;
  //    if (item) {
  //       isXType = item.type == 'html';
  //    }
  //    return isXType;
  // }

  // static isBoxesCodeType(item: Partial<CodeProps>) {
  //    let isXType = true;
  //    if (item) {
  //       isXType = item.type == 'boxes';
  //    }
  //    return isXType;
  // }

  static getCodeType(item?: CodeInfo): CodePropsType {
    // if (item && item.properties && item.properties.type) {
    if (item) {
      return item.type;
    }

    return "static";
  }

  static getCodeTypeString = (item: CodeInfo) => {
    const codeType = Helpers.getCodeType(item);
    return codeType === "static"
      ? strings.home_items
      : codeType === "freezer"
      ? strings.home_FreezerReuse
      : codeType === "lostFound"
      ? strings.home_LostFound
      : codeType === "html"
      ? strings.home_Freetext
      : codeType === "boxes"
      ? strings.home_Boxes
      : "";
  };

  // TODO: Make this obsolete by using the useObserveCode everywhere instead.
  static getStatusIconFilename(item?: CodeInfo) {
    if (!item) {
      return "/ICON_Freezer_64.png";
    } // TODO: Change to "default" icon
    else if (item.type === "freezer") {
      return "/ICON_Freezer_64.png";
    } else if (Helpers.isRegistered(item)) {
      if (Helpers.isMine(item)) {
        return "/ICON_Home_64.png";
      } else {
        return "/ICON_HomeAtOther_64.png";
      }
    } else {
      if (Helpers.isBorrowed(item) && Helpers.isMine(item)) {
        return "/ICON_MineBorrowedByOther_64.png";
      } else if (Helpers.isAwaitingOwnerReturnAccept(item) && Helpers.isMine(item)) {
        return "/ICON_MinePendingReturnAccept_64.png";
      } else if (Helpers.isAwaitingOwnerReturnAccept(item) && !Helpers.isMine(item)) {
        return "/ICON_OthersPendingReturnAccept_64.png";
      } else {
        if (Helpers.isBorrowed(item) && !Helpers.isBorrowedByMe(item)) {
          return "/ICON_OtherBorrowedByOther_64.png";
        } else {
          // TODO: make explicit if for this case too, and add also when others have borrowed others
          return "/ICON_OthersBorrowedByMe_64.png";
        }
      }
    }
  }

  static getBoxStatusIconFilename(boxState: string) {
    switch (boxState) {
      case "tagged":
        return "/BoxOnly_White_32x32.png";
      case "packing":
        return "/BoxArrowIn_WhiteGreenArrow_32x32.png";
      case "closed":
        return "/BoxOnlyClosed_White_32x32.png";
      case "storedHome":
        return "/BoxOnlyClosedStored_green_32x32.png";
      case "storedAway":
        return "/BoxOnlyClosedStored_red_32x32.png";
      case "unpacking":
        return "/BoxArrowOut_WhiteBlueArrow_32x32.png";
      case "unpacked":
        return "/BoxOnly_White_32x32.png";
      default:
        return "/BoxOnly_White_32x32.png";
    }
  }

  static getStatusIconByName(iconType: string) {
    // TODO: Remove this from the code, use otehr instead
    if (iconType === "home") {
      return "/ICON_Home_64.png";
    } else {
      if (iconType === "out") {
        return "/ICON_MineBorrowedByOther_64.png";
      } else {
        if (iconType === "in") {
          return "/ICON_OthersBorrowedByMe_64.png";
        } else {
          if (iconType === "iconTypeOtherByOther") {
            return "/ICON_OtherBorrowedByOther_64.png";
          } else {
            return "/ICON_Freezer_64.png";
          }
        }
      }
    }
  }

  static async getBorrowerUserName(borrowerUserId: string) {
    if (borrowerUserId) return "";
    try {
      const userInfo = await Api.getUserInfo(borrowerUserId);
      return userInfo.username; // this.getFullName(userInfo);
    } catch {
      return "<unable to get user name>";
    }
  }
  static async getDaysUntilReturn(item: CodeInfo) {
    try {
      const code = await Api.getCode(item.id);
      //TODO: get the date when it was borrowed and how long it was borrowed for.
      return code.name;
    } catch {
      return "<unable to get user name>";
    }
  }

  static async getOwnerUserName(item: CodeInfo) {
    try {
      const userInfo = await Api.getUserInfo(item.userId);
      return userInfo.username; // this.getFullName(userInfo);
    } catch {
      return "<unable to get user name>";
    }
  }

  static async getOwnerPhone(item: CodeInfo) {
    // TODO: move getting of phone no to backend (safer)
    try {
      const userInfo = await Api.getUserInfo(item.userId);
      return userInfo.phone;
    } catch {
      return "<unable to get phone>";
    }
  }

  static async verifyCodeExists(qrCode: string) {
    try {
      const item = await Api.verifyCode(qrCode);
      const itemExist = item ? true : false;
      return itemExist;
    } catch {
      return false;
    }
  }

  static async verifyCodeUnregistered(qrCode: string) {
    try {
      const item = await Api.verifyCodeUnregistered(qrCode);
      const itemExist = item ? true : false;
      return itemExist;
    } catch {
      return false;
    }
  }

  static getFullName(user: UserInfo) {
    return user.firstName + " " + user.lastName;
  }

  static getDateDiffInDays(date1: Date, date2: Date) {
    var timeDiff = Math.abs(date2.getTime() - date1.getTime());
    var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return diffDays;
  }

  static getDateWithDaysAdded(days: number) {
    const now = new Date();
    const date = new Date(now.getTime() + days * 1000 * 3600 * 24);
    return date;
  }

  static canShare() {
    return true;
    // // @ts-ignore
    // return navigator.share ? true : false;
  }

  static async share(id: string, title: string = "QRit", text: string = "Jag vill dela min pryl med dig.") {
    // @ts-ignore
    await navigator.share({
      title,
      text,
      url: "https://qrit.app/" + id,
    });
  }

  static async shareStore(id: string, title: string = "QRit", text: string = "Jag vill dela min lista med dig.") {
    // @ts-ignore
    await navigator.share({
      title,
      text,
      url: "https://qrit.app/store/" + id,
    });
  }

  static getFeatures() {
    const features = localStorage.getItem("features");
    return features ? features.split(",") : [];
  }

  static hasFeature(feature: string) {
    return this.getFeatures().includes(feature);
  }

  static parsePropertiesJsonCode(propertiesJson: string) {
    let props = JSON.parse(propertiesJson) || {};
    if (props.date) {
      props.date = new Date(Date.parse(props.date));
    }

    if (props.expirationDate) {
      props.expirationDate = new Date(Date.parse(props.expirationDate));
    }

    return props as CodeProps;
  }

  static parsePropertiesJsonUser(propertiesJson: string) {
    let props = JSON.parse(propertiesJson) || {};
    return props as UserProps;
  }

  static insertNoChoiceOption(options: IComboBoxOption[]) {
    options.unshift({ key: "-", text: "-" });
    return options;
  }

  static getRole = (accessType: string): Roles => {
    const claims = accessType.split(",");
    if (claims.includes("listAdmin")) return "administrator";
    if (claims.includes("addUser")) return "maintainer";
    if (claims.includes("editCodes")) return "editor";
    if (claims.includes("pendingUser")) return "pending";
    return "member";
  };

  static postFeedbackMessage = async (message: string) => {
    try {
      await Api.postFeedbackMessage(message);
    } catch (e) {
      alert("Error posting feedback message: " + e);
    }
  };

  static prepDropdownOptionsWithAddItem = (options: IComboBoxOption[], onAddClick: () => void) => {
    return [
      ...options,
      {
        key: "+",
        text: strings.Helpers_ManageCategories,
        data: {
          icon: "Edit",
          onClick: (e: any) => {
            e.stopPropagation();
            e.preventDefault();
            onAddClick();
          },
        },
      },
    ];
  };
}
