import React, { useEffect, useState } from "react";
import { Image, mergeStyleSets, ImageFit, Stack, getTheme } from "@fluentui/react";
import Api from "../api";
import { Language } from "./Strings";

const strings = Language.strings;

const theme = getTheme();
const styles = mergeStyleSets({
  itemImage: {
    marginBottom: 6,
    width: "90vw",
    // maxWidth: "200px",
    height: "40%",
    backgroundColor: "transparent", // theme.semanticColors.buttonBackgroundDisabled
  },
  itemImageSmall: {
    marginBottom: 6,
    // width: "40vw",
    maxWidth: "200px",
    minHeight: "20%",
    height: "100%",
    backgroundColor: "transparent", // theme.semanticColors.buttonBackgroundDisabled
  },
  shimmerImage: {
    marginBottom: 6,
    width: 200,
    height: 100,
    backgroundColor: theme.semanticColors.buttonBackgroundDisabled,
  },
});

export const ItemImage = (props: { id?: string; isSmall?: boolean }) => {
  const [url, setUrl] = useState("");
  const { id, isSmall } = props;

  useEffect(() => {
    if (id) {
      Api.getFileUrl(id)
        .then((url) => setUrl(url))
        .catch(() => {});
    }
  }, [id]);

  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", height: "100%" }}>
      {id && url && isSmall === true && (
        <Image className={styles.itemImageSmall} src={url} alt="image" imageFit={ImageFit.contain} />
      )}
      {id && url && !(isSmall === true) && (
        <Image className={styles.itemImage} src={url} alt="image" imageFit={ImageFit.contain} />
      )}
      {!id && <Stack className={styles.shimmerImage} />}
      {!url && id && <p>{strings.itemImage_NoImage}</p>}
    </div>
  );
};
