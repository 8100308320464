import React, { useState } from "react";
import { Stack, MessageBarType, MessageBar, PrimaryButton, Label } from "@fluentui/react";
import { StackVert, ParagraphText, ParagraphTitle, ItemInfoStacked } from "./Controls";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Language } from "./Strings";
import { useObserveCode } from "../hooks/useObserveCode";
import { TitleWithInfo } from "./TitleWithInfo";
import { ListMessages } from "./ListMessages";
import { ImagesPanel } from "./ImagesPanel";
import { ItemFooterEdit } from "./ItemFooterEdit";

const strings = Language.strings;

interface HandleOtherLostFoundProps extends RouteComponentProps {
  code: string;
}

export const HandleOtherLostFound = withRouter(({ history, code }: HandleOtherLostFoundProps) => {
  const {
    ownerPhone,
    showHowMuchPay,
    lostPayValue,
    showPhoneNumber,
    possibleSendMess,
    enablePosition,
    // name,
    // description,
    defaultImageId,
    setDefaultImageId,
    images,
    hasCodeEditAccess,
    postMessage,
  } = useObserveCode({ code, onSaved: () => history.goBack() });

  // const [messToOwer, setMessToOwer] = useState('');

  // const sendMessToOwner = async () => {
  //    // TODO: Write this....
  // };
  const [error, setError] = useState(undefined);
  const [message, setMessage] = useState(undefined);

  const sendGPSToOwner = () => {
    if (navigator.geolocation) {
      setMessage(strings.handleOtherLostFound_SendingGPSPosToOwner);
      navigator.geolocation.getCurrentPosition(function (position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        const url = "https://www.google.com/maps/?q=" + latitude + "," + longitude;
        postMessage(strings.handleOtherLostFound_GpsLinkMessage + url);
        setMessage(strings.handleOtherLostFound_HasSentGPSPosToOwner);
      });
    } else {
      setError(strings.handleOtherLostFound_GeolocationIsNotSupported);
      setMessage(undefined);
      console.log("Geolocation is not supported by this browser.");
    }
  };

  return (
    <StackVert>
      <Stack.Item>
        {error && (
          <MessageBar messageBarType={MessageBarType.error} onDismiss={() => setError(error)}>
            {error}
          </MessageBar>
        )}
      </Stack.Item>
      {/* <h1>TODO: fix what to show, if nothing is shown...show only send found message to user...</h1>          */}

      {
        <Stack.Item>
          <TitleWithInfo
            title={strings.handleOtherLostFound_HowToReturnItem}
            // title={name ? name : ""}
            // iconFileName={"SearchIssue"}
            infoTitle={strings.handleOtherLostFound_infoHeader}
            code={code}
          >
            <ul>
              <li>{strings.handleOtherLostFound_info1}</li>
              <li>{strings.handleOtherLostFound_info2}</li>
              {showHowMuchPay && <li>{strings.handleOtherLostFound_info3}</li>}
              {showPhoneNumber && <li>{strings.handleOtherLostFound_info4}</li>}
              {possibleSendMess && <li>{strings.handleOtherLostFound_info5}</li>}
              <li>{strings.handleOtherLostFound_info6}</li>
            </ul>
          </TitleWithInfo>
          <ParagraphText>{strings.handleOtherLostFound_YouHaveScannedCode}</ParagraphText>
          <StackVert>
            {/* <ItemInfoStacked header={strings.generic_name + ":"} content={name}></ItemInfoStacked> */}
            {/* <Stack.Item>
              <ParagraphLabel>{strings.generic_description}</ParagraphLabel>
              <ParagraphText>{description}</ParagraphText>
            </Stack.Item> */}

            {showHowMuchPay && (
              <ItemInfoStacked
                header={strings.handleOtherLostFound_FindersFee}
                content={strings.handleOtherLostFound_ThereIsAFindersFee + lostPayValue + "!"}
              ></ItemInfoStacked>
            )}
            {showPhoneNumber && (
              <ItemInfoStacked
                header={strings.handleOtherLostFound_PhoneNum}
                content={
                  strings.handleOtherLostFound_OwnersPhoneNum +
                  ownerPhone +
                  strings.handleOtherLostFound_PleaseCallOrSend
                }
              ></ItemInfoStacked>
            )}
            {possibleSendMess && (
              <Stack.Item>
                <StackVert>
                  <Stack.Item>
                    <ParagraphTitle>{strings.handleOtherLostFound_SendMessToOwner}</ParagraphTitle>
                    <ParagraphText>{strings.handleOtherLostFound_YouCanChooseSendMess}</ParagraphText>
                    <ListMessages code={code} showSendMessage={true} />
                    {/* <TextField
                                    multiline
                                    id="messToOwner"
                                    label='Message'
                                    value={messToOwer}
                                    onChange={(_e, value) => setMessToOwer(value || '')} /> */}
                  </Stack.Item>
                  {/* <Stack.Item>
                                 <PrimaryButton onClick={sendMessToOwner}>
                                    {strings.handleOtherLostFound_SendMess}
                                 </PrimaryButton>
                              </Stack.Item> */}
                </StackVert>
              </Stack.Item>
            )}
            {enablePosition && (
              <StackVert>
                <ParagraphTitle>{strings.handleOtherLostFound_SendGPSPosToOwnerHeader}</ParagraphTitle>
                <ParagraphText>{strings.handleOtherLostFound_SendGPSPositionToOwnerInfo}</ParagraphText>
                <Stack.Item>
                  {message && (
                    <MessageBar messageBarType={MessageBarType.info} onDismiss={() => setMessage(undefined)}>
                      {message}
                    </MessageBar>
                  )}
                </Stack.Item>
                <Stack.Item>
                  <PrimaryButton onClick={sendGPSToOwner}>
                    {strings.handleOtherLostFound_SendGPSPosToOwnerButton}
                  </PrimaryButton>
                </Stack.Item>
              </StackVert>
            )}
            {images.length > 0 && (
              <Stack.Item>
                <Label>{strings.item_photo}</Label>
                <ImagesPanel
                  viewMode
                  defaultImageId={defaultImageId}
                  setDefaultImageId={setDefaultImageId}
                  code={code}
                  images={images}
                />
              </Stack.Item>
            )}
            {hasCodeEditAccess && <ItemFooterEdit code={code} />}
          </StackVert>
        </Stack.Item>
      }
    </StackVert>
  );
});
