import React from "react";
import { Stack, Spinner, SpinnerSize } from "@fluentui/react";
import queryString from "query-string";
import { HandleItemBorrowedByMe } from "./HandleItemBorrowedByMe";
import { HandleItemBorrowedByOther } from "./HandleItemBorrowedByOther";
import { HandleBorrowingItem } from "./HandleBorrowingItem";
import { HandleOwnItem } from "./HandleOwnItem";
import { Header } from "./Header";
import { Content } from "./Content";
import { StackVert } from "./Controls";
import { withRouter } from "react-router-dom";
import { RegisterItem } from "./RegisterItem";
import { HandleOwnHtml } from "./HandleOwnHtml";
import { HandleOthersHtml } from "./HandleOthersHtml";
import { HandleOtherLostFound } from "./HandleOtherLostFound";
import { HandleOwnLostFound } from "./HandleOwnLostFound";
// import { HandleOthersFreezer } from "./HandleOthersFreezer";
import { HandleOwnBoxes } from "./HandleOwnBoxes";
import { HandleOthersBoxes } from "./HandleOthersBoxes";
import { useObserveCode } from "../hooks/useObserveCode";
import { HandleItemOtherBorrowedByOther } from "./HandleItemOtherBorrowedByOther";
// import { EditFreezer } from "./EditFreezer";
// import { strings } from "./Strings";
import { HandleFreezer } from "./HandleFreezer";

export const ItemView = withRouter((props) => {
  const { params } = props.match;
  const { code } = params.code ? params : queryString.parse(window.location.search);
  // const history = useHistory();
  const {
    busy,
    isBorrowed,
    isRegistered,
    isAwaitingOwnerReturnAccept,
    isBorrowedByMe,
    type,
    isMine,
    returnItem,
    borrow,
    setType,
  } = useObserveCode({ code });
  // TODO: Fix RegisterItemManually
  //   useEffect(() => {
  //     if (code) {
  //       Api.getCode(code)
  //         .then((ci) => {
  //           setCodeInfo(ci);
  //           setOrigin((ci.origin || "qrit") as Origin);
  //         })
  //         .catch(() => {
  //           Api.verifyCode(code)
  //             .then(() => props.history.push("/registerItemManually?s=" + code))
  //             .catch(() => {});
  //         });
  //     }
  //   }, [code, props]);
  return (
    <StackVert>
      <Header />
      <Content>
        {busy && (
          <Stack.Item>
            <Spinner size={SpinnerSize.large} />
          </Stack.Item>
        )}
        {!busy && (
          <>
            {/* {codeInfo && codeInfo.userId === null && (
              <Stack.Item>
                <MessageBar messageBarType={MessageBarType.error}>
                  {strings.itemView_CodeDoesNotExist}
                </MessageBar>
              </Stack.Item>
            )} */}
            {!isRegistered && (
              <Stack.Item>
                <RegisterItem code={code} type={type} onSaved={() => window.location.reload()} onChangeType={setType} />
              </Stack.Item>
            )}
            {isRegistered && (
              <Stack.Item>
                {type === "static" && (
                  <>
                    {!isBorrowed && isRegistered && !isMine && <HandleBorrowingItem code={code} onBorrow={borrow} />}

                    {(isBorrowed || isAwaitingOwnerReturnAccept) && isMine && (
                      <HandleItemBorrowedByOther code={code} returnItem={returnItem} />
                    )}

                    {isBorrowed && !isBorrowedByMe && !isMine && <HandleItemOtherBorrowedByOther code={code} />}

                    {isBorrowed && isBorrowedByMe && !isMine && (
                      <HandleItemBorrowedByMe code={code} onReturnItem={returnItem} />
                    )}

                    {!isBorrowed && isRegistered && isMine && <HandleOwnItem code={code} />}
                  </>
                )}

                {type === "lostFound" && (
                  <>
                    {!isMine && <HandleOtherLostFound code={code} />}
                    {isMine && <HandleOwnLostFound code={code} />}
                  </>
                )}

                {type === "boxes" && (
                  <>
                    {!isMine && <HandleOthersBoxes code={code} />}
                    {isMine && <HandleOwnBoxes code={code} />}
                  </>
                )}

                {type === "freezer" && (
                  <>
                    {<HandleFreezer code={code} />}
                  </>
                  // <>
                  //     {isMine && (
                  //       <EditFreezer
                  //         qrCode={code}
                  //         header={strings.freezer_PageHeaderEdit}
                  //         onSaved={() => {
                  //           if (history.length > 1) {
                  //             history.goBack();
                  //           } else {
                  //             history.replace("/");
                  //           }
                  //         }}
                  //         onChangeType={setType}
                  //       />
                  //     )}
                  //     {!isMine && <HandleOthersFreezer code={code} />}
                  //   </>
                )}

                {type === "html" && (
                  <>
                    {isMine && <HandleOwnHtml code={code} />}
                    {!isMine && <HandleOthersHtml code={code} />}
                  </>
                )}
              </Stack.Item>
            )}
          </>
        )}
      </Content>
    </StackVert>
  );
});
