import React from "react";
import { Stack, PrimaryButton } from "@fluentui/react";
import { StackVert, ParagraphText, ItemInfoStacked, ParagraphLabel, CodeShowAndLinkToQRImage } from "./Controls";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useObserveCode } from "../hooks/useObserveCode";
import { TitleWithInfo } from "./TitleWithInfo";
import { useObserveStrings } from "../hooks/useObserveStrings";
import { ListMessages } from "./ListMessages";
import { ImagesPanel } from "./ImagesPanel";
import { ItemFooterEdit } from "./ItemFooterEdit";

interface HandleItemOtherBorrowedByOtherProps extends RouteComponentProps {
  code: string;
}

export const HandleItemOtherBorrowedByOther = withRouter(({ history, code }: HandleItemOtherBorrowedByOtherProps) => {
  const {
    name,
    description,
    itemType,
    rentable,
    rentCostDescr,
    statusIconFilename,
    defaultImageId,
    setDefaultImageId,
    images,
    hasCodeEditAccess,
  } = useObserveCode({ code: code, onSaved: () => history.goBack() });
  const strings = useObserveStrings();
  return (
    <StackVert>
      <TitleWithInfo
        title={name ? name : ""}
        iconFileName={statusIconFilename}
        infoTitle={strings.handleItemOtherBorrowedByOther_infoHeader}
        code={code}
      >
        <ul>
          <li>{strings.handleItemOtherBorrowedByOther_info1}</li>
          <li>{strings.handleItemOtherBorrowedByOther_info2}</li>
        </ul>
      </TitleWithInfo>
      <ItemInfoStacked
        header={strings.handleBorrowingItem_TypeOfItem}
        content={itemType ? itemType : strings.generic_noTypeSet}
      ></ItemInfoStacked>
      {/* <ItemInfoStacked header={strings.item_status} content={Helpers.stateToString(state, false)} ></ItemInfoStacked> */}
      <ItemInfoStacked header={strings.handleBorrowingItem_ItemName} content={name}></ItemInfoStacked>
      {/* <ItemInfoStacked header={strings.handleItemBorrowedByOther_BorrowedBy} content={borrowerUserName} ></ItemInfoStacked>
        <ItemInfoStacked header={strings.handleItemBorrowedByOther_ShouldBeReturnedBy} content={returnDate ? returnDate.toLocaleDateString() : ""} ></ItemInfoStacked> */}
      {/* <ItemInfoStacked header={strings.handleItemBorrowedByOther_ReturnIn} content={returnNumDays + strings.handleItemBorrowedByOther_Days} ></ItemInfoStacked> */}
      {description && description !== "-" && (
        <Stack.Item>
          <ParagraphLabel>{strings.generic_description}</ParagraphLabel>
          <ParagraphText>{description}</ParagraphText>
        </Stack.Item>
      )}
      {rentable && rentCostDescr && rentCostDescr !== "-" && (
        <Stack.Item>
          <ParagraphLabel>{strings.registerItem_rentInfo}</ParagraphLabel>
          <ParagraphText>{rentCostDescr}</ParagraphText>
        </Stack.Item>
      )}
      <Stack.Item>
        <ParagraphLabel>{strings.item_photo}</ParagraphLabel>
        <ImagesPanel
          viewMode
          defaultImageId={defaultImageId}
          setDefaultImageId={setDefaultImageId}
          code={code}
          images={images}
        />
      </Stack.Item>
      <CodeShowAndLinkToQRImage code={code}></CodeShowAndLinkToQRImage>
      <ListMessages code={code} showSendMessage={true} />

      {/* <ItemInfoStacked header={strings.generic_QRCode} content={code} ></ItemInfoStacked> */}
      {/* <ShowList header={strings.generic_ListsShow} codesLists={codeStores}></ShowList>             */}
      {/* <ListMessages code={code} showSendMessage={false} /> */}
      {/* <Stack.Item> */}
      {/* <StackHorizontal> */}
      {/* <Stack.Item grow>
              <PrimaryButton onClick={returnItem}>
                {strings.handleItemBorrowedByOther_ReportReturned}
              </PrimaryButton>
            </Stack.Item> */}
      {/* <Stack.Item>
                        <PrimaryButton disabled={!Helpers.canShare()}
                            onClick={async () => {
                                try {
                                    await Helpers.share(this.props.value.id);
                                }
                                catch (error) {
                                    this.setState({ error: error.message });
                                }
                            }}>{strings.generic_share}</PrimaryButton>
                    </Stack.Item> */}

      {!hasCodeEditAccess && (
        <Stack.Item align="end">
          <PrimaryButton onClick={() => history.goBack()}>{strings.generic_close}</PrimaryButton>
        </Stack.Item>
      )}
      {hasCodeEditAccess && <ItemFooterEdit code={code} />}
      {/* </StackHorizontal> */}
      {/* </Stack.Item> */}
    </StackVert>
  );
});
