import React from "react";
import { DetailsListLayoutMode, IColumn, SelectionMode, ShimmeredDetailsList } from "@fluentui/react";
import { CodeInfo } from "../models";
import { Language } from "./Strings";

interface ItemListViewProps {
  items: CodeInfo[];
  busy: boolean;
  columns: IColumn[];
  onItemInvoked: (item?: CodeInfo) => void;
}

export const ItemListView = ({ items, busy, columns, onItemInvoked }: ItemListViewProps) => {
  const strings = Language.strings;
  return (
    <ShimmeredDetailsList
      items={items}
      enableShimmer={busy}
      ariaLabelForShimmer={strings.listItems_loading}
      compact={false}
      columns={columns}
      selectionMode={SelectionMode.none}
      layoutMode={DetailsListLayoutMode.justified}
      isHeaderVisible={true}
      onActiveItemChanged={onItemInvoked}
    />
  );
};
