import {
  Stack,
  PrimaryButton,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  ChoiceGroup,
  IChoiceGroupOption,
  Checkbox,
  IconButton,
} from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { Prompt, RouteComponentProps, withRouter } from "react-router-dom";
import { useObserveCode } from "../hooks/useObserveCode";
import { CodePropsType } from "../models";
import { StackHorizontal, StackVert } from "./Controls";
import { Language } from "./Strings";

const strings = Language.strings;

interface ItemFooterSaveProps extends RouteComponentProps {
  code: string;
  onSave: () => void;
  isValid: boolean;
  onUnregister?: () => void;
  onChangeType: (type: CodePropsType) => void;
  canSave: boolean;
  onPasteCode?: () => void;
}

export const ItemFooterSave = withRouter(
  ({ code, history, onSave, isValid, onUnregister, onChangeType, canSave, onPasteCode }: ItemFooterSaveProps) => {
    const { type } = useObserveCode({ code });
    const [showUnregister, setShowUnregister] = useState(false);
    const [showChangeCodeType, setShowChangeCodeType] = useState(false);
    const [codeType, setCodeType] = useState<CodePropsType>("static");
    const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);

    const closeUnregister = () => setShowUnregister(false);
    const closeChangeCodeType = () => setShowChangeCodeType(false);

    useEffect(() => {
      setCodeType(type);
    }, [type]);

    const options: IChoiceGroupOption[] = [
      { key: "static", text: "Items" },
      { key: "boxes", text: "Boxes" },
      { key: "lostFound", text: "Lost&Found" },
      { key: "freezer", text: "Freezer" },
      { key: "html", text: "Messages" },
    ];

    const _onChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
      if (option) {
        setCodeType(option.key as CodePropsType);
      }
    };

    const _onSaveCodeTypeChange = async () => {
      closeChangeCodeType();
      onChangeType(codeType);
      // await save();
      // window.location.reload();
      // onChangeType(codeType);
      // if (codeType == "Items") {
      //   // TODO: What to do here? Send it directly to Edit? Will not work. Will need to set the type first in the code
      // } else if (codeType == "Boxes") {
      // } else if (codeType == "Lost&Found") {
      // } else if (codeType == "Freezer") {
      // } else if (codeType == "Messages") {
      // }
    };

    const _onCancelCodeTypeChange = () => {
      closeChangeCodeType();
      setCodeType(type);
    };

    const _onToggelShowAdvancedSettings = (checked?: boolean) => {
      setShowAdvancedSettings(!!checked);
    };

    return (
      <StackVert>
        <Stack.Item>
          <Checkbox
            label={strings.itemFooter_ShowAdvSettings}
            checked={showAdvancedSettings}
            // TODO: Why does it not toggle?
            onChange={(_ev, checked) => _onToggelShowAdvancedSettings(checked)}
          />
        </Stack.Item>
        {showAdvancedSettings && (
          <Stack>
            <Stack.Item grow>
              <PrimaryButton onClick={() => setShowChangeCodeType(true)}>
                {strings.itemFooter_ChangeCodeType}
              </PrimaryButton>
            </Stack.Item>
            {onPasteCode && (
              <Stack.Item style={{ marginLeft: 0 }}>
                <IconButton iconProps={{ iconName: "Paste" }} onClick={onPasteCode} />
              </Stack.Item>
            )}
          </Stack>
        )}
        <StackHorizontal>
          <Stack.Item grow>
            <PrimaryButton disabled={!isValid || !canSave} onClick={onSave}>
              {strings.generic_save}
            </PrimaryButton>
          </Stack.Item>
          <Stack.Item grow>
            {onUnregister && (
              <PrimaryButton onClick={() => setShowUnregister(true)}>{strings.itemFooter_unregisterItem}</PrimaryButton>
            )}
          </Stack.Item>
          <Stack.Item align="end">
            <PrimaryButton onClick={() => history.goBack()}>{strings.generic_back}</PrimaryButton>
          </Stack.Item>
          <Dialog
            hidden={!showUnregister}
            onDismiss={closeUnregister}
            dialogContentProps={{
              type: DialogType.normal,
              title: strings.itemFooter_unregister,
              closeButtonAriaLabel: strings.itemFooter_close,
              subText: strings.itemFooter_realyUnregister,
            }}
          >
            <DialogFooter>
              <PrimaryButton onClick={onUnregister} text={strings.generic_yes} />
              <DefaultButton onClick={closeUnregister} text={strings.generic_no} />
            </DialogFooter>
          </Dialog>
        </StackHorizontal>
        <Dialog
          hidden={!showChangeCodeType}
          onDismiss={closeChangeCodeType}
          dialogContentProps={{
            type: DialogType.normal,
            title: strings.itemFooter_ChangeCodeType,
            closeButtonAriaLabel: strings.itemFooter_close,
            subText: strings.itemFooter_WarningDataLostRegisterNew,
          }}
        >
          <ChoiceGroup selectedKey={codeType} options={options} onChange={_onChange} required={true} />
          <DialogFooter>
            <PrimaryButton onClick={_onSaveCodeTypeChange} text={strings.generic_save} />
            <DefaultButton onClick={_onCancelCodeTypeChange} text={strings.generic_cancel} />
          </DialogFooter>
        </Dialog>
        <Prompt when={canSave} message={strings.itemFooter_areYouReadyToLeavePage} />
      </StackVert>
    );
  }
);
