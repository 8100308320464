import React, { useEffect, useState } from "react";
import { MessageBar, MessageBarType } from "@fluentui/react";

export const InfoBar = ({ info }: { info?: string }) => {
  const [text, setText] = useState<string>();
  useEffect(() => setText(info), [info]);

  if (!text || text.trim().length === 0) return null;
  return (
    <MessageBar messageBarType={MessageBarType.info} onDismiss={() => setText(undefined)}>
      {text}
    </MessageBar>
  );
};
