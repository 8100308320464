import { Dialog, DialogFooter, PrimaryButton, DefaultButton, DialogContent, TextField } from "@fluentui/react";
import React, { useState } from "react";
import { Store } from "../models";
import { Language } from "./Strings";

const strings = Language.strings;

interface CreateStoreDialogProps {
  hidden: boolean;
  onDismiss: () => void;
  onCreateStore: (store: Store) => void;
}

export const CreateStoreDialog = (props: CreateStoreDialogProps) => {
  const { onCreateStore, onDismiss, hidden } = props;

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const createStore = () => {
    onCreateStore({ name: name, description: description } as Store);
    // onDismiss();
  };

  const nameOk = name.trim().length > 2;
  const descriptionOk = description.trim().length > 2;

  return (
    <Dialog
      title={strings.createStoreDialog_Create}
      dialogContentProps={{ subText: strings.createStoreDialog_CreateStoreQuestion }}
      hidden={hidden}
      onDismiss={onDismiss}
      modalProps={{ isBlocking: true }}
    >
      <DialogContent>
        <TextField
          label={strings.createStoreDialog_StoreName}
          //styles={{ fieldGroup: { width: 100 } }}
          value={name}
          onChange={(_e, newValue) => setName(newValue || "")}
          required={!nameOk}
        />
        <TextField
          multiline
          label={strings.generic_description}
          // styles={{ fieldGroup: { width: 100 } }}
          value={description}
          onChange={(_e, newValue) => setDescription(newValue || "")}
          required={!descriptionOk}
        />
      </DialogContent>
      <DialogFooter>
        <PrimaryButton
          onClick={createStore}
          text={strings.createStoreDialog_Create}
          disabled={!nameOk || !descriptionOk}
        />
        <DefaultButton onClick={onDismiss} text={strings.generic_cancel} />
      </DialogFooter>
    </Dialog>
  );
};
