import { useEffect, useState } from "react";
import { createTheme } from "@fluentui/react";

const myTheme = createTheme({
  palette: {
    themePrimary: "#5d7d56",
    themeLighterAlt: "#f7faf7",
    themeLighter: "#e1eadf",
    themeLight: "#c8d8c4",
    themeTertiary: "#96b190",
    themeSecondary: "#6d8d66",
    themeDarkAlt: "#54704e",
    themeDark: "#475f42",
    themeDarker: "#344630",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#a19f9d",
    neutralSecondary: "#605e5c",
    neutralPrimaryAlt: "#3b3a39",
    neutralPrimary: "#323130",
    neutralDark: "#201f1e",
    black: "#000000",
    white: "#ffffff",
  },
});

const shugardTheme = createTheme({
  palette: {
    themePrimary: "#D1003B", // '#5d7d56', Change color scheme to: H,S,L: 343, 100, 41 ; D1003B; R,G,B: 207,0,59;
    themeLighterAlt: "#f7faf7",
    themeLighter: "#e1eadf",
    themeLight: "#c8d8c4",
    themeTertiary: "#96b190",
    themeSecondary: "#6d8d66",
    themeDarkAlt: "#54704e",
    themeDark: "#475f42",
    themeDarker: "#344630",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#a19f9d",
    neutralSecondary: "#605e5c",
    neutralPrimaryAlt: "#3b3a39",
    neutralPrimary: "#323130",
    neutralDark: "#201f1e",
    black: "#000000",
    white: "#ffffff",
  },
});

export type Origin = "qrit" | "shuregard";
export const useTheme = (initialOrigin: Origin = "qrit") => {
  const [showStaticCodes, setShowStaticCodes] = useState(true);
  const [showStorageCodes, setShowStorageCodes] = useState(true);
  const [showKeyCodes, setShowKeyCodes] = useState(true);
  const [showLostAndFoundCodes, setShowLostAndFoundCodes] = useState(true);
  const [showFreezerCodes, setShowFreezerCodes] = useState(true);
  const [showHtmlCodes, setShowHtmlCodes] = useState(true);

  //Header
  const [showBuy, setShowBuy] = useState(true);
  const [aboutPage, setAboutPage] = useState("/about");

  const [origin, setOrigin] = useState(initialOrigin);
  const [theme, setTheme] = useState(myTheme);
  const [imageDir, setImageDir] = useState("img");

  useEffect(() => {
    switch (origin) {
      case "qrit":
        setShowStaticCodes(true);
        setShowStorageCodes(true);
        setShowKeyCodes(true);
        setShowLostAndFoundCodes(true);
        setShowFreezerCodes(true);
        setShowHtmlCodes(true);
        setShowBuy(true);
        setAboutPage("/about");
        setImageDir("img");
        setTheme(myTheme);
        break;
      case "shuregard":
        setShowStaticCodes(false);
        setShowStorageCodes(true);
        setShowKeyCodes(false);
        setShowLostAndFoundCodes(false);
        setShowFreezerCodes(false);
        setShowBuy(false);
        setAboutPage("/aboutShure");
        setImageDir("img_shurgard");
        setTheme(shugardTheme);

        break;
    }
  }, [origin]);

  return {
    showStaticCodes,
    showStorageCodes,
    showKeyCodes,
    showLostAndFoundCodes,
    showFreezerCodes,
    showHtmlCodes,
    showBuy,
    aboutPage,
    theme,
    imageDir,
    // origin,
    setOrigin,
  };
};
